@tailwind base;
@tailwind components;
@tailwind utilities;



/* font section */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

html, body, * {
    font-family: 'Poppins', sans-serif; /* Set Poppins as default */
}
/* font section */




@media (max-width: 767px) {
    .wide-screen-div {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      height: 100vw; /* Adjust as needed */
    }
  
    .wide-screen-div img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }