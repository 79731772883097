/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
#0.1	Theme Reset Style
#0.2	Global Elements
#0.3	header
#0.4	hero-slider
#0.5	footer


----------------------------------------------------------------
	#home-default
----------------------------------------------------------------
#1.1	feature-product-section
#1.2	trendy-product-section
#1.3	deal-section
#1.4	best-seller-section
#1.5	partners-section
#1.6	category-section-area
#1.7	cta-section
#1.8	instagram-section


----------------------------------------------------------------
	#home-s2
----------------------------------------------------------------
#2.1	featured-proeducts-section-s2
#2.2	home-pg-prodcut-section
#2.3	feautured-product-s3-section


----------------------------------------------------------------
	#home-s3
----------------------------------------------------------------
#3.1	top-products-section


----------------------------------------------------------------
	#my account
----------------------------------------------------------------
#4.1	my-account-section


----------------------------------------------------------------
	#blog-page
----------------------------------------------------------------
#5.1	blog-pg-section


---------------------------------------------------------------
	#blog-single-page
----------------------------------------------------------------
#6.1	blog-single-section


----------------------------------------------------------------
	#error-404
----------------------------------------------------------------
#7.1	error-404-section


----------------------------------------------------------------
	#checkout-page
----------------------------------------------------------------
#8.1	checkout-section


----------------------------------------------------------------
	 #cart
----------------------------------------------------------------
#9.1    cart-section


----------------------------------------------------------------
	#shop-page
----------------------------------------------------------------
#10.1	shop-section


----------------------------------------------------------------
	#shop-single-page
----------------------------------------------------------------
#11.1	shop-single-section


----------------------------------------------------------------
	#contact page
----------------------------------------------------------------
#12.1	contact-pg-section


----------------------------------------------------------------
	#about-page
----------------------------------------------------------------
#13.1	feature-section
#13.2.	about-section
#13.3	testimonials-section
#13.4	blog-section


----------------------------------------------------------------*/
/*---------------------------
	Fonts
----------------------------*/
/* @import url("https://fonts.googleapis.com/css2?family=Gothic+A1:wght@300;400;500;600&display=swap"); */

@font-face {
    /* font-family: 'futura-pt-demi'; */
    /* src: url(../fonts/futura-pt-demi.otf) format("truetype"); */
    font-weight: 600;
    font-style: normal;
}

/*--------------------------------------------------------------
#0.1    Theme Reset Style
--------------------------------------------------------------*/
html {
    font-size: 15px;
}

body {
    /* font-family: "Gothic A1", sans-serif; */
    background-color: #fff;
    font-size: 15px;
    font-size: 1rem;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
}

@media (max-width: 767px) {
    body {
        font-size: 14px;
        font-size: 0.9333333333rem;
    }
}

p {
    
    line-height: 1.6em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    /* font-family: "futura-pt-demi", sans-serif; */
    color: #000;
    font-weight: 400;
}

ul {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
}

a,
button {
    text-decoration: none;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
}

a:hover {
    text-decoration: none;
}

img {
    max-width: 100%;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
form select,
textarea {
    /* font-family: "Gothic A1", sans-serif; */
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 20;
    padding: 12px 15px;
}

@media (max-width: 991px) {
    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="tel"],
    form select,
    textarea {
        padding: 10px 15px;
    }
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
form select:focus,
textarea:focus {
    border: 1px solid #000;
    outline: none;
    box-shadow: none;
}

textarea {
    height: 180px;
}

label {
    /* font-family: "Gothic A1", sans-serif; */
    font-size: 14px;
    font-size: 0.9333333333rem;
    color: #000;
}

select {
    -moz-appearance: none;
    -webkit-appearance: none;
    /* background: #fff url(../images/select-arrow.png) calc(100% - 15px) 25px no-repeat; */
    background-size: 7px 4px;
    position: relative;
}

/*--------------------------------------------------------------
#0.2    Global Elements
--------------------------------------------------------------*/
.page-wrapper {
    position: relative;
    overflow: hidden;
}

/* .container-1410 {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .container-1410 {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container-1410 {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .container-1410 {
        width: 1170px;
    }
}

@media (min-width: 1450px) {
    .container-1410 {
        width: 1300px;
    }
} */

.container-1410 {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 90%; /* Added a maximum width for larger screens */
}

@media (min-width: 1200px) {
    .container-1410 {
        max-width: 1170px;
    }
}

@media (min-width: 1440px) {
    .container-1410 {
        max-width: 1300px;
    }
}

@media (min-width: 1920px) {
    .container-1410 {
        max-width: 1600px; /* Adjusted for 1920px width screen */
    }
}



.m-t-0 {
    margin-top: 0 !important;
}

.transform-none {
    -webkit-transform: none !important;
    -moz-transform: none !important;
    -o-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
}

/*** newsletter-popup-area ***/
.newsletter-popup-area-section {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    -webkit-transition: opacity 0.1s ease-in-out, z-index 0.1s ease-in-out;
    -o-transition: opacity 0.1s ease-in-out, z-index 0.1s ease-in-out;
    transition: opacity 0.1s ease-in-out, z-index 0.1s ease-in-out;
    opacity: 0;
    visibility: hidden;
}

@media (max-width: 991px) {
    .newsletter-popup-area-section {
        display: none;
    }
}

.newsletter-popup-area-section .newsletter-popup-area {
    width: 700px;
    background-color: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
}

.newsletter-popup-area-section .newsletter-popup-area .newsletter-popup-ineer {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.newsletter-popup-area-section .newsletter-popup-area .img-holder {
    width: 325px;
    float: left;
}

.newsletter-popup-area-section .newsletter-popup-area .details {
    width: calc(100% - 325px);
    float: left;
    padding: 35px;
}

.newsletter-popup-area-section .newsletter-popup-area h4 {
    font-size: 24px;
    font-size: 1.6rem;
    margin: 0 0 0.5em;
}

.newsletter-popup-area-section .newsletter-popup-area p {
    font-size: 14px;
    font-size: 0.9333333333rem;
    margin: 0 0 2em;
}

.newsletter-popup-area-section .newsletter-popup-area form div {
    position: relative;
}

.newsletter-popup-area-section .newsletter-popup-area form div input[type="email"] {
    font-size: 14px;
    font-size: 0.9333333333rem;
}

.newsletter-popup-area-section .newsletter-popup-area form div button {
    height: 46px;
    line-height: 46px;
    border: 0;
    outline: 0;
    padding: 0 25px;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: background 0.2s ease-in-out;
    -ms-transition: background 0.2s ease-in-out;
    transition: background 0.2s ease-in-out;
    letter-spacing: 0;
}

.newsletter-popup-area-section .newsletter-popup-area form div input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.newsletter-popup-area-section .newsletter-popup-area form div .checkbox-holder {
    margin-top: 25px;
    padding-left: 20px;
    position: relative;
    cursor: pointer;
}

.newsletter-popup-area-section .newsletter-popup-area form div .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 12px;
    width: 12px;
    background-color: #c7c7c7;
    border-radius: 50%;
}

.newsletter-popup-area-section .newsletter-popup-area form div .checkbox-holder input:checked ~ .checkmark {
    /* background-color: #c2572b; */
    background-color: #080808;
}

.newsletter-popup-area-section .newsletter-popup-area form div label {
    
    
}

.newsletter-popup-area-section .newsletter-popup-area .newsletter-close-btn {
    background: transparent;
    border: 0;
    outline: 0;
    color: #000;
    font-size: 30px;
    font-size: 2rem;
    position: absolute;
    right: 0;
    top: 0;
}

@media (max-width: 450px) {
    .newsletter-popup-area-section .newsletter-popup-area .newsletter-close-btn {
        right: 0;
    }
}

.newsletter-popup-area-section .newsletter-popup-area .newsletter-close-btn:hover,
.newsletter-popup-area-section .newsletter-popup-area .newsletter-close-btn:focus {
    outline: none;
    box-shadow: none;
}

/*** quick-view-single-product ***/
.quick-view-single-product {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    -webkit-transition: opacity 0.1s ease-in-out, z-index 0.1s ease-in-out;
    -o-transition: opacity 0.1s ease-in-out, z-index 0.1s ease-in-out;
    transition: opacity 0.1s ease-in-out, z-index 0.1s ease-in-out;
    opacity: 0;
    visibility: hidden;
    /*** product option ***/
}

.quick-view-single-product .quick-view-single-product-close-btn {
    background: transparent;
    border: 0;
    outline: 0;
    color: #000;
    font-size: 30px;
    font-size: 2rem;
    position: absolute;
    right: 20px;
    top: 20px;
}

@media (max-width: 450px) {
    .quick-view-single-product .quick-view-single-product-close-btn {
        right: 0;
    }
}

.quick-view-single-product .quick-view-single-product-close-btn:hover,
.quick-view-single-product .quick-view-single-product-close-btn:focus {
    outline: none;
    box-shadow: none;
}

.quick-view-single-product .view-single-product-inner {
    width: 1100px;
    background-color: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

@media (max-width: 1200px) {
    .quick-view-single-product .view-single-product-inner {
        width: 850px;
        height: 505px;
    }
}

@media (max-width: 900px) {
    .quick-view-single-product .view-single-product-inner {
        width: 450px;
        height: calc(100vh - 100px);
        overflow-y: scroll;
        left: calc(50% - 225px);
        top: 50px;
        display: block;
        -webkit-transform: none;
        -moz-transform: none;
        -o-transform: none;
        -ms-transform: none;
        transform: none;
    }
}

@media (max-width: 500px) {
    .quick-view-single-product .view-single-product-inner {
        width: 300px;
        left: calc(50% - 150px);

        /* width: 360px;
        left: calc(50% - 180px); */
    }
}

.quick-view-single-product .product-details,
.quick-view-single-product .img-holder {
    width: 50%;
    float: left;
}

@media (max-width: 900px) {
    .quick-view-single-product .product-details,
    .quick-view-single-product .img-holder {
        width: 100%;
        float: none;
    }
}

.quick-view-single-product .product-details {
    padding: 45px;
    overflow-y: scroll;
}

@media (max-width: 1200px) {
    .quick-view-single-product .product-details {
        height: 505px;
    }
}

@media (max-width: 900px) {
    .quick-view-single-product .product-details {
        height: auto;
    }
}

@media (max-width: 500px) {
    .quick-view-single-product .product-details {
        padding: 35px 20px;
    }
}

.quick-view-single-product h4 {
    font-size: 40px;
    font-size: 2.6666666667rem;
    margin: 0 0 0.5em;
}

@media (max-width: 767px) {
    .quick-view-single-product h4 {
        font-size: 22px;
        font-size: 1.4666666667rem;
    }
}

.quick-view-single-product .price {
    /* font-family: "futura-pt-demi", sans-serif; */
    font-size: 20px;
    font-size: 1.3333333333rem;
    margin: 0.4em 0 0.5em;
}

.quick-view-single-product .price .old {
    font-size: 20px;
    font-size: 1.3333333333rem;
    font-weight: normal;
    color: #ababab;
    text-decoration: line-through;
    display: inline-block;
    margin-left: 5px;
}

@media (max-width: 991px) {
    .quick-view-single-product .price .old {
        font-size: 18px;
        font-size: 1.2rem;
    }
}

.quick-view-single-product .rating {
    margin-bottom: 25px;
}

.quick-view-single-product .rating .fi:before {
    
    
    color: #c2572b;
}

.quick-view-single-product p {
    font-size: 15px;
    font-size: 1rem;
    margin-bottom: 1.3em;
}

.quick-view-single-product p:last-child {
    margin: 0;
}

.quick-view-single-product .product-option {
    margin: 35px 0;
}

.quick-view-single-product .product-option .btn {
    padding: 7px 4px 7px 6px;
    font-size: 8px;
    font-weight: normal;
    position: relative;
}

.quick-view-single-product .product-option .btn:hover {
    background-color: #000 !important;
}

.quick-view-single-product .product-option .product-count {
    height: 50px;
    border-right: 0;
}

.quick-view-single-product .product-option .input-group.bootstrap-touchspin {
    width: 65px;
    position: relative;
}

.quick-view-single-product .product-option .input-group-btn-vertical {
    position: absolute;
    top: -3px;
    z-index: 2;
}

.quick-view-single-product .product-option .bootstrap-touchspin-up {
    top: 3px;
}

.quick-view-single-product .product-option .bootstrap-touchspin-down {
    bottom: 1px;
}

.quick-view-single-product .product-option form button[type="submit"] {
    
    border: 0;
}

.quick-view-single-product .product-option .product-row {
    overflow: hidden;
}

.quick-view-single-product .product-option .product-row button:after {
    display: none;
}

.quick-view-single-product .product-option .product-row > div {
    display: inline-block;
    float: left;
}

.quick-view-single-product .product-option .product-row > div + div {
    margin-left: 15px;
}

@media (max-width: 500px) {
    .quick-view-single-product .product-option .product-row > div + div {
        margin: 0 0 25px 0;
    }
}

.quick-view-single-product .product-option .product-row > div:first-child {
    width: 85px;
}

.quick-view-single-product .product-option .product-row > div:last-child .theme-btn:hover, .quick-view-single-product .product-option .product-row > div:last-child .theme-btn-s2:hover, .quick-view-single-product .product-option .product-row > div:last-child .theme-btn-s3:hover, .quick-view-single-product .product-option .product-row > div:last-child .theme-btn-s4:hover, .quick-view-single-product .product-option .product-row > div:last-child .theme-btn-s6:hover, .quick-view-single-product .product-option .product-row > div:last-child .theme-btn-s7:hover, .quick-view-single-product .product-option .product-row > div:last-child .theme-btn-s5:hover {
    background: #c2572b;
    border-color: #c2572b;
    color: #fff;
}

.quick-view-single-product .product-option .heart-btn i {
    font-size: 15px;
    font-size: 1rem;
}

.quick-view-single-product .product-option .product-row > div:last-child .theme-btn, .quick-view-single-product .product-option .product-row > div:last-child .theme-btn-s2, .quick-view-single-product .product-option .product-row > div:last-child .theme-btn-s3, .quick-view-single-product .product-option .product-row > div:last-child .theme-btn-s4, .quick-view-single-product .product-option .product-row > div:last-child .theme-btn-s6, .quick-view-single-product .product-option .product-row > div:last-child .theme-btn-s7, .quick-view-single-product .product-option .product-row > div:last-child .theme-btn-s5 {
    font-size: 18px;
    font-size: 1.2rem;
}

.quick-view-single-product .product-count {
    border-radius: 20;
    border: 1px solid #e6e5e5;
}

.quick-view-single-product .product-count:focus {
    box-shadow: none;
    outline: none;
}

.quick-view-single-product .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up,
.quick-view-single-product .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
    border-radius: 20;
    border-color: #e6e5e5;
}

.quick-view-single-product .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up:hover,
.quick-view-single-product .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down:hover {
    background-color: #c2572b;
    color: #fff;
}

.quick-view-single-product .thb-product-meta-before .add-to-wishlist {
    margin-bottom: 20px;
}

.quick-view-single-product .thb-product-meta-before .add-to-wishlist i {
    font-size: 20px;
    font-size: 1.3333333333rem;
    position: relative;
    top: 4px;
    display: inline-block;
    padding-right: 3px;
}

.quick-view-single-product .thb-product-meta-before .add-to-wishlist a {
    font-size: 14px;
    font-size: 0.9333333333rem;
    color: #000;
    font-weight: 500;
}

.quick-view-single-product .thb-product-meta-before .add-to-wishlist a:hover {
    color: #c2572b;
}

.quick-view-single-product .thb-product-meta-before .product_meta > span {
    display: block;
    font-size: 14px;
    font-size: 0.9333333333rem;
    font-weight: 500;
    margin-bottom: 5px;
}

.quick-view-single-product .thb-product-meta-before .product_meta > span a {
    font-weight: 400;
    
}

.quick-view-single-product .thb-product-meta-before .product_meta > span a:hover {
    text-decoration: underline;
}

.active-newsletter-popup {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 1s ease-in-out, z-index 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out, z-index 1s ease-in-out;
    transition: opacity 1s ease-in-out, z-index 1s ease-in-out;
}

.active-newsletter-popup,
.activve-quick-view-single-product {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.2s ease-in-out, z-index 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out, z-index 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out, z-index 0.2s ease-in-out;
}

/*** back to top **/
.back-to-top {
    background-color: #fff;
    color: red;
    width: 35px;
    height: 35px;
    line-height: 35px;
    border: 1px solid rgba(255, 255, 255, 0.05);
    text-align: center;
    position: absolute;
    z-index: 999;
    left: calc(50% - 17px);
    top: -22.5px;
    border-radius: 50%;
    border: 2px solid #e0e0e0;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
}

@media (max-width: 991px) {
    .back-to-top {
        width: 40px;
        height: 40px;
        line-height: 40px;
    }
}

.back-to-top:hover {
    background-color: #000;
    border-color: #000;
}

.back-to-top:hover i {
    color: #fff;
}

.back-to-top i {
    
    
    
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@media (max-width: 991px) {
    .back-to-top i {
        font-size: 15px;
        font-size: 1rem;
    }
}

/*** woocommerce ***/
.woocommerce-Button,
.woocommerce-button {
    background: rgba(0, 0, 0, 0.9);
    
    
    font-weight: 500;
    padding: 12px 30px;
    color: #fff;
    margin-top: 20px;
    color: white;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    text-transform: uppercase;
    letter-spacing: 3px;
    border: 0;
}

.woocommerce-button-account {
    background: rgba(0, 0, 0, 0.9);
    
    
    font-weight: 500;
    padding: 12px 30px;
    color: #fff;
    /* margin-top: 20px; */
    color: white;
    display: inline-block;
    /* margin-left: auto; */
    /* margin-right: auto; */
    text-transform: uppercase;
    letter-spacing: 3px;
    border: 0;
}


.woocommerce-Button:hover,
.woocommerce-button:hover {
    background: #000;
}

.woocommerce-info:before {
    display: none;
}

.woocommerce-privacy-policy-text a {
    font-weight: 500;
    color: #c2572b;
    text-decoration: underline;
}

.woocommerce-error a,
.woocommerce-info a,
.woocommerce-message a,
.lost_password a {
    
}

.woocommerce-error a:hover,
.woocommerce-info a:hover,
.woocommerce-message a:hover,
.lost_password a:hover {
    text-decoration: underline;
}

.woocommerce-error, .woocommerce-info,
.woocommerce-message {
    /* font-family: "futura-pt-demi", sans-serif; */
    background-color: rgba(191, 171, 112, 0.1);
    width: auto;
    padding: 15px 20px;
    border: 1px solid rgba(191, 171, 112, 0.2);
    padding: 15px 15px 15px 35px;
    margin: 0 0 2em;
    position: relative;
    color: #515151;
    list-style: none;
    word-wrap: break-word;
}

@media (max-width: 767px) {
    .woocommerce-error, .woocommerce-info,
    .woocommerce-message {
        padding: 15px;
    }
}

.woocommerce form.checkout_coupon,
.woocommerce form.login,
.woocommerce form.register {
    padding: 34px 35px 33px;
    border: 1px solid #E5E5E5;
    margin: 19px 0;
    text-align: left;
    border-radius: 20;
}

@media (max-width: 767px) {
    .woocommerce form.checkout_coupon,
    .woocommerce form.login,
    .woocommerce form.register {
        padding: 34px 20px 33px;
    }
}

.woocommerce form .form-row .required {
    color: red;
    font-weight: 600;
    border: 0;
}

.woocommerce form.login > p:not(.form-row) {
    margin-bottom: 2em;
}

#customer_login form.login > p:not(.form-row) {
    margin-top: 2em;
    margin-bottom: 0;
}

.woocommerce form.login input[type="submit"],
.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button {
    /* font-family: "futura-pt-demi", sans-serif; */
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    
    
    font-weight: 500;
    padding: 14px 35px;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: inline-block;
    border: 0;
    border-radius: 20;
    outline: 0;
}

.woocommerce form.login input[type="submit"]:hover, .woocommerce form.login input[type="submit"]:focus, .woocommerce form.login input[type="submit"]:active,
.woocommerce #respond input#submit:hover,
.woocommerce #respond input#submit:focus,
.woocommerce #respond input#submit:active,
.woocommerce a.button:hover,
.woocommerce a.button:focus,
.woocommerce a.button:active,
.woocommerce button.button:hover,
.woocommerce button.button:focus,
.woocommerce button.button:active,
.woocommerce input.button:hover,
.woocommerce input.button:focus,
.woocommerce input.button:active {
    background-color: #000;
    color: #fff;
}

.woocommerce form .form-row input.input-text,
.woocommerce form .form-row textarea {
    padding: 12px 15px;
}

.woocommerce form .form-row label.inline {
    display: inline;
    padding-left: 15px;
}

.woocommerce h3 {
    font-size: 22px;
    font-size: 1.4666666667rem;
    margin: 1em 0 1.5em;
}

abbr,
abbr[title] {
    border-bottom: 1px dotted #0a0a0a;
    cursor: help;
    text-decoration: none;
}

@media (max-width: 767px) {
    .woocommerce form .form-row label.inline {
        padding-left: 5px;
    }
}

.wow {
    visibility: hidden;
}

.fi:before {
    margin: 0;
}

.section-padding {
    padding: 120px 0;
}

@media (max-width: 991px) {
    .section-padding {
        padding: 100px 0;
    }
}

@media (max-width: 767px) {
    .section-padding {
        padding: 120px 0;
    }
}

/**/
.section-padding-medium {
    padding: 30px 0;
}
@media (max-width: 991px) {
    .section-padding-medium {
        padding: 100px 0;
    }
}
@media (max-width: 767px) {
    .section-padding-medium {
        padding: 90px 0;
    }
}
/**/

/** for popup image ***/
.mfp-wrap {
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 99999;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
    opacity: 0;
}

/*** for fancybox video ***/
.fancybox-overlay {
    background: rgba(0, 0, 0, 0.9);
    z-index: 9999 !important;
}

.fancybox-wrap {
    z-index: 99999 !important;
}

.section-title, .section-title-s3, .section-title-s4 {
    text-align: center;
    margin-bottom: 40px;
}

@media (max-width: 767px) {
    .section-title, .section-title-s3, .section-title-s4 {
        margin-bottom: 30px;
    }
}

.section-title > span, .section-title-s3 > span, .section-title-s4 > span {
    font-size: 14px;
    font-size: 0.9333333333rem;
    font-weight: 500;
    color: #c2572b;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: -0.4em 0 0.6em;
    display: block;
}

.section-title h2, .section-title-s3 h2, .section-title-s4 h2 {
    font-size: 42px;
    font-size: 2.8rem;
    margin: -0.2em 0 0.2em;
    position: relative;
    text-transform: capitalize;
}

@media (max-width: 767px) {
    .section-title h2, .section-title-s3 h2, .section-title-s4 h2 {
        font-size: 28px;
        font-size: 1.8666666667rem;
    }
}

@media (max-width: 330px) {
    .section-title h2, .section-title-s3 h2, .section-title-s4 h2 {
        font-size: 26px;
        font-size: 1.7333333333rem;
    }
}

.section-title-s2 {
    margin-bottom: 55px;
    position: relative;
}

.section-title-s2:after {
    content: "";
    background-color: #d6d6d6;
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: -24px;
}

@media (max-width: 1199px) {
    .section-title-s2:after {
        bottom: -23px;
    }
}

@media (max-width: 991px) {
    .section-title-s2:after {
        bottom: -27px;
    }
}

@media (max-width: 767px) {
    .section-title-s2:after {
        bottom: -50px;
    }
}

.section-title-s2 h2 {
    font-size: 42px;
    font-size: 2.8rem;
    margin: -0.2em 0;
    position: relative;
    text-transform: capitalize;
}

@media (max-width: 991px) {
    .section-title-s2 h2 {
        font-size: 28px;
        font-size: 1.8666666667rem;
    }
}

@media (max-width: 330px) {
    .section-title-s2 h2 {
        font-size: 26px;
        font-size: 1.7333333333rem;
    }
}

.section-title-s3 {
    margin-bottom: 15px;
}

.section-title-s3 h2 {
    margin-bottom: 0.2em;
}

.section-title-s4 {
    text-align: left;
}

.section-title-s4 h2,
.section-title-s4 p {
    color: #fff;
}

.theme-btn, .theme-btn-s2, .theme-btn-s3, .theme-btn-s4, .theme-btn-s6, .theme-btn-s7, .theme-btn-s5,
button {
    /* font-family: "futura-pt-demi", sans-serif; */
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    
    
    font-weight: 500;
    padding: 16px 35px;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: inline-block;
}

.theme-btn:hover, .theme-btn-s2:hover, .theme-btn-s3:hover, .theme-btn-s4:hover, .theme-btn-s6:hover, .theme-btn-s7:hover, .theme-btn-s5:hover, .theme-btn:focus, .theme-btn-s2:focus, .theme-btn-s3:focus, .theme-btn-s4:focus, .theme-btn-s6:focus, .theme-btn-s7:focus, .theme-btn-s5:focus, .theme-btn:active, .theme-btn-s2:active, .theme-btn-s3:active, .theme-btn-s4:active, .theme-btn-s6:active, .theme-btn-s7:active, .theme-btn-s5:active,
button:hover,
button:focus,
button:active {
    background-color: #000;
    color: #fff;
}

.theme-btn-s2 {
    background-color: #fff;
    color: #000;
}

.theme-btn-s2:hover, .theme-btn-s2:focus, .theme-btn-s2:active {
    color: #fff;
}

.theme-btn-s3, .theme-btn-s4, .theme-btn-s6, .theme-btn-s7 {
    background-color: transparent;
    border: 1px solid #000;
    color: #000;
}

.theme-btn-s3:hover, .theme-btn-s4:hover, .theme-btn-s6:hover, .theme-btn-s7:hover, .theme-btn-s3:focus, .theme-btn-s4:focus, .theme-btn-s6:focus, .theme-btn-s7:focus {
    background-color: #000;
    color: #fff;
    text-decoration: none;
}

.theme-btn-s4, .theme-btn-s6, .theme-btn-s7 {
    color: #fff;
    border-color: #fff;
}

.theme-btn-s4:hover, .theme-btn-s6:hover, .theme-btn-s7:hover, .theme-btn-s4:focus, .theme-btn-s6:focus, .theme-btn-s7:focus {
    background-color: #fff;
    color: #000;
}

@media screen and (min-width: 992px) {
    .theme-btn-s4, .theme-btn-s6, .theme-btn-s7 {
        padding: 17px 55px;
    }
}

.theme-btn-s5 {
    
    
    padding: 17px 45px;
}

@media (max-width: 991px) {
    .theme-btn-s5 {
        
        
        padding: 15px 30px;
    }
}

.theme-btn-s6 {
    padding: 16px 35px;
}

@media (max-width: 991px) {
    .theme-btn-s6 {
        padding: 13px 30px;
    }
}

@media (max-width: 767px) {
    .theme-btn-s6 {
        padding: 10px 20px;
    }
}

.theme-btn-s7 {
    background-color: #fff;
    color: #000;
}

@media screen and (min-width: 992px) {
    .theme-btn-s7 {
        padding: 17px 55px;
    }
}

@media (max-width: 767px) {
    .theme-btn-s7 {
        font-size: 10px;
        font-size: 0.6666666667rem;
        padding: 12px 30px;
    }
}

.theme-btn-s7:hover {
    background-color: #000;
    color: #fff;
    border-color: #000;
}

.view-cart-btn {
    /* font-family: "futura-pt-demi", sans-serif; */
    background: #ddd;
    width: 100%;
    
    
    font-weight: 500;
    padding: 12px 20px;
    color: #000;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.checkout-btn {
    /* font-family: "futura-pt-demi", sans-serif; */
    background: #053040;
    width: 100%;
    
    
    font-weight: 500;
    padding: 12px 20px;
    color: #fff;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.valor-hijab-btn {
    /* font-family: "futura-pt-demi", sans-serif; */
    /* background: #053040; */
    /* width: 100%; */
    
    
    font-weight: 500;
    padding: 12px 20px;
    color: #fff;
    display: block;
    /* margin-left: auto; */
    /* margin-right: auto; */
    text-transform: uppercase;
    letter-spacing: 3px;
}
.valor-hijab-btn:hover {
    /* background-color: #777777; */
    color: #fff;
}



.view-cart-btn:hover, .checkout-btn:hover {
    background-color: #777777;
    /* background-color: #c2572b; */
    color: #fff;
}


.checkout-btn:hover {
    /* background-color: #c2572b; */
    color: #fff;
}

.form input,
.form textarea,
.form select {
    border-color: #bfbfbf;
    border-radius: 20;
    outline: 0;
    box-shadow: none;
    color: #595959;
}

.form input:focus,
.form textarea:focus,
.form select:focus {
    border-color: #c2572b;
    -webkit-box-shadow: 0 0 5px 0 #d77249;
    -moz-box-shadow: 0 0 5px 0 #d77249;
    -o-box-shadow: 0 0 5px 0 #d77249;
    -ms-box-shadow: 0 0 5px 0 #d77249;
    box-shadow: 0 0 5px 0 #d77249;
}

.form ::-webkit-input-placeholder {
    font-style: 14px;
    font-style: italic;
    color: #595959;
}

.form :-moz-placeholder {
    font-style: 14px;
    font-style: italic;
    color: #595959;
}

.form ::-moz-placeholder {
    font-style: 14px;
    font-style: italic;
    color: #595959;
}

.form :-ms-input-placeholder {
    font-style: 14px;
    font-style: italic;
    color: #595959;
}

.form select {
    font-style: italic;
    /* background: url(../images/select-icon.png) no-repeat right center; */
    display: inline-block;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    cursor: pointer;
}

.form select::-ms-expand {
    /* for IE 11 */
    display: none;
}

/******************************
	#page title
******************************/
.page-title {
    background-color: #f1f1f1;
    width: 100%;
    padding: 100px 0;
    position: relative;
    word-break: break-all;
}

@media (max-width: 991px) {
    .page-title {
        height: 300px;
    }
}

.page-title .page-title-container {
    width: 100%;
    height: 100%;
    z-index: 1;
}

.page-title .page-title-wrapper {
    text-align: center;
}

.page-title h2,
.page-title ol {
    color: #fff;
}

.page-title h2 {
    font-size: 40px;
    font-size: 2.6666666667rem;
    color: #000;
    margin: 0 0 0.2em;
    text-transform: capitalize;
}

.page-title .breadcrumb {
    background-color: transparent;
    padding: 0;
    margin-bottom: 0;
}

@media (max-width: 767px) {
    .page-title .breadcrumb {
        text-align: center;
    }
}

.page-title .breadcrumb li {
    font-size: 14px;
    font-size: 0.9333333333rem;
    
    margin-right: 5px;
    position: relative;
}

@media (max-width: 991px) {
    .page-title .breadcrumb li {
        font-size: 16px;
        font-size: 1.0666666667rem;
    }
}

@media (max-width: 767px) {
    .page-title .breadcrumb li {
        font-size: 14px;
        font-size: 0.9333333333rem;
    }
}

.page-title .breadcrumb li i {
    font-size: 25px;
    font-size: 1.6666666667rem;
}

.page-title .breadcrumb li a {
    color: #000;
}

.page-title .breadcrumb li a:hover {
    color: #c2572b;
}

.page-title .breadcrumb > li + li {
    margin-left: 17px;
    padding-left: 12px;
}

.page-title .breadcrumb > li + li:before {
    /* font-family: "Themify"; */
    content: "\e649";
    font-size: 10px;
    font-size: 0.6666666667rem;
    color: #000;
    padding: 0;
    position: absolute;
    left: -15px;
    top: 3px;
    border-radius: 50%;
}

.page-title-s2 {
    height: 400px;
}

@media (max-width: 991px) {
    .page-title-s2 {
        height: 300px;
    }
}

.page-title-s2 .container {
    padding-top: 0;
}

.preloader {
    background-color: #fff;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.preloader .sk-chase {
    width: 70px;
    height: 70px;
    position: relative;
    animation: sk-chase 2.5s infinite linear both;
}

.preloader .sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation: sk-chase-dot 2.0s infinite ease-in-out both;
}

.preloader .sk-chase-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: #c2572b;
    border-radius: 100%;
    animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
}

.preloader .sk-chase-dot:nth-child(1) {
    animation-delay: -1.1s;
}

.preloader .sk-chase-dot:nth-child(2) {
    animation-delay: -1.0s;
}

.preloader .sk-chase-dot:nth-child(3) {
    animation-delay: -0.9s;
}

.preloader .sk-chase-dot:nth-child(4) {
    animation-delay: -0.8s;
}

.preloader .sk-chase-dot:nth-child(5) {
    animation-delay: -0.7s;
}

.preloader .sk-chase-dot:nth-child(6) {
    animation-delay: -0.6s;
}

.preloader .sk-chase-dot:nth-child(1):before {
    animation-delay: -1.1s;
}

.preloader .sk-chase-dot:nth-child(2):before {
    animation-delay: -1.0s;
}

.preloader .sk-chase-dot:nth-child(3):before {
    animation-delay: -0.9s;
}

.preloader .sk-chase-dot:nth-child(4):before {
    animation-delay: -0.8s;
}

.preloader .sk-chase-dot:nth-child(5):before {
    animation-delay: -0.7s;
}

.preloader .sk-chase-dot:nth-child(6):before {
    animation-delay: -0.6s;
}

@keyframes sk-chase {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes sk-chase-dot {
    80%, 100% {
        transform: rotate(360deg);
    }
}

@keyframes sk-chase-dot-before {
    50% {
        transform: scale(0.4);
    }
    100%, 0% {
        transform: scale(1);
    }
}

/*------------------------------------
	blog sidebar
------------------------------------*/
.blog-sidebar {
    /*** search-widget ***/
    /*** category-widget ***/
    /*** recent-post-widget ***/
    /*** tag-widget ***/
    /*** about-widget ***/
}

@media (max-width: 991px) {
    .blog-sidebar {
        margin-top: 80px;
        max-width: 400px;
    }
}

@media (max-width: 767px) {
    .blog-sidebar {
        margin-top: 60px;
        max-width: 400px;
    }
}

.blog-sidebar .widget h3 {
    font-size: 18px;
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0 0 1.3em;
    position: relative;
}

.blog-sidebar > .widget + .widget {
    margin-top: 65px;
}

.blog-sidebar .search-widget form div {
    position: relative;
}

.blog-sidebar .search-widget input {
    font-size: 14px;
    font-size: 0.9333333333rem;
}

.blog-sidebar .search-widget form button {
    background-color: transparent;
    padding: 0;
    border: 0;
    outline: 0;
    position: absolute;
    right: 15px;
    top: 15px;
    border-radius: 20;
    
}

.blog-sidebar .search-widget form button i {
    font-size: 18px;
    font-size: 1.2rem;
}

.blog-sidebar .category-widget ul {
    list-style: none;
}

.blog-sidebar .category-widget ul li {
    font-size: 14px;
    font-size: 0.9333333333rem;
    font-weight: 500;
    position: relative;
}

.blog-sidebar .category-widget ul li span {
    display: inline-block;
    padding-left: 5px;
}

.blog-sidebar .category-widget ul > li + li {
    margin-top: 5px;
    padding-top: 5px;
}

.blog-sidebar .category-widget ul a {
    display: block;
    
    position: relative;
}

.blog-sidebar .category-widget ul a:hover {
    color: #c2572b;
}

.blog-sidebar .recent-post-widget .post {
    overflow: hidden;
}

.blog-sidebar .recent-post-widget .posts > .post + .post {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #f3f2f2;
}

.blog-sidebar .recent-post-widget .post .img-holder {
    width: 60px;
    float: left;
}

.blog-sidebar .recent-post-widget .post .details {
    width: calc(100% - 68px);
    float: left;
    padding-left: 15px;
}

.blog-sidebar .recent-post-widget .post h4 {
    font-size: 14px;
    font-size: 0.9333333333rem;
    line-height: 1.3em;
    margin: 0 0 0.3em;
}

@media (max-width: 1199px) {
    .blog-sidebar .recent-post-widget .post h4 {
        margin: 0;
    }
}

.blog-sidebar .recent-post-widget .post h4 a {
    font-weight: 500;
    display: inline-block;
    color: #000;
}

.blog-sidebar .recent-post-widget .post h4 a:hover {
    color: #c2572b;
}

.blog-sidebar .recent-post-widget .post .details .date {
    
    
    font-weight: 500;
}

.blog-sidebar .tag-widget ul {
    overflow: hidden;
    list-style: none;
}

.blog-sidebar .tag-widget ul li {
    float: left;
    margin: 0 10px 7px 0;
    position: relative;
}

.blog-sidebar .tag-widget ul li:after {
    content: ",";
    
    position: absolute;
    right: -5px;
    bottom: 1px;
}

.blog-sidebar .tag-widget ul > li:last-child:after {
    display: none;
}

.blog-sidebar .tag-widget ul li a {
    font-size: 14px;
    font-size: 0.9333333333rem;
    display: inline-block;
    
}

.blog-sidebar .tag-widget ul li a:hover {
    color: #c2572b;
}

.blog-sidebar .about-widget {
    font-size: 14px;
    font-size: 0.9333333333rem;
}

/**** pagination ****/
.pagination-wrapper {
    text-align: center;
    margin-top: 40px;
}

@media (max-width: 991px) {
    .pagination-wrapper {
        text-align: left;
        margin-top: 40px;
    }
}

.pagination-wrapper .pg-pagination {
    display: inline-block;
    overflow: hidden;
    list-style-type: none;
    text-align: center;
}

.pagination-wrapper .pg-pagination li {
    float: left;
    margin-right: 10px;
}

@media (max-width: 767px) {
    .pagination-wrapper .pg-pagination li {
        margin-right: 5px;
    }
}

.pagination-wrapper .pg-pagination li:last-child {
    margin-right: 0;
}

.pagination-wrapper .pg-pagination li a {
    /* font-family: "futura-pt-demi", sans-serif; */
    background-color: transparent;
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 14px;
    font-size: 0.9333333333rem;
    font-weight: 500;
    color: #000;
    display: block;
}

@media (max-width: 991px) {
    .pagination-wrapper .pg-pagination li a {
        width: 40px;
        height: 40px;
        line-height: 38px;
        font-size: 15px;
        font-size: 1rem;
    }
}

.pagination-wrapper .pg-pagination .active a,
.pagination-wrapper .pg-pagination li a:hover {
    background: #000;
    border-color: #000;
    color: #fff;
}

.pagination-wrapper .pg-pagination .fi:before {
    font-size: 15px;
    font-size: 1rem;
}

.pagination-wrapper-left {
    text-align: left;
}

.pagination-wrapper-right {
    text-align: right;
}

@media screen and (min-width: 1200px) {
    .pagination-wrapper-right {
        padding-right: 50px;
    }
}

@media (max-width: 991px) {
    .pagination-wrapper-right {
        margin-top: 45px;
        text-align: left;
    }
}

.woocommerce table.shop_table {
    border: 0;
    border-radius: 20;
}

.shop_table tfoot tr th {
    font-weight: normal;
}

.woocommerce table.shop_table tbody th,
.woocommerce table.shop_table tfoot td,
.woocommerce table.shop_table tfoot th {
    font-weight: 500;
}

.shop_table {
    table-layout: auto;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    text-align: left;
}

.shop_table thead th {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.shop_table.woocommerce-checkout-review-order-table thead tr:first-child th {
    padding-top: 0;
}

.shop_table thead tr th:first-child {
    text-align: left;
}

.shop_table.woocommerce-checkout-review-order-table th,
.shop_table.woocommerce-checkout-review-order-table td {
    padding-top: 15px;
    padding-bottom: 15px;
}

.shop_table th, .shop_table td {
    padding: 25px 0;
}

.shop_table.woocommerce-checkout-review-order-table th,
.shop_table.woocommerce-checkout-review-order-table td {
    padding-top: 15px;
    padding-bottom: 15px;
}

.shop_table tbody tr td {
    padding-right: 25px;
}

.shop_table th, .shop_table td {
    padding: 25px 0;
}

.add_payment_method #payment,
.woocommerce-cart #payment,
.woocommerce-checkout #payment {
    background: #f5f5f5;
    border-radius: 20;
    padding: 25px;
}

#add_payment_method #payment ul.payment_methods,
.woocommerce-cart #payment ul.payment_methods,
.woocommerce-checkout #payment ul.payment_methods {
    padding: 0;
}

#add_payment_method #payment div.payment_box,
.woocommerce-cart #payment div.payment_box,
.woocommerce-checkout #payment div.payment_box {
    background-color: #e6e6e6;
}

#add_payment_method #payment div.payment_box:before,
.woocommerce-cart #payment div.payment_box:before,
.woocommerce-checkout #payment div.payment_box:before {
    border: 1em solid #e6e6e6;
    border-right-color: transparent;
    border-left-color: transparent;
    border-top-color: transparent;
}

#add_payment_method #payment div.form-row,
.woocommerce-cart #payment div.form-row,
.woocommerce-checkout #payment div.form-row {
    padding-left: 0;
}

.woocommerce table.shop_table td {
    border-top: 1px solid #e5e5e5;
}

.woocommerce-cart .woocommerce table.shop_table td.actions {
    background-color: #fcfcfc;
    padding-top: 19px;
    padding-bottom: 19px;
}

.woocommerce table.shop_table th, .woocommerce table.shop_table td {
    font-size: 15px;
    font-size: 1rem;
    padding: 17px 19px 15px;
}

.woocommerce #content table.cart td.actions .coupon,
.woocommerce table.cart td.actions .coupon,
.woocommerce-page #content table.cart td.actions .coupon,
.woocommerce-page table.cart td.actions .coupon {
    float: left;
}

/*------------------------------------
	blog sidebar
------------------------------------*/
.shop-sidebar {
    /*** search-widget ***/
    /*** widget_price_filter ***/
    /*** widget_product_categories ***/
    /*** widget_product_tag_cloud ***/
}

.shop-sidebar .widget h3 {
    font-size: 18px;
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0 0 1.3em;
    position: relative;
    text-transform: none;
}

.shop-sidebar > .widget + .widget {
    margin-top: 65px;
}

.shop-sidebar .search-widget form div {
    position: relative;
}

.shop-sidebar .search-widget input {
    font-size: 14px;
    font-size: 0.9333333333rem;
}

.shop-sidebar .search-widget form button {
    background-color: transparent;
    
    padding: 0;
    border: 0;
    outline: 0;
    position: absolute;
    right: 15px;
    top: 15px;
    border-radius: 20;
}

.shop-sidebar .search-widget form button i {
    font-size: 18px;
    font-size: 1.2rem;
}

.shop-sidebar .widget_price_filter .ui-corner-all,
.shop-sidebar .widget_price_filter .ui-corner-bottom,
.shop-sidebar .widget_price_filter .ui-corner-right,
.shop-sidebar .widget_price_filter .ui-corner-br {
    border-radius: 30px;
}

.shop-sidebar .widget_price_filter .filter-price {
    position: relative;
    font-size: 14px;
}

.shop-sidebar .widget_price_filter .filter-price p {
    position: absolute;
    left: 148px;
    bottom: -4px;
}

.shop-sidebar .widget_price_filter .filter-price input {
    border: 0;
    padding: 0;
    display: inline-block;
    width: auto;
    position: absolute;
}

.shop-sidebar .widget_price_filter .filter-price input:focus {
    border: 0;
    outline: none;
}

.shop-sidebar .widget_price_filter .filter-price button {
    /* font-family: "futura-pt-demi", sans-serif; */
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    
    
    font-weight: 500;
    padding: 9px 35px;
    text-transform: uppercase;
    letter-spacing: 0;
    display: inline-block;
    border: 0;
    outline: none;
}

.shop-sidebar .widget_price_filter .filter-price button:hover, .shop-sidebar .widget_price_filter .filter-price button:focus, .shop-sidebar .widget_price_filter .filter-price button:active {
    background-color: #000;
    color: #fff;
}

.shop-sidebar .widget_price_filter .filter-price #amount {
    color: #000;
}

.shop-sidebar .widget_price_filter #slider-range {
    height: 3px;
    background: #a1a1a1;
    border: 0;
    border-radius: 50px;
    margin-bottom: 25px;
}

.shop-sidebar .widget_price_filter .ui-state-default,
.shop-sidebar .widget_price_filter .ui-widget-content .ui-state-default,
.shop-sidebar .widget_price_filter .ui-widget-header .ui-state-default {
    width: 10px;
    height: 10px;
    border: none;
    background: #000;
    top: -4px;
}

.shop-sidebar .widget_price_filter .ui-state-default:focus,
.shop-sidebar .widget_price_filter .ui-widget-content .ui-state-default:focus,
.shop-sidebar .widget_price_filter .ui-widget-header .ui-state-default:focus {
    outline: none;
}

.shop-sidebar .widget_product_categories,
.shop-sidebar .woocommerce-widget-layered-nav {
    /* font-family: "Gothic A1", sans-serif; */
}

.shop-sidebar .widget_product_categories .product-categories a,
.shop-sidebar .widget_product_categories .woocommerce-widget-layered-nav-list a,
.shop-sidebar .woocommerce-widget-layered-nav .product-categories a,
.shop-sidebar .woocommerce-widget-layered-nav .woocommerce-widget-layered-nav-list a {
    
}

.shop-sidebar .widget_product_categories .product-categories li,
.shop-sidebar .widget_product_categories .woocommerce-widget-layered-nav-list li,
.shop-sidebar .woocommerce-widget-layered-nav .product-categories li,
.shop-sidebar .woocommerce-widget-layered-nav .woocommerce-widget-layered-nav-list li {
    margin-top: 10px;
    position: relative;
}

.shop-sidebar .widget_product_categories .product-categories li a,
.shop-sidebar .widget_product_categories .woocommerce-widget-layered-nav-list li a,
.shop-sidebar .woocommerce-widget-layered-nav .product-categories li a,
.shop-sidebar .woocommerce-widget-layered-nav .woocommerce-widget-layered-nav-list li a {
    font-size: 14px;
    font-size: 0.9333333333rem;
    font-weight: 500;
    position: relative;
}

.shop-sidebar .widget_product_categories .product-categories li a:hover,
.shop-sidebar .widget_product_categories .woocommerce-widget-layered-nav-list li a:hover,
.shop-sidebar .woocommerce-widget-layered-nav .product-categories li a:hover,
.shop-sidebar .woocommerce-widget-layered-nav .woocommerce-widget-layered-nav-list li a:hover {
    text-decoration: underline;
    color: #000;
}

.shop-sidebar .widget_product_categories .product-categories li a:hover:before,
.shop-sidebar .widget_product_categories .woocommerce-widget-layered-nav-list li a:hover:before,
.shop-sidebar .woocommerce-widget-layered-nav .product-categories li a:hover:before,
.shop-sidebar .woocommerce-widget-layered-nav .woocommerce-widget-layered-nav-list li a:hover:before {
    border-color: #000;
}

.shop-sidebar .widget_product_categories .children,
.shop-sidebar .woocommerce-widget-layered-nav .children {
    padding-left: 15px;
}

.shop-sidebar .widget_product_tag_cloud .tagcloud {
    overflow: hidden;
    list-style: none;
}

.shop-sidebar .widget_product_tag_cloud .tagcloud a {
    float: left;
    margin: 0 8px 8px 0;
    
    
    font-weight: 500;
    display: inline-block;
    padding: 7px 14px;
    
    border: 1px solid #c7c7c7;
    border-radius: 2px;
}

.shop-sidebar .widget_product_tag_cloud .tagcloud a:hover {
    background: #000;
    color: #fff;
    border-color: #000;
}

/*--------------------------------------------------------------
#0.3	header
--------------------------------------------------------------*/
.site-header {
    /* navigation open and close btn hide for width screen */
    /* style for navigation less than 992px */
    /*navbar collaps less then 992px*/
}

.site-header .navigation {
    background-color: #fff;
    margin-bottom: 0;
    border: 0;
    -webkit-border-radius: 20;
    -moz-border-radius: 20;
    -o-border-radius: 20;
    -ms-border-radius: 20;
    border-radius: 20;
}

.site-header .navigation > .container {
    position: relative;
}

.site-header #navbar {
    /* font-family: "futura-pt-demi", sans-serif; */
    -webkit-transition: all 0.75s;
    -moz-transition: all 0.75s;
    -o-transition: all 0.75s;
    -ms-transition: all 0.75s;
    transition: all 0.75s;
    /*** mega-menu style ***/
}

.site-header #navbar > ul li a:hover,
.site-header #navbar > ul li a:focus,
.site-header #navbar .current-menu-parent .current-menu-item > a,
.site-header #navbar .current-menu-parent > a,
.site-header #navbar .current-menu-item > a {
    text-decoration: none;
    color: #c2572b !important;
}

@media screen and (min-width: 992px) {
    .site-header #navbar {
        /*** hover effect ***/
    }

    .site-header #navbar li {
        position: relative;
    }

    .site-header #navbar > ul > li > a {
        font-size: 16px;
        font-size: 1.0666666667rem;
        text-transform: capitalize;
        padding: 40px 20px;
        position: relative;
    }

    .site-header #navbar > ul > li.menu-item-has-children > a:before {
        /* font-family: "Themify"; */
        content: "\e64b";
        font-size: 8px;
        font-size: 0.5333333333rem;
        position: absolute;
        right: 6px;
    }

    .site-header #navbar > ul .sub-menu {
        background-color: #000;
        width: 240px;
        text-align: left;
        padding: 25px 0;
        position: absolute;
        left: 0;
        top: 100%;
        z-index: 10;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        -ms-transition: all 0.3s;
        transition: all 0.3s;
        box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
        text-transform: capitalize;
    }

    .site-header #navbar > ul > li .sub-menu li:last-child {
        border-bottom: 0;
    }

    .site-header #navbar > ul > li .sub-menu a {
        /* font-family: "Gothic A1", sans-serif; */
        display: block;
        padding: 10px 30px;
        color: #000;
        font-size: 14px;
        font-size: 0.9333333333rem;
        font-weight: 500;
    }

    .site-header #navbar > ul > li .sub-menu a:hover {
        padding-left: 35px;
    }

    .site-header #navbar > ul > li > .sub-menu .sub-menu {
        left: 95%;
        top: 0;
    }

    .site-header #navbar > ul > li > .sub-menu > .menu-item-has-children > a {
        position: relative;
    }

    .site-header #navbar > ul > li > .sub-menu > .menu-item-has-children > a:before {
        /* font-family: "themify"; */
        content: "\e649";
        font-size: 11px;
        font-size: 0.7333333333rem;
        position: absolute;
        right: 25px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .site-header #navbar > ul > li:hover > .sub-menu {
        top: 100%;
        visibility: visible;
        opacity: 1;
    }

    .site-header #navbar .sub-menu > li:hover > .sub-menu {
        left: 100%;
        visibility: visible;
        opacity: 1;
    }
}

@media (max-width: 991px) {
    .site-header #navbar > ul > li a {
        display: block;
        font-size: 14px;
        font-size: 0.9333333333rem;
    }

    .site-header #navbar > ul > li .sub-menu li {
        border-bottom: 1px solid #e6e5e5;
    }

    .site-header #navbar > ul .sub-menu > li:last-child {
        border-bottom: 0;
    }

    .site-header #navbar > ul > li > .sub-menu a {
        /* font-family: "Gothic A1", sans-serif; */
        padding: 8px 15px 8px 45px;
    }

    .site-header #navbar > ul > li > .sub-menu .sub-menu a {
        padding: 8px 15px 8px 65px;
    }

    .site-header #navbar > ul .menu-item-has-children > a {
        position: relative;
    }

    .site-header #navbar > ul .menu-item-has-children > a:after {
        /* font-family: "themify"; */
        content: "\e61a";
        font-size: 11px;
        font-size: 0.7333333333rem;
        position: absolute;
        right: 15px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

@media screen and (min-width: 992px) {
    .site-header #navbar {
        /*** hover effect ***/
    }

    .site-header #navbar .has-mega-menu {
        position: static;
    }

    .site-header #navbar .mega-menu,
    .site-header #navbar .half-mega-menu {
        background-color: #fff;
        padding: 40px;
        position: absolute;
        left: 0;
        top: 100%;
        z-index: 10;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        -ms-transition: all 0.3s;
        transition: all 0.3s;
    }

    .site-header #navbar .mega-menu {
        width: 1140px;
        left: calc(50% - 570px);
        margin-left: initial;
        display: block;
        float: left;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    }

    .site-header #navbar .half-mega-menu {
        width: 585px;
    }

    .site-header #navbar .mega-menu-box-title {
        font-size: 14px;
        font-size: 0.9333333333rem;
        text-transform: uppercase;
        font-weight: 500;
        display: block;
        padding-bottom: 7px;
        margin-bottom: 7px;
    }

    .site-header #navbar .mega-menu-list-holder li a {
        /* font-family: "Gothic A1", sans-serif; */
        font-size: 14px;
        font-size: 0.9333333333rem;
        color: #000;
        display: block;
        padding: 7px 8px;
        margin-left: -8px;
    }

    .site-header #navbar .mega-menu-content {
        overflow: hidden;
    }

    .site-header #navbar .mega-menu-content > .col {
        width: 20%;
        float: left;
    }

    .site-header #navbar .has-mega-menu:hover > ul {
        top: 100%;
        visibility: visible;
        opacity: 1;
    }
}

@media (max-width: 1199px) {
    .site-header #navbar > ul .mega-menu {
        width: 950px;
        right: 15px;
    }

    .site-header #navbar > ul .half-mega-menu {
        width: 485px;
    }
}

@media (max-width: 991px) {
    .site-header #navbar > ul .mega-menu,
    .site-header #navbar > ul .half-mega-menu {
        width: auto;
    }

    .site-header #navbar > ul .mega-menu .row,
    .site-header #navbar > ul .half-mega-menu .row {
        margin: 0;
    }

    .site-header #navbar .mega-menu-content {
        overflow: hidden;
        padding-top: 15px;
    }

    .site-header #navbar .mega-menu-content > .col {
        width: 100%;
        float: left;
        margin-bottom: 25px;
    }
}

@media (max-width: 991px) {
    .site-header #navbar .mega-menu .mega-menu-list-holder a {
        /* font-family: "Gothic A1", sans-serif; */
        padding: 5px 15px 5px 40px;
    }

    .site-header #navbar .mega-menu .mega-menu-box-title {
        
        
        text-transform: uppercase;
        display: block;
        border-bottom: 1px dotted #b3b2b2;
        padding: 0 0 4px 5px;
        margin: 0 25px 8px 25px;
    }
}

@media screen and (min-width: 992px) {
    .site-header .navbar-header .open-btn {
        display: none;
    }

    .site-header #navbar .close-navbar {
        display: none;
    }
}

@media (max-width: 991px) {
    .site-header {
        /* class for show hide navigation */
    }

    .site-header .container {
        width: 100%;
    }

    .site-header .navbar-header button {
        /* background-color: #000; */
        background-color: 'transparent';
        width: 40px;
        height: 40px;
        border: 0;
        padding: 5px 10px;
        outline: 0;
        position: absolute;
        /* right: 15px; */
        left: 15px;
        top: 29px;
        /* border-radius: 50%; */
        z-index: 20;
    }

    .site-header .navbar-header button span {
        background-color: #fff;
        display: block;
        height: 2px;
        margin-bottom: 5px;
    }

    .site-header .navbar-header button span:last-child {
        margin: 0;
    }

    .site-header #navbar {
        /* background: #fff; */
        background: #000;
        /* background: 'rgba(0, 0, 0, 0.2)'; */
        display: block !important;
        /* width: 280px; */
        width: 240px;
        height: 100% !important;
        margin: 0;
        padding: 0;
        border-left: 1px solid #cccccc;
        border-right: 1px solid #cccccc;
        position: fixed;
        right: -330px;
        top: 0;
        z-index: 100;
    }

    .site-header #navbar ul a {
        color: #000;
    }

    .site-header #navbar ul a:hover,
    .site-header #navbar ul li.current a {
        color: #c2572b;
    }

    .site-header #navbar .navbar-nav {
        height: 100%;
        overflow: auto;
    }

    .site-header #navbar .close-navbar {
        background-color: #fff;
        width: 40px;
        height: 40px;
        padding: 0;
        color: #000;
        border: 0;
        outline: none;
        position: absolute;
        left: -41px;
        top: 90px;
        z-index: 20;
    }

    .site-header #navbar .close-navbar .ti-close {
        position: relative;
        top: 1px;
    }

    .site-header #navbar > ul > li {
        border-bottom: 1px solid #f2f2f2;
    }

    .site-header #navbar > ul > li > a {
        padding: 10px 15px 10px 35px;
    }

    .site-header .slideInn {
        right: 0 !important;
    }
}

@media (max-width: 767px) {
    .site-header .navbar-header .navbar-brand {
        font-size: 24px;
    }

    .site-header #navbar .navbar-nav {
        margin: 0;
    }
}

@media (max-width: 991px) {
    .site-header .navbar-collapse.collapse {
        display: none;
    }

    .site-header .navbar-collapse.collapse.in {
        display: block;
    }

    .site-header .navbar-header .collapse,
    .site-header .navbar-toggle {
        display: block;
    }

    .site-header .navbar-header {
        float: none;
    }

    .site-header .navbar-right {
        float: none;
    }

    .site-header .navbar-nav {
        float: none;
    }

    .site-header .navbar-nav > li {
        float: none;
    }
}

@media (max-width: 991px) {
    .page-wrapper {
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -o-transition: all 0.3s;
        -ms-transition: all 0.3s;
        transition: all 0.3s;
    }
}

/*--------------------------------------------------------------
	#header-style-1
--------------------------------------------------------------*/
.header-style-1 .topbar {
    background-color: #1f1f1f;
    text-align: center;
    padding: 15px;
}

.header-style-1 .topbar p {
    font-size: 14px;
    font-size: 0.9333333333rem;
    color: #fff;
    margin: 0;
}

@media screen and (min-width: 992px) {
    .header-style-1 {
        background-color: #fff;
        width: 100%;
        border-bottom: 0;
        z-index: 10;
    }

    .header-style-1 .navigation {
        background-color: #fff;
    }

    .header-style-1 .navigation > .container-fluid {
        min-height: 100px;
    }

    .header-style-1 #navbar > ul > li > a {
        color: #000;
    }
}

@media (max-width: 1450px) and (min-width: 992px) {
    .header-style-1 #navbar > ul > li > a {
        padding: 40px 10px;
    }
}

@media (max-width: 1450px) {
    .header-style-1 #navbar > ul > li.menu-item-has-children > a:before {
        display: none;
    }
}

.header-style-1 .container-fluid,
.header-style-1 ul.nav.navbar-nav {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 600px) {
    .header-style-1 .container-fluid,
    .header-style-1 ul.nav.navbar-nav {
        justify-content: normal;
    }
}

@media (max-width: 991px) {
    .header-style-1 ul.nav.navbar-nav {
        display: block;
    }
}

@media screen and (min-width: 992px) {
    .header-style-1 .navbar-header {
        float: none;
        position: absolute;
        left: calc(50% - 25px);
        top: 25px;
    }

    .header-style-1 .navbar-header img {
        max-width: 100px;
    }

    .header-style-1 .navbar-header .navbar-brand {
        margin: 0;
        padding: 0;
        float: none;
        height: auto;
        line-height: normal;
    }
}

.header-style-1 .navbar-header img {
    max-width: 100px;
}

.header-style-1 .navbar-header .navbar-brand {
    margin: 0;
    padding: 0;
    float: none;
    height: auto;
    line-height: normal;
}

.header-style-1 #navbar .logo-middle {
    position: relative;
}

.header-style-1 #navbar .logo-middle img {
    max-width: 100px;
}

.header-style-1 #navbar .logo-middle .navbar-brand {
    margin: 0;
    padding: 0 30px;
    float: none;
    height: auto;
    line-height: normal;
}

.header-style-1 .header-left {
    position: absolute;
    left: 70px;
    display: flex;
    align-items: center;
}

@media (max-width: 991px) {
    .header-style-1 .header-left {
        left: 30px;
    }
}

@media (max-width: 600px) {
    .header-style-1 .header-left {
        display: none;
    }
}

.header-style-1 .header-left .side-info-bars > span {
    background: #000;
}

.header-style-1 .header-left > div {
    float: left;
    margin-right: 10px;
}

.header-style-1 .header-left form {
    position: relative;
}

.header-style-1 .header-left input {
    background: #f1f1f1;
    width: 230px;
    height: 45px;
    font-size: 14px;
    font-size: 0.9333333333rem;
    
    padding: 15px 15px 15px 45px;
    border: 0;
    border-radius: 45px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    opacity: 1;
}

.header-style-1 .header-left .width-search-form {
    background: rgba(255, 255, 255, 0.1);
    width: 230px;
}

.header-style-1 .header-left input:focus {
    outline: 0;
    box-shadow: none;
}

.header-style-1 .header-left ::-webkit-input-placeholder {
    
    opacity: 1;
}

.header-style-1 .header-left :-ms-input-placeholder {
    
    opacity: 1;
}

.header-style-1 .header-left ::placeholder {
    
    opacity: 1;
}

.header-style-1 .header-left button {
    background: transparent;
    padding: 0;
    border: 0;
    outline: 0;
    position: absolute;
    left: 20px;
    top: 11px;
}

.header-style-1 .header-left button i:before {
    font-size: 16px;
    font-size: 1.0666666667rem;
    color: #000;
}

@media (max-width: 1350px) {
    .header-style-1 .header-left .search-area {
        display: none;
    }
}

.header-style-1 .header-right {
    position: absolute;
    /* right: 70px; */
    right: 50px;
}

@media (max-width: 991px) {
    .header-style-1 .header-right {
        /* right: 80px; */
        right: 30px;
    }
}

.header-style-1 .header-right button {
    background: transparent;
    padding: 0;
    border: 0;
    outline: 0;
}

.header-style-1 .header-right > div {
    float: left;
    position: relative;
}

.header-style-1 .header-right > div + div {
    margin-left: 18px;
}

@media (max-width: 767px) {
    .header-style-1 .header-right > div + div {
        margin-left: 13px;
    }
}

.header-style-1 .header-right > div:last-child {
    position: relative;
}

.header-style-1 .header-right i {
    font-size: 20px;
    color: #000;
}

.header-style-1 .header-right .mini-cart .cart-toggle-btn {
    /* font-family: "Gothic A1", sans-serif; */
}

.header-style-1 .header-right .mini-cart .fi:before {
    font-size: 25px;
    font-size: 1.6666666667rem;
}

.header-style-1 .header-right .mini-cart .cart-count {
    background: #000;
    width: 18px;
    height: 18px;
    font-size: 10px;
    font-size: 0.6666666667rem;
    line-height: 18px;
    color: white;
    position: absolute;
    top: -12px;
    right: -10px;
    border-radius: 50%;
}

.header-style-1 .header-right .mini-cart-content {
    background: #fff;
    /* width: 300px; */
    width: 500px;
    z-index: 10;
    position: absolute;
    right: 0;
    top: 62px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    box-shadow: -2px 18px 40px -9px #999999;
}

@media (max-width: 991px) {
    .header-style-1 .header-right .mini-cart-content {
        top: 68px;

        width: 300px; /*New*/
    }
}

@media (max-width: 450px) {
    .header-style-1 .header-right .mini-cart-content {
        right: auto;
        left: -198px;
    }
}

.header-style-1 .header-right .mini-cart-content p {
    font-size: 15px;
    font-size: 1rem;
    font-weight: 500;
    color: #000;
    margin: 0;
    text-transform: uppercase;
}

.header-style-1 .header-right .mini-cart-content .mini-cart-items {
    padding: 25px 20px;
}

.header-style-1 .header-right .mini-cart-content .mini-cart-item {
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid #efefef;
}

.header-style-1 .header-right .mini-cart-content .mini-cart-item:first-child {
    padding-top: 0;
    margin-top: 0;
    border-top: 0;
}

.header-style-1 .header-right .mini-cart-content .mini-cart-item-image {
    width: 70px;
    height: 80px;
    border: 1px solid #f3f3f3;
    float: left;
    margin-right: 15px;
}

.header-style-1 .header-right .mini-cart-content .mini-cart-item-image a,
.header-style-1 .header-right .mini-cart-content .mini-cart-item-image img {
    display: block;
}

.header-style-1 .header-right .mini-cart-content .mini-cart-item-des {
    position: relative;
    overflow: hidden;
}

.header-style-1 .header-right .mini-cart-content .mini-cart-item-des a {
    font-size: 14px;
    font-size: 0.9333333333rem;
    font-weight: 500;
    text-align: left;
    color: #000;
    display: block;
    margin-bottom: 0.5em;
}

.header-style-1 .header-right .mini-cart-content .mini-cart-item-des a:hover {
    color: #c2572b;
}

.header-style-1 .header-right .mini-cart-content .mini-cart-item-des .mini-cart-item-price {
    /* font-family: "futura-pt-demi", sans-serif; */
    font-size: 14px;
    font-size: 0.9333333333rem;
    color: #888;
    display: block;
    margin-top: 3px;
}

.header-style-1 .header-right .mini-cart-content .mini-cart-item-des .mini-cart-item-quantity {
    
    
    color: #888;
    display: block;
}

.header-style-1 .header-right .mini-cart-content .mini-cart-action {
    padding: 20px 25px 30px;
    border-top: 1px solid #efefef;
    text-align: center;
}

.header-style-1 .header-right .mini-cart-content .mini-cart-action .theme-btn-s2 {
    float: right;
}

.header-style-1 .header-right .mini-cart-content .mini-cart-action .mini-checkout-price {
    /* font-family: "futura-pt-demi", sans-serif; */
    font-size: 18px;
    font-size: 1.2rem;
    font-weight: 500;
    
    display: block;
    margin-bottom: 20px;
}

.header-style-1 .header-right .mini-cart-content .mini-cart-action .mini-checkout-price + a {
    margin-bottom: 8px;
}

.header-style-1 .header-right .mini-cart-content-toggle,
.header-style-1 .header-right .header-search-content-toggle {
    opacity: 1;
    visibility: visible;
    right: 0;
}

@media (max-width: 991px) {
    .header-style-1 .navigation {
        background: #fff;
        padding: 35px 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }

    .header-style-1 .navbar-header {
        margin-left: 0;
    }

    .header-style-1 .navbar-brand {
        max-width: 100px;
        display: inline-block;
    }
}

/*--------------------------------------------------------------
	#header-default-style
--------------------------------------------------------------*/
.header-style-2 {
    /*** cart-search-contact ***/
}

.header-style-2 .topbar {
    background-color: #1f1f1f;
    text-align: center;
    padding: 15px;
}

.header-style-2 .topbar p {
    font-size: 14px;
    font-size: 0.9333333333rem;
    color: #fff;
    margin: 0;
}

.header-style-2 .container-1410 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

@media (max-width: 991px) {
    .header-style-2 .container-1410 {
        width: 100%;
        align-items: left;
        justify-content: left;
    }
}

@media screen and (min-width: 992px) {
    .header-style-2 .navbar-header {
        position: absolute;
        left: 15px;
    }

    .header-style-2 .navbar > .container-1410 .navbar-brand {
        height: auto;
        padding: 0;
        margin: 0;
    }

    .header-style-2 #navbar > ul > li > a {
        color: #000;
        padding: 40px 17px;
    }
}

@media (max-width: 991px) {
    .header-style-2 .navigation {
        padding: 25px 0;
    }

    .header-style-2 .navbar-header button {
        top: 2px;
    }

    .header-style-2 .navbar-header .navbar-brand {
        padding-left: 0;
    }
}

.header-style-2 .navbar-header img {
    max-width: 100px;
}

@media screen and (min-width: 992px) {
    .header-style-2 .navbar-header {
        margin-right: 65px;
    }
}

.header-style-2 .header-right {
    position: absolute;
    right: 20px;
}

@media (max-width: 991px) {
    .header-style-2 .header-right {
        right: 90px;
    }
}

@media (max-width: 767px) {
    .header-style-2 .header-right {
        right: 70px;
    }
}

.header-style-2 .header-right button {
    background: transparent;
    padding: 0;
    border: 0;
    outline: 0;
}

.header-style-2 .header-right > div {
    float: left;
    position: relative;
    margin-right: 18px;
}

@media (max-width: 767px) {
    .header-style-2 .header-right > div {
        margin-right: 10px;
    }
}

.header-style-2 .header-right > div:last-child {
    margin-right: 0;
}

.header-style-2 .header-right i {
    font-size: 22px;
    color: #000;
}

@media (max-width: 767px) {
    .header-style-2 .header-right i {
        font-size: 18px;
        font-size: 1.2rem;
    }
}

.header-style-2 .header-right .header-search-form-wrapper .fi:before {
    font-size: 22px;
    font-size: 1.4666666667rem;
}

.header-style-2 .header-right .search-toggle-btn {
    color: #000;
    position: relative;
    top: -2px;
}

.header-style-2 .header-right .search-toggle-btn .fi:before {
    font-size: 21px;
    font-size: 1.4rem;
}

@media (max-width: 767px) {
    .header-style-2 .header-right .search-toggle-btn .fi:before {
        font-size: 18px;
        font-size: 1.2rem;
    }
}

.header-style-2 .header-right .mini-cart .fi:before {
    font-size: 25px;
    font-size: 1.6666666667rem;
}

.header-style-2 .header-right .mini-cart .cart-count {
    background: #000;
    width: 17px;
    height: 17px;
    font-size: 8px;
    font-size: 0.5333333333rem;
    line-height: 17px;
    color: white;
    position: absolute;
    top: -2px;
    right: -5px;
    border-radius: 50%;
    font-weight: 600;
}

.header-style-2 .header-right .mini-cart-content {
    background: #fff;
    width: 300px;
    z-index: 10;
    position: absolute;
    right: 0;
    top: 62px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    box-shadow: -2px 18px 40px -9px #999999;
}

@media (max-width: 991px) {
    .header-style-2 .header-right .mini-cart-content {
        top: 63px;
    }
}

@media (max-width: 450px) {
    .header-style-2 .header-right .mini-cart-content {
        right: auto;
        left: -198px;
    }
}

.header-style-2 .header-right .mini-cart-content p {
    font-size: 15px;
    font-size: 1rem;
    font-weight: 500;
    color: #000;
    margin: 0;
    text-transform: uppercase;
}

.header-style-2 .header-right .mini-cart-content .mini-cart-items {
    padding: 25px 20px;
}

.header-style-2 .header-right .mini-cart-content .mini-cart-item {
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid #efefef;
}

.header-style-2 .header-right .mini-cart-content .mini-cart-item:first-child {
    padding-top: 0;
    margin-top: 0;
    border-top: 0;
}

.header-style-2 .header-right .mini-cart-content .mini-cart-item-image {
    width: 70px;
    height: 80px;
    border: 1px solid #f3f3f3;
    float: left;
    margin-right: 15px;
}

.header-style-2 .header-right .mini-cart-content .mini-cart-item-image a,
.header-style-2 .header-right .mini-cart-content .mini-cart-item-image img {
    display: block;
}

.header-style-2 .header-right .mini-cart-content .mini-cart-item-des {
    position: relative;
    overflow: hidden;
}

.header-style-2 .header-right .mini-cart-content .mini-cart-item-des a {
    font-size: 14px;
    font-size: 0.9333333333rem;
    font-weight: 500;
    text-align: left;
    color: #000;
    display: block;
    margin-bottom: 0.5em;
}

.header-style-2 .header-right .mini-cart-content .mini-cart-item-des a:hover {
    color: #c2572b;
}

.header-style-2 .header-right .mini-cart-content .mini-cart-item-des .mini-cart-item-price {
    /* font-family: "futura-pt-demi", sans-serif; */
    font-size: 14px;
    font-size: 0.9333333333rem;
    color: #888;
    display: block;
    margin-top: 3px;
}

.header-style-2 .header-right .mini-cart-content .mini-cart-item-des .mini-cart-item-quantity {
    
    
    color: #888;
    display: block;
}

.header-style-2 .header-right .mini-cart-content .mini-cart-action {
    padding: 20px 25px 30px;
    border-top: 1px solid #efefef;
    text-align: center;
}

.header-style-2 .header-right .mini-cart-content .mini-cart-action .theme-btn-s2 {
    float: right;
}

.header-style-2 .header-right .mini-cart-content .mini-cart-action .mini-checkout-price {
    /* font-family: "futura-pt-demi", sans-serif; */
    font-size: 18px;
    font-size: 1.2rem;
    font-weight: 500;
    
    display: block;
    margin-bottom: 20px;
}

.header-style-2 .header-right .mini-cart-content .mini-cart-action .mini-checkout-price + a {
    margin-bottom: 8px;
}

.header-style-2 .header-right .mini-cart-content-toggle,
.header-style-2 .header-right .header-search-content-toggle {
    opacity: 1;
    visibility: visible;
    right: 0;
}

/*--------------------------------------------------------------
	#header-style-3
--------------------------------------------------------------*/
@media screen and (min-width: 992px) {
    .header-style-3 {
        position: relative;
        background-color: #fff;
    }

    .header-style-3 #navbar > ul > li > a,
    .header-style-3 .header-right i.i7s {
        color: #000;
    }

    .header-style-3 .header-left input {
        background: #f3f3f3;
        border: 1px solid #e6e6e6;
        
    }

    .header-style-3 .header-left input::placeholder {
        
    }

    .header-style-3 .header-left button i:before {
        color: #525252;
    }

    .header-style-3 .side-info-bars > span {
        background: #000;
    }
}

@media (max-width: 991px) {
    .header-style-3 .navigation {
        background-color: #68b0ab;
    }
}

.header-search-area {
    background: #fff;
    width: 100%;
    padding: 100px 0;
    text-align: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 111;
}

.header-search-area h3 {
    font-size: 45px;
    margin: 0 0 1em;
}

.header-search-area form {
    position: relative;
}

.header-search-area input {
    height: 50px;
    font-size: 18px;
    font-size: 1.2rem;
    border-radius: 20;
    border: 0;
    border-bottom: 1px solid #d0d0d0;
    box-shadow: none;
}

.header-search-area input:focus {
    box-shadow: none;
    border-color: #c2572b;
}

.header-search-area form button {
    background: transparent;
    border: 0;
    font-size: 18px;
    font-size: 1.2rem;
    
    position: absolute;
    right: 0;
    padding: 0;
    bottom: 10px;
    right: 15px;
}

.header-search-area form button:focus {
    border: none;
    outline: none;
}

.header-search-area .close-form {
    position: absolute;
    right: 35px;
    top: 35px;
    bottom: auto;
}

.header-search-area .close-form button {
    background: transparent;
    border: 0;
    outline: 0;
    
    
    text-transform: uppercase;
    color: #000;
    padding: 0;
    text-decoration: underline;
    letter-spacing: 4px;
}

.header-search-area .close-form .i7s {
    font-size: 25px;
    font-size: 1.6666666667rem;
    text-decoration: none;
    position: relative;
    top: 6px;
}

.header-search-area .close-form button:focus {
    border: 0;
    outline: 0;
}

.side-info-content {
    background-color: #080808;
    /* background-color: #ffffff; */
    position: fixed;
    width: 400px;
    height: 100%;
    padding: 70px 50px;
    text-align: center;
    overflow: scroll;
    opacity: 0;
    left: -400px;
    top: 0;
    z-index: 100;
    -webkit-transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
    transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}

@media (max-width: 450px) {
    .side-info-content {
        width: 300px;
        padding: 70px 20px;
    }
}

.side-info-content .side-info-close-btn {
    background: transparent;
    border: 0;
    outline: 0;
    color: #c8c8c8;
    font-size: 20px;
    font-size: 1.3333333333rem;
    position: absolute;
    right: 20px;
    top: 20px;
}

@media (max-width: 450px) {
    .side-info-content .side-info-close-btn {
        right: 0;
    }
}

.side-info-content .side-info-close-btn:hover,
.side-info-content .side-info-close-btn:focus {
    outline: none;
    box-shadow: none;
}

.side-info-content .logo {
    text-align: center;
    margin-bottom: 50px;
}

.side-info-content .logo img {
    max-width: 120px;
    display: inline-block;
}

.side-info-content .logo span {
    font-size: 25px;
    font-size: 1.6666666667rem;
    color: #fff;
    font-weight: bold;
    display: block;
    margin: 0.3em 0 1em;
}

.side-info-content .img-holder {
    margin-bottom: 45px;
}

.side-info-content p {
    font-size: 15px;
    font-size: 1rem;
    margin: 0 0 2em;
}

.side-info-content .info li {
    margin: 0 0 8px;
    
    
}

.side-info-content .social-links {
    margin-top: 20px;
    display: inline-block;
}

.side-info-content .social-links a {
    font-size: 15px;
    font-size: 1rem;
    color: #000;
}

.side-info-content .social-links a:hover {
    color: #c2572b;
}

.side-info-content .social-links li {
    float: left;
    margin-right: 20px;
}

.side-info-content .social-links li:last-child {
    margin-right: 0;
}

/*** side info ***/
.side-info-bars {
    cursor: pointer;
}

.side-info-bars > span {
    background: #fff;
    width: 30px;
    height: 1px;
    display: block;
    margin-bottom: 9px;
}

.side-info-bars > span:last-child {
    width: 45px;
    margin-bottom: 0;
    position: relative;
    right: 15px;
}

.toggle-side-info {
    left: 0;
    opacity: 1;
}

.body-overlay {
    z-index: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.6);
    -webkit-transition: opacity .25s ease-in-out, z-index .25s ease-in-out;
    -o-transition: opacity .25s ease-in-out, z-index .25s ease-in-out;
    transition: opacity .25s ease-in-out, z-index .25s ease-in-out;
    visibility: hidden;
}

.active-body-overlay .body-overlay {
    z-index: 99;
    opacity: 1;
    visibility: visible;
}

/*--------------------------------------------------------------
#0.4	hero slider
--------------------------------------------------------------*/
.hero-slider {
    width: 100%;
    height: 800px;
    min-height: 750px;
    display: flex;
    position: relative;
    z-index: 0;
}

@media (max-width: 991px) {
    .hero-slider {
        height: 600px;
        min-height: auto;
    }
}

@media (max-width: 767px) {
    .hero-slider {
        height: 500px;
        min-height: auto;
    }
}

.hero-slider .swiper-slide,
.hero-slider .swiper-slide-s2 {
    overflow: hidden;
    color: #fff;
}

.hero-slider .swiper-container,
.hero-slider .swiper-container-s2 {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.hero-slider .slide-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
}

.hero-slider .swiper-button-prev,
.hero-slider .swiper-button-next {
    background: rgba(255, 255, 255, 0.3);
    width: 45px;
    height: 45px;
    line-height: 45px;
    margin-top: -30px;
    text-align: center;
    border-radius: 45px;
    transition: all 0.3s cubic-bezier(0.7, 0, 0.3, 1);
}

@media (max-width: 991px) {
    .hero-slider .swiper-button-prev,
    .hero-slider .swiper-button-next {
        display: none;
    }
}

.hero-slider .swiper-button-prev {
    left: 35px;
}

.hero-slider .swiper-button-next {
    right: 35px;
}

.hero-slider .swiper-pagination {
    bottom: 40px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

@media screen and (min-width: 992px) {
    .hero-slider .swiper-pagination {
        display: none;
    }
}

.hero-slider .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    text-align: center;
    
    color: #000;
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
    margin: 0 5px 0;
}

.hero-slider .swiper-pagination-bullet-active {
    background: #fff;
}

/*--------------------------------------------------------------
	#hero-style-1
--------------------------------------------------------------*/
.hero-style-1 {
    height: calc(100vh - 152px);
    min-height: 600px;
}

@media (max-width: 991px) {
    .hero-style-1 {
        height: 600px;
        min-height: auto;
    }
}

@media (max-width: 767px) {
    .hero-style-1 {
        height: 400px;
        min-height: auto;
    }
}

.hero-style-1 .container-1410 {
    position: relative;
    padding-left: 80px;
}

@media (max-width: 767px) {
    .hero-style-1 .container-1410 {
        width: 100%;
        word-break: break-word;
        padding-left: 15px;
    }
}

.hero-style-1 .slide-text {
    position: absolute;
    left: 0;
    top: 30px;
    padding-top: 80px;
}

@media (max-width: 991px) {
    .hero-style-1 .slide-text {
        padding-top: 0;
    }
}

@media (max-width: 767px) {
    .hero-style-1 .slide-text {
        position: static;
    }
}

.hero-style-1 .slide-text:before {
    background: #fff;
    content: "";
    width: 1px;
    height: 60px;
    position: absolute;
    left: 50%;
    top: 1px;
}

@media (max-width: 991px) {
    .hero-style-1 .slide-text:before {
        top: -90px;
    }
}

@media (max-width: 767px) {
    .hero-style-1 .slide-text:before {
        display: none;
    }
}

.hero-style-1 p {
    /* font-family: "futura-pt-demi", sans-serif; */
    font-size: 22px;
    font-size: 1.4666666667rem;
    color: #fff;
    margin: 0;
    text-transform: uppercase;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    letter-spacing: 8px;
}

@media (max-width: 991px) {
    .hero-style-1 p {
        font-size: 18px;
        font-size: 1.2rem;
    }
}

@media (max-width: 767px) {
    .hero-style-1 p {
        writing-mode: horizontal-tb;
    }
}

.hero-style-1 h2 {
    font-size: 120px;
    font-size: 8rem;
    color: #fff;
    margin: 0 0 0.57em;
}

@media (max-width: 991px) {
    .hero-style-1 h2 {
        font-size: 75px;
        font-size: 5rem;
    }
}

@media (max-width: 767px) {
    .hero-style-1 h2 {
        font-size: 55px;
        font-size: 3.6666666667rem;
    }
}

@media (max-width: 550px) {
    .hero-style-1 h2 {
        font-size: 50px;
        font-size: 3.3333333333rem;
    }
}

.hero-style-1 h2 span {
    font-weight: 200;
}

.hero-style-1 .slide-title {
    max-width: 540px;
}

@media (max-width: 767px) {
    .hero-style-1 .slide-title {
        max-width: none;
    }
}

.hero-style-1 .control-slider {
    position: relative;
    padding-top: 0;
}

@media screen and (min-width: 992px) {
    .hero-style-1 .control-slider {
        height: 45px;
        position: absolute;
        left: 0;
        top: calc(50% - 22px);
        width: 100%;
    }
}

.hero-style-1 .swiper-button-prev,
.hero-style-1 .swiper-button-next {
    left: 25px;
}

.hero-style-1 .swiper-button-next {
    left: auto;
    right: 25px;
}

.hero-style-1 .swiper-button-prev i,
.hero-style-1 .swiper-button-next i {
    position: absolute;
    left: 35%;
    top: 31%;
}

.hero-style-1 .swiper-button-prev i:before,
.hero-style-1 .swiper-button-next i:before {
    font-size: 10px;
    font-size: 0.6666666667rem;
    color: #000;
}

.hero-style-1 .slider-nav-path {
    stroke-width: 1;
    fill: white;
    stroke: white;
    opacity: .4;
    transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.hero-style-1 .slider-nav:hover .slider-nav-path {
    opacity: 1;
    stroke: #1a1921;
    fill: #1a1921;
}

.hero-style-1 .slider-nav:hover + i:before {
    color: #fff;
}

.hero-style-1 .slider-nav-path-progress-color {
    fill: transparent;
    stroke: #000;
}

.hero-style-1 .slider-nav-transparent {
    position: absolute;
    left: 0;
}

/*--------------------------------------------------------------
	#hero-style-2
--------------------------------------------------------------*/
.hero-style-2 {
    height: calc(100vh - 152px);
    position: relative;
}

@media (max-width: 991px) {
    .hero-style-2 {
        height: 650px;
        min-height: auto;
    }
}

@media (max-width: 767px) {
    .hero-style-2 {
        height: 400px;
        min-height: auto;
    }
}

.hero-style-2 .container-1410 {
    padding-top: 0;
}

.hero-style-2 .inner {
    text-align: center;
}

.hero-style-2 h2,
.hero-style-2 p {
    color: #000;
}

.hero-style-2 .sell-off p {
    /* font-family: "futura-pt-demi", sans-serif; */
    font-size: 24px;
    font-size: 1.6rem;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    display: inline-block;
    margin: 0;
}

@media (max-width: 991px) {
    .hero-style-2 .sell-off p {
        font-size: 20px;
        font-size: 1.3333333333rem;
    }
}

@media (max-width: 767px) {
    .hero-style-2 .sell-off p {
        font-size: 16px;
        font-size: 1.0666666667rem;
    }
}

@media (max-width: 500px) {
    .hero-style-2 .sell-off p {
        font-size: 14px;
        font-size: 0.9333333333rem;
    }
}

.hero-style-2 .slide-title {
    max-width: none;
}

.hero-style-2 h2 {
    font-size: 185px;
    font-size: 12.3333333333rem;
    margin: 0 0 0.1em 0;
    text-transform: uppercase;
    color: #fff;
}

@media (max-width: 991px) {
    .hero-style-2 h2 {
        font-size: 100px;
        font-size: 6.6666666667rem;
    }
}

@media (max-width: 767px) {
    .hero-style-2 h2 {
        font-size: 85px;
        font-size: 5.6666666667rem;
        margin: 0.1em 0 0.3em 0;
    }

    .hero-style-2 h2 span {
        display: block;
    }
}

@media (max-width: 550px) {
    .hero-style-2 h2 {
        font-size: 50px;
        font-size: 3.3333333333rem;
    }
}

.hero-style-2 h2 span {
    font-weight: 200;
}

.hero-style-2 .slide-btns > a:first-child {
    margin-right: 10px;
    margin-bottom: 10px;
}

.hero-style-2 .swiper-control .swiper-button-next,
.hero-style-2 .swiper-control .swiper-button-prev {
    /* font-family: "futura-pt-demi", sans-serif; */
    background: transparent;
    padding: 0;
    text-transform: uppercase;
    color: #fff;
    text-decoration: underline;
    letter-spacing: 5px;
}

/*--------------------------------------------------------------
	#hero-style-3
--------------------------------------------------------------*/
.hero-style-3 {
    background: #78909C;
    height: calc(100vh - 152px);
    text-align: center;
}

@media (max-width: 991px) {
    .hero-style-3 {
        height: 500px;
    }
}

@media (max-width: 767px) {
    .hero-style-3 {
        height: 400px;
    }
}

@media (max-width: 450px) {
    .hero-style-3 {
        height: 350px;
    }
}

.hero-style-3 .container-1410 {
    text-align: center;
    position: relative;
}

.hero-style-3 .swiper-wrapper {
    z-index: 0;
}

.hero-style-3 .slide-img {
    width: 906px;
    height: auto;
    position: absolute;
    left: 50%;
    top: 40%;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 5;
    -webkit-transition: all 1s 0.8s cubic-bezier(0.25, 1.02, 0.62, 0.96);
}

@media (max-width: 1199px) {
    .hero-style-3 .slide-img {
        width: 706px;
    }
}

@media (max-width: 991px) {
    .hero-style-3 .slide-img {
        width: 600px;
    }
}

@media (max-width: 767px) {
    .hero-style-3 .slide-img {
        width: 350px;
    }
}

@media (max-width: 450px) {
    .hero-style-3 .slide-img {
        width: 280px;
    }
}

.hero-style-3 .slide-title h2 {
    font-size: 35.75vmin;
    position: relative;
    top: -600px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.8s cubic-bezier(0.25, 1.02, 0.62, 0.96);
    color: #fff;
    margin: 0;
    text-transform: uppercase;
}

@media (max-width: 1400px) {
    .hero-style-3 .slide-title h2 {
        font-size: 28.75vmin;
    }
}

@media (max-width: 991px) {
    .hero-style-3 .slide-title h2 {
        font-size: 22.75vmin;
    }
}

@media (max-width: 767px) {
    .hero-style-3 .slide-title h2 {
        font-size: 18vmin;
    }
}

.hero-style-3 .swiper-slide-active:before {
    display: none;
}

.hero-style-3 .swiper-slide-active .slide-img {
    top: 50%;
    visibility: visible;
    opacity: 1;
}

.hero-style-3 .swiper-slide-active .slide-title h2 {
    top: 0;
    visibility: visible;
    opacity: 1;
}

.hero-style-3 .swiper-slide:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #68b0ab;
    z-index: 3;
}

.hero-style-3 .control-slider {
    height: 20px;
    z-index: 6;
    position: relative;
    bottom: 100px;
}

@media (max-width: 991px) {
    .hero-style-3 .control-slider {
        bottom: 50px;
    }
}

.hero-style-3 .arrows {
    width: 100%;
    height: 20px;
    position: absolute;
    left: 0;
    bottom: 0;
}

.hero-style-3 .swiper-button-prev,
.hero-style-3 .swiper-button-next {
    background: transparent;
    padding: 0;
    width: 120px;
    height: auto;
    line-height: normal;
    margin: 0;
    text-align: left;
    top: 0;
    bottom: auto;
}

@media (max-width: 991px) {
    .hero-style-3 .swiper-button-prev,
    .hero-style-3 .swiper-button-next {
        display: block;
        width: 100px;
    }
}

.hero-style-3 .swiper-button-prev span,
.hero-style-3 .swiper-button-next span {
    font-size: 14px;
    font-size: 0.9333333333rem;
    color: #fff;
    letter-spacing: 3px;
    display: block;
}

.hero-style-3 .swiper-button-prev i,
.hero-style-3 .swiper-button-next i {
    position: absolute;
    top: 0;
    right: 0;
}

.hero-style-3 .swiper-button-prev i:before,
.hero-style-3 .swiper-button-next i:before {
    font-size: 14px;
    font-size: 0.9333333333rem;
    color: #fff;
}

.hero-style-3 .swiper-button-next {
    text-align: right;
    padding-right: 32px;
    right: calc(50% - 120px);
    left: auto;
}

@media (max-width: 991px) {
    .hero-style-3 .swiper-button-next {
        right: calc(50% - 100px);
    }
}

.hero-style-3 .swiper-button-next:before {
    content: "";
    width: 1px;
    height: 100%;
    background: #fff;
    position: absolute;
    left: -1px;
    top: 0;
}

.hero-style-3 .swiper-button-prev {
    padding-left: 32px;
    left: calc(50% - 120px);
    right: auto;
}

@media (max-width: 991px) {
    .hero-style-3 .swiper-button-prev {
        left: calc(50% - 100px);
    }
}

.hero-style-3 .swiper-button-prev:before {
    content: "";
    width: 1px;
    height: 100%;
    background: #fff;
    position: absolute;
    right: 0;
    top: 0;
}

.hero-style-3 .swiper-button-prev i {
    left: 0;
    right: auto;
}

@media (max-width: 991px) {
    .hero-style-3 .swiper-pagination {
        width: 100%;
        left: 0;
        display: none;
    }
}

/*--------------------------------------------------------------
#0.5	footer
--------------------------------------------------------------*/
.site-footer {
    /* background-color: #f9f9f9; */
    background-color: #080808;
    /* color: #000; */
    color: #fff;
    padding-top: 95px;
    /*** widgets ***/
    /*** newsletter widget ***/
    /*** contact-widget ***/
}

.site-footer p,
.site-footer li,
.site-footer a {
    /* color: #000; */
    color: #fff;
    
    
}

.site-footer a:hover {
    color: #c2572b;
    text-decoration: underline;
}

@media (max-width: 1199px) {
    .site-footer .widget-col {
        margin-bottom: 60px;
    }
}

@media (max-width: 670px) {
    .site-footer .widget-col {
        width: 100%;
        float: none;
        margin-bottom: 50px;
    }
}

@media (max-width: 500px) {
    .site-footer .widget-area > .widget-col:last-child {
        margin-bottom: 0;
    }
}

.site-footer .widget h3 {
    font-size: 15px;
    font-size: 1rem;
    margin: 0 0 2em;
    text-transform: uppercase;
}

.site-footer .widget p,
.site-footer .widget a,
.site-footer .widget li {
    font-weight: 500;
}

.site-footer .widget li {
    line-height: 1.7em;
}

.site-footer .widget ul li {
    margin-bottom: 10px;
}

.site-footer .widget ul > li:last-child {
    margin-bottom: 0;
}

@media screen and (min-width: 1200px) {
    .site-footer .newsletter-widget {
        padding-right: 25px;
    }
}

.site-footer .newsletter-widget form {
    position: relative;
    margin-top: 15px;
}

@media (max-width: 767px) {
    .site-footer .newsletter-widget form {
        width: 100%;
        float: none;
        margin-top: 25px;
    }
}

.site-footer .newsletter-widget form input {
    background-color: transparent;
    height: 50px;
    
    padding: 6px 20px;
    border-radius: 20;
    border: 1px solid #cecece;
    box-shadow: none;
}

.site-footer .newsletter-widget form button {
    background-color: #2f2f2f;
    height: 50px;
    padding: 0 20px;
    
    
    text-transform: uppercase;
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    outline: 0;
    color: #fff;
}

@media screen and (min-width: 1200px) {
    .site-footer .contact-widget {
        /* padding: 0 65px 0 55px; */
    }
}

.site-footer .lower-footer {
    /* font-family: "futura-pt-demi", sans-serif; */
    /* border-top: 1px solid #eaeaea; */
    border-top: 1px solid #282828;
    padding: 25px 0;
    margin-top: 95px;
    position: relative;
}

@media (max-width: 1199px) {
    .site-footer .lower-footer {
        margin-top: 10px;
    }
}

@media (max-width: 500px) {
    .site-footer .lower-footer {
        margin-top: 50px;
    }
}

.site-footer .lower-footer .lower-footer-inner > div {
    width: 33.33%;
    display: inline-block;
    float: left;
}

@media (max-width: 991px) {
    .site-footer .lower-footer .lower-footer-inner > div {
        width: 100%;
        float: none;
        text-align: center;
    }
}

@media (max-width: 991px) {
    .site-footer .lower-footer .lower-footer-inner > div + div {
        margin-top: 15px;
    }
}

.site-footer .lower-footer p {
    
    
    font-weight: 500;
    margin: 0;
    position: relative;
    top: 4px;
}

.site-footer .lower-footer .extra-link {
    text-align: right;
}

.site-footer .lower-footer .extra-link ul {
    display: inline-block;
}

.site-footer .lower-footer .extra-link ul li {
    float: left;
}

.site-footer .lower-footer .extra-link ul > li + li {
    margin-left: 15px;
}

.site-footer .lower-footer .extra-link a {
    color: #fff;
}

@media (max-width: 767px) {
    .site-footer .lower-footer {
        text-align: center;
    }

    .site-footer .lower-footer p,
    .site-footer .lower-footer img {
        float: none;
        display: block;
    }

    .site-footer .lower-footer img {
        display: inline-block;
        margin-top: 15px;
    }
}

.site-footer .lower-footer .social {
    /* font-family: "futura-pt-demi", sans-serif; */
    text-align: center;
}

.site-footer .lower-footer .social ul {
    display: inline-block;
}

.site-footer .lower-footer .social ul li {
    float: left;
}

.site-footer .lower-footer .social ul > li + li {
    margin-left: 22px;
}

.site-footer .lower-footer .social ul a {
    
    
    color: #fff;
    text-transform: uppercase;
}

.sticky-header {
    width: 100%;
    position: fixed;
    left: 0;
    top: -200px;
    z-index: 9999;
    opacity: 0;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    -ms-transition: all 0.7s;
    transition: all 0.7s;
}

.sticky-on {
    opacity: 1;
    top: 0;
}

/*--------------------------------------------------------------
	#home-default
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#1.1	feature-product-section
--------------------------------------------------------------*/
.feature-product-section {
    padding-bottom: 80px;
}

@media (max-width: 991px) {
    .feature-product-section {
        padding-bottom: 70px;
    }
}

@media (max-width: 767px) {
    .feature-product-section {
        padding-bottom: 75px;
    }
}

.feature-product-section .product-grids {
    margin: 0 -15px;
}

@media (max-width: 767px) {
    .feature-product-section .product-grids {
        margin: 0 -7.5px;
    }
}

.feature-product-section .product-grids .grid {
    width: calc(33.33% - 30px);
    float: left;
    margin: 0 15px 30px;
    position: relative;
}

@media (max-width: 991px) {
    .feature-product-section .product-grids .grid {
        width: calc(50% - 30px);
    }
}

@media (max-width: 767px) {
    .feature-product-section .product-grids .grid {
        width: calc(50% - 15px);
        margin: 0 7.5px 15px;
    }
}

@media (max-width: 550px) {
    .feature-product-section .product-grids .grid {
        width: calc(100% - 15px);
        float: none;
    }
}

.feature-product-section .details {
    width: 100%;
    position: absolute;
    left: 40px;
    bottom: 60px;
}

@media (max-width: 1199px) {
    .feature-product-section .details {
        left: 25px;
        bottom: 40px;
    }
}

.feature-product-section h3 {
    font-size: 26px;
    font-size: 1.7333333333rem;
    margin: 0 0 0.3em;
    text-transform: capitalize;
}

@media (max-width: 1199px) {
    .feature-product-section h3 {
        font-size: 20px;
        font-size: 1.3333333333rem;
        margin: 0 0 0.1em;
    }
}

.feature-product-section .shop-btn {
    
    
    color: #000;
    font-weight: 500;
    text-decoration: underline;
    text-transform: uppercase;
    letter-spacing: 3px;
}

@media (max-width: 1199px) {
    .feature-product-section .shop-btn {
        font-size: 11px;
        font-size: 0.7333333333rem;
    }
}

.feature-product-section .shop-btn:hover {
    color: #c2572b;
}

/*--------------------------------------------------------------
#1.2	trendy-product-section
--------------------------------------------------------------*/
.trendy-product-section, .best-seller-section {
    padding-top: 0;
    padding-bottom: 85px;
}

@media (max-width: 991px) {
    .trendy-product-section, .best-seller-section {
        padding-bottom: 95px;
    }
}

@media (max-width: 767px) {
    .trendy-product-section, .best-seller-section {
        padding-bottom: 85px;
    }
}

.trendy-product-section .products-wrapper, .best-seller-section .products-wrapper,
.trendy-product-section .container-1410,
.best-seller-section .container-1410 {
    position: relative;
}

.trendy-product-section .product, .best-seller-section .product {
    -webkit-transition: all 250ms ease-in-out 0s;
    -moz-transition: all 250ms ease-in-out 0s;
    -o-transition: all 250ms ease-in-out 0s;
    transition: all 250ms ease-in-out 0s;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 20px 0;
}

.trendy-product-section .product-holder, .best-seller-section .product-holder {
    position: relative;
}

.trendy-product-section .product-holder .product-badge, .best-seller-section .product-holder .product-badge {
    background-color: #494949;
    
    
    color: #fff;
    padding: 5px 15px;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 25px;
    z-index: 2;
}

.trendy-product-section .product:hover .shop-action-wrap, .best-seller-section .product:hover .shop-action-wrap {
    opacity: 1;
    visibility: visible;
    bottom: 35px;
}

.trendy-product-section .shop-action-wrap, .best-seller-section .shop-action-wrap {
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 15px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
}

.trendy-product-section .shop-action, .best-seller-section .shop-action {
    display: inline-block;
}

.trendy-product-section .shop-action > li, .best-seller-section .shop-action > li {
    float: left;
    text-align: center;
}

.trendy-product-section .shop-action > li + li, .best-seller-section .shop-action > li + li {
    margin-left: 5px;
}

.trendy-product-section .shop-action li a, .best-seller-section .shop-action li a {
    background-color: #fff;
    width: 45px;
    height: 45px;
    line-height: 45px;
    color: #000;
    display: inline-block;
    border-radius: 45px;
    box-shadow: 0px 3px 6.58px 0.42px rgba(0, 0, 0, 0.08);
}

.trendy-product-section .shop-action li a:hover, .best-seller-section .shop-action li a:hover {
    background-color: #000;
    color: #fff;
}

.trendy-product-section .shop-action a i:before, .best-seller-section .shop-action a i:before {
    font-size: 15px;
    font-size: 1rem;
}

.trendy-product-section .shop-action > li:first-child i:before, .best-seller-section .shop-action > li:first-child i:before {
    font-size: 16px;
    font-size: 1.0666666667rem;
}

.trendy-product-section .shop-action > li:nth-child(2) a, .best-seller-section .shop-action > li:nth-child(2) a {
    line-height: 50px;
}

.trendy-product-section .product-info, .best-seller-section .product-info {
    text-align: center;
    padding-top: 25px;
}

.trendy-product-section .product-info h4, .best-seller-section .product-info h4 {
    /* font-family: "Gothic A1", sans-serif; */
    font-size: 15px;
    font-size: 1rem;
    font-weight: 500;
    margin: 0 0 0.5em;
}

.trendy-product-section .product-info h4 a, .best-seller-section .product-info h4 a {
    color: #000;
}

.trendy-product-section .product-info h4 a:hover, .best-seller-section .product-info h4 a:hover {
    text-decoration: underline;
}

.trendy-product-section .product-info .woocommerce-Price-amount, .best-seller-section .product-info .woocommerce-Price-amount {
    /* font-family: "futura-pt-demi", sans-serif; */
    font-size: 16px;
    font-size: 1.0666666667rem;
}

.trendy-product-section .product-info ins, .best-seller-section .product-info ins {
    font-weight: 500;
    color: #000;
    text-decoration: none;
}

.trendy-product-section .product-info del, .best-seller-section .product-info del {
    display: inline-block;
    padding-left: 8px;
    color: #a9a9a9;
}

.trendy-product-section .more-products, .best-seller-section .more-products {
    /* font-family: "futura-pt-demi", sans-serif; */
    
    
    color: #000;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: inline-block;
    position: absolute;
    top: 25px;
    right: 150px;
}

@media (max-width: 991px) {
    .trendy-product-section .more-products, .best-seller-section .more-products {
        top: 15px;
    }
}

@media (max-width: 767px) {
    .trendy-product-section .more-products, .best-seller-section .more-products {
        right: auto;
        top: 35px;
        left: 15px;
    }
}

.trendy-product-section .more-products:hover, .best-seller-section .more-products:hover, .trendy-product-section .more-products:focus, .best-seller-section .more-products:focus, .trendy-product-section .more-products:active, .best-seller-section .more-products:active {
    text-decoration: none;
}

.trendy-product-section .owl-theme .owl-controls, .best-seller-section .owl-theme .owl-controls {
    margin-top: 45px;
    position: absolute;
    right: 0;
    top: -123px;
}

@media (max-width: 767px) {
    .trendy-product-section .owl-theme .owl-controls, .best-seller-section .owl-theme .owl-controls {
        position: static;
    }
}

.trendy-product-section .owl-theme .owl-controls .owl-nav [class*=owl-], .best-seller-section .owl-theme .owl-controls .owl-nav [class*=owl-] {
    width: 50px;
    height: 55px;
    line-height: 55px;
    background: #fff;
    padding: 0;
    color: #000;
    border-radius: 20;
    border: 1px solid #d6d6d6;
    margin: 0;
}

@media (max-width: 767px) {
    .trendy-product-section .owl-theme .owl-controls .owl-nav [class*=owl-], .best-seller-section .owl-theme .owl-controls .owl-nav [class*=owl-] {
        width: 45px;
        height: 50px;
        line-height: 50px;
    }
}

.trendy-product-section .owl-theme .owl-controls .owl-nav [class*=owl-]:hover, .best-seller-section .owl-theme .owl-controls .owl-nav [class*=owl-]:hover {
    background: #000;
}

.trendy-product-section .owl-theme .owl-controls .owl-nav [class*=owl-]:hover i:before, .best-seller-section .owl-theme .owl-controls .owl-nav [class*=owl-]:hover i:before {
    color: #fff;
}

.trendy-product-section .owl-theme .owl-controls .owl-nav [class*=owl-] i:before, .best-seller-section .owl-theme .owl-controls .owl-nav [class*=owl-] i:before {
    font-size: 14px;
    font-size: 0.9333333333rem;
    color: #000;
}

.trendy-product-section .owl-theme .owl-controls .owl-nav .owl-prev, .best-seller-section .owl-theme .owl-controls .owl-nav .owl-prev {
    position: relative;
    left: 1px;
}

/*--------------------------------------------------------------
#1.3	deal-section
--------------------------------------------------------------*/
.deal-section {
    /* background: url("../images/deal.jpg") center top/cover no-repeat local; */
    overflow: hidden;
    position: relative;
    margin: 0 0 110px;
}

@media (max-width: 991px) {
    .deal-section {
        margin: 0 0 100px;
    }
}

@media (max-width: 767px) {
    .deal-section {
        margin: 0 0 90px;
    }
}

@media screen and (min-width: 1200px) {
    .deal-section {
        width: calc(100% - 200px);
        margin: 0 0 110px 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.deal-section .container-1410 {
    position: relative;
}

.deal-section .sec-title {
    margin-bottom: 35px;
}

.deal-section .sec-title h2 {
    font-size: 82px;
    font-size: 5.4666666667rem;
    margin: 0;
    position: relative;
    color: #fff;
}

@media (max-width: 991px) {
    .deal-section .sec-title h2 {
        font-size: 50px;
        font-size: 3.3333333333rem;
    }
}

@media (max-width: 767px) {
    .deal-section .sec-title h2 {
        font-size: 40px;
        font-size: 2.6666666667rem;
    }
}

.deal-section p {
    font-size: 15px;
    font-size: 1rem;
    font-weight: 500;
    margin: 1em 0 0;
    color: #000;
}

.deal-section .woocommerce-Price-amount {
    /* font-family: "futura-pt-demi", sans-serif; */
    font-size: 40px;
    font-size: 2.6666666667rem;
}

@media (max-width: 767px) {
    .deal-section .woocommerce-Price-amount {
        font-size: 24px;
        font-size: 1.6rem;
    }
}

.deal-section ins {
    font-weight: 400;
    color: #fff;
    text-decoration: none;
}

.deal-section del {
    display: inline-block;
    padding-left: 8px;
    color: #a9a9a9;
}

.deal-section #clock {
    /* font-family: "futura-pt-demi", sans-serif; */
    color: #000;
    overflow: hidden;
    max-width: 390px;
    margin: 50px -6.5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 767px) {
    .deal-section #clock {
        max-width: 310px;
        margin: 50px 0;
    }
}

.deal-section #clock > div {
    background-color: #fff;
    width: calc(25% - 13px);
    min-height: 88px;
    float: left;
    padding-top: 23px;
    margin: 0 6.5px;
    text-align: center;
}

@media (max-width: 767px) {
    .deal-section #clock > div {
        background: #fff;
        width: 25%;
        min-height: 85px;
        float: left;
        padding-top: 23px;
        margin: 0 0 0 -1px;
        border: 1px solid #f1f1f1;
    }
}

.deal-section #clock .box > div {
    font-size: 24px;
    font-size: 1.6rem;
    line-height: 0.8em;
}

.deal-section #clock .box span {
    
    
    text-transform: uppercase;
    font-weight: 500;
}

.deal-section .img-holder {
    position: absolute;
    top: 0;
    right: 0;
}

.deal-section .img-holder img {
    position: relative;
    left: 50px;
    z-index: 2;
}

@media (max-width: 991px) {
    .deal-section .img-holder img {
        position: static;
    }
}

/*--------------------------------------------------------------
#1.4	best-seller-section
--------------------------------------------------------------*/
.best-seller-section {
    padding-top: 110px;
}

/*--------------------------------------------------------------
#1.5	partners-section
--------------------------------------------------------------*/
.partners-section {
    padding-top: 0;
}

.partners-section .grid {
    width: 20%;
    float: left;
    border: 1px solid #dedede;
    text-align: center;
    margin-left: -1px;
    margin-top: -1px;
    padding: 60px 35px;
}

@media (max-width: 767px) {
    .partners-section .grid {
        width: 33.33%;
    }
}

@media (max-width: 450px) {
    .partners-section .grid {
        width: 50%;
    }
}

.partners-section .grid img {
    display: inline-block;
}

/*--------------------------------------------------------------
#1.6	category-section-area
--------------------------------------------------------------*/
.category-section-area {
    padding-top: 0;
    padding-bottom: 80px;
}

.category-section-area .all-cat {
    text-align: center;
}

.category-section-area .all-cat ul > li {
    width: 12.5%;
    float: left;
    -webkit-transition: all 250ms ease-in-out 0s;
    -moz-transition: all 250ms ease-in-out 0s;
    -o-transition: all 250ms ease-in-out 0s;
    transition: all 250ms ease-in-out 0s;
}

@media (max-width: 991px) {
    .category-section-area .all-cat ul > li {
        width: 25%;
    }
}

@media (max-width: 550px) {
    .category-section-area .all-cat ul > li {
        width: 33.33%;
    }
}

.category-section-area .all-cat li span {
    display: block;
}

.category-section-area .all-cat a {
    /* font-family: "futura-pt-demi", sans-serif; */
    font-size: 16px;
    font-size: 1.0666666667rem;
    
    display: block;
    padding: 25px 15px;
}

@media (max-width: 767px) {
    .category-section-area .all-cat a {
        font-size: 14px;
        font-size: 0.9333333333rem;
        padding: 15px;
    }
}

.category-section-area .all-cat .fi:before {
    font-size: 50px;
    font-size: 3.3333333333rem;
    color: #000;
}

@media (max-width: 767px) {
    .category-section-area .all-cat .fi:before {
        font-size: 40px;
        font-size: 2.6666666667rem;
    }
}

.category-section-area .all-cat li:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

/*--------------------------------------------------------------
#1.7	cta-section
--------------------------------------------------------------*/
.cta-section {
    /* background: url("../images/cta.jpg") top center/cover no-repeat local; */
    background-color: #000;
    padding: 150px 0;
}

@media screen and (min-width: 1200px) {
    .cta-section {
        height: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
    }
}

@media (max-width: 991px) {
    .cta-section {
        padding: 100px 0;
    }
}

.cta-section .content-area {
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
}

.cta-section .content-area > span {
    
    
    font-weight: 500;
    color: #c2572b;
    text-transform: uppercase;
    letter-spacing: 5px;
}

.cta-section h3 {
    font-size: 42px;
    font-size: 2.8rem;
    margin: 0 0 0.3em;
    color: #fff;
}

@media (max-width: 991px) {
    .cta-section h3 {
        font-size: 35px;
        font-size: 2.3333333333rem;
    }
}

@media (max-width: 767px) {
    .cta-section h3 {
        font-size: 30px;
        font-size: 2rem;
    }
}

.cta-section p {
    margin-bottom: 2em;
    color: #fff;
}

/*--------------------------------------------------------------
#1.8	instagram-section
--------------------------------------------------------------*/
.instagram-section {
    margin-bottom: 160px;
}

@media (max-width: 991px) {
    .instagram-section {
        margin-bottom: 140px;
    }
}

@media (max-width: 767px) {
    .instagram-section {
        margin-bottom: 130px;
    }
}

.instagram-section .instagram-inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.instagram-section .instagram-text {
    background-color: #fff;
    padding: 25px 100px;
    text-align: center;
    position: absolute;
    bottom: -50px;
    box-shadow: 10px -10px 50px 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 991px) {
    .instagram-section .instagram-text {
        padding: 20px 80px;
    }
}

.instagram-section h3 {
    font-size: 22px;
    font-size: 1.4666666667rem;
    margin: 0;
}

@media (max-width: 991px) {
    .instagram-section h3 {
        font-size: 18px;
        font-size: 1.2rem;
    }
}

.instagram-section p {
    margin: 0;
}

.instagram-section .instagram-grids > .grid {
    width: 16.66%;
    float: left;
}

@media (max-width: 767px) {
    .instagram-section .instagram-grids > .grid {
        width: 33.33%;
    }
}

/*--------------------------------------------------------------
	#home-s2
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#2.1	featured-proeducts-section-s2
--------------------------------------------------------------*/
.featured-proeducts-section-s2, .featured-proeducts-section-s3 {
    padding-bottom: 95px;
}

@media (max-width: 991px) {
    .featured-proeducts-section-s2, .featured-proeducts-section-s3 {
        padding-bottom: 85px;
    }
}

@media (max-width: 767px) {
    .featured-proeducts-section-s2, .featured-proeducts-section-s3 {
        padding-bottom: 75px;
    }
}

.featured-proeducts-section-s2 .product-grids, .featured-proeducts-section-s3 .product-grids {
    margin: 0 -7.5px;
}

.featured-proeducts-section-s2 .product-grids .grid, .featured-proeducts-section-s3 .product-grids .grid {
    width: calc(33.33% - 15px);
    float: left;
    margin: 0 7.5px 15px;
    position: relative;
}

@media (max-width: 991px) {
    .featured-proeducts-section-s2 .product-grids .grid, .featured-proeducts-section-s3 .product-grids .grid {
        width: calc(50% - 15px);
    }
}

@media (max-width: 767px) {
    .featured-proeducts-section-s2 .product-grids .grid, .featured-proeducts-section-s3 .product-grids .grid {
        width: calc(50% - 15px);
    }
}

@media (max-width: 600px) {
    .featured-proeducts-section-s2 .product-grids .grid, .featured-proeducts-section-s3 .product-grids .grid {
        width: calc(100% - 15px);
        float: none;
    }
}

.featured-proeducts-section-s2 .product-grids .inner-grid, .featured-proeducts-section-s3 .product-grids .inner-grid {
    position: relative;
}

.featured-proeducts-section-s2 .product-grids .grid > .inner-grid + .inner-grid, .featured-proeducts-section-s3 .product-grids .grid > .inner-grid + .inner-grid {
    margin-top: 15px;
}

@media screen and (min-width: 1200px) {
    .featured-proeducts-section-s2 .product-grids > .grid:first-child, .featured-proeducts-section-s3 .product-grids > .grid:first-child,
    .featured-proeducts-section-s2 .product-grids > .grid:last-child,
    .featured-proeducts-section-s3 .product-grids > .grid:last-child {
        margin-top: 35px;
    }
}

.featured-proeducts-section-s2 .details, .featured-proeducts-section-s3 .details {
    background-color: rgba(255, 255, 255, 0.95);
    width: 55%;
    padding: 25px;
    position: absolute;
    left: 40px;
    bottom: 60px;
}

@media (max-width: 1199px) {
    .featured-proeducts-section-s2 .details, .featured-proeducts-section-s3 .details {
        left: 25px;
        bottom: 40px;
    }
}

.featured-proeducts-section-s2 h3, .featured-proeducts-section-s3 h3 {
    font-size: 18px;
    font-size: 1.2rem;
    margin: 0 0 0.3em;
    text-transform: capitalize;
}

.featured-proeducts-section-s2 .shop-btn, .featured-proeducts-section-s3 .shop-btn {
    /* font-family: "futura-pt-demi", sans-serif; */
    font-size: 10px;
    font-size: 0.6666666667rem;
    
    text-transform: uppercase;
    letter-spacing: 2px;
    text-decoration: underline;
}

@media (max-width: 1199px) {
    .featured-proeducts-section-s2 .shop-btn, .featured-proeducts-section-s3 .shop-btn {
        font-size: 11px;
        font-size: 0.7333333333rem;
    }
}

.featured-proeducts-section-s2 .shop-btn:hover, .featured-proeducts-section-s3 .shop-btn:hover {
    color: #c2572b;
}

.featured-proeducts-section-s2 .grid-white-text h3, .featured-proeducts-section-s3 .grid-white-text h3,
.featured-proeducts-section-s2 .grid-white-text a,
.featured-proeducts-section-s3 .grid-white-text a {
    color: #fff;
}

/*--------------------------------------------------------------
#2.2	home-pg-prodcut-section
--------------------------------------------------------------*/
.home-pg-prodcut-section .tablinks {
    text-align: center;
    margin-bottom: 20px;
    margin-top: -10px;
}

.home-pg-prodcut-section .tablinks ul {
    /* font-family: "futura-pt-demi", sans-serif; */
    display: inline-block;
}

.home-pg-prodcut-section .tablinks ul > li {
    float: left;
}

@media (max-width: 500px) {
    .home-pg-prodcut-section .tablinks ul > li {
        float: none;
    }
}

.home-pg-prodcut-section .tablinks ul > li + li {
    margin-left: 45px;
}

@media (max-width: 500px) {
    .home-pg-prodcut-section .tablinks ul > li + li {
        margin-left: 0;
        margin-top: 7px;
    }
}

.home-pg-prodcut-section .tablinks ul a {
    
    
    color: #000;
    text-transform: uppercase;
    letter-spacing: 2px;
}

@media (max-width: 767px) {
    .home-pg-prodcut-section .tablinks ul a {
        font-size: 14px;
        font-size: 0.9333333333rem;
        letter-spacing: 0;
    }
}

.home-pg-prodcut-section .tablinks li.active a {
    text-decoration: underline;
}

.home-pg-prodcut-section .products {
    overflow: hidden;
    margin: 0 -15px;
}

.home-pg-prodcut-section .products > li {
    width: calc(25% - 30px);
    float: left;
    margin: 0 15px 50px;
}

@media (max-width: 1500px) {
    .home-pg-prodcut-section .products > li {
        width: calc(33.33% - 30px);
    }
}

@media (max-width: 1300px) {
    .home-pg-prodcut-section .products > li {
        width: calc(50% - 30px);
    }
}

@media (max-width: 500px) {
    .home-pg-prodcut-section .products > li {
        width: calc(100% - 30px);
        float: none;
    }
}

.home-pg-prodcut-section .product-holder {
    position: relative;
}

.home-pg-prodcut-section .product-holder .product-badge {
    background-color: #494949;
    
    
    color: #fff;
    padding: 5px 15px;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 25px;
}

.home-pg-prodcut-section .product:hover .shop-action-wrap {
    opacity: 1;
    visibility: visible;
    bottom: 35px;
}

.home-pg-prodcut-section .shop-action-wrap {
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 15px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
}

.home-pg-prodcut-section .shop-action {
    display: inline-block;
}

.home-pg-prodcut-section .shop-action > li {
    float: left;
    text-align: center;
}

.home-pg-prodcut-section .shop-action > li + li {
    margin-left: 5px;
}

.home-pg-prodcut-section .shop-action li a {
    background-color: #fff;
    width: 45px;
    height: 45px;
    line-height: 45px;
    color: #000;
    display: inline-block;
    border-radius: 45px;
    box-shadow: 0px 3px 6.58px 0.42px rgba(0, 0, 0, 0.08);
}

.home-pg-prodcut-section .shop-action li a:hover {
    background-color: #000;
    color: #fff;
}

.home-pg-prodcut-section .shop-action a i:before {
    font-size: 15px;
    font-size: 1rem;
}

.home-pg-prodcut-section .shop-action > li:first-child i:before {
    font-size: 16px;
    font-size: 1.0666666667rem;
}

.home-pg-prodcut-section .shop-action > li:nth-child(2) a {
    line-height: 50px;
}

.home-pg-prodcut-section .product-info {
    text-align: center;
    padding-top: 25px;
}

.home-pg-prodcut-section .product-info h4 {
    /* font-family: "Gothic A1", sans-serif; */
    font-weight: 500;
    font-size: 15px;
    font-size: 1rem;
    font-weight: 500;
    margin: 0 0 0.5em;
}

.home-pg-prodcut-section .product-info h4 a {
    color: #000;
}

.home-pg-prodcut-section .product-info h4 a:hover {
    text-decoration: underline;
}

.home-pg-prodcut-section .product-info .woocommerce-Price-amount {
    /* font-family: "futura-pt-demi", sans-serif; */
    font-size: 16px;
    font-size: 1.0666666667rem;
}

.home-pg-prodcut-section .product-info ins {
    color: #000;
    text-decoration: none;
}

.home-pg-prodcut-section .product-info del {
    display: inline-block;
    padding-left: 8px;
    color: #a9a9a9;
}

.home-pg-prodcut-section .product-info .product-description {
    display: none;
}

/*--------------------------------------------------------------
#2.3	feautured-product-s3-section
--------------------------------------------------------------*/
.feautured-product-s3-section, .feautured-product-s4-section {
    padding-top: 0;
}

.feautured-product-s3-section .container-fluid, .feautured-product-s4-section .container-fluid {
    padding: 0;
}

.feautured-product-s3-section .product-grids .grid, .feautured-product-s4-section .product-grids .grid {
    width: 50%;
    float: left;
    position: relative;
}

@media (max-width: 767px) {
    .feautured-product-s3-section .product-grids .grid, .feautured-product-s4-section .product-grids .grid {
        width: 100%;
        float: none;
    }
}

.feautured-product-s3-section .details, .feautured-product-s4-section .details {
    width: 100%;
    position: absolute;
    left: 40px;
    bottom: 60px;
}

@media (max-width: 1199px) {
    .feautured-product-s3-section .details, .feautured-product-s4-section .details {
        left: 25px;
        bottom: 40px;
    }
}

.feautured-product-s3-section h3, .feautured-product-s4-section h3 {
    font-size: 36px;
    font-size: 2.4rem;
    color: #fff;
    margin: 0 0 1em;
    text-transform: capitalize;
}

@media (max-width: 1199px) {
    .feautured-product-s3-section h3, .feautured-product-s4-section h3 {
        font-size: 30px;
        font-size: 2rem;
    }
}

@media (max-width: 991px) {
    .feautured-product-s3-section h3, .feautured-product-s4-section h3 {
        font-size: 25px;
        font-size: 1.6666666667rem;
    }
}

.feautured-product-s3-section .shop-btn, .feautured-product-s4-section .shop-btn {
    
    
    color: #000;
    font-weight: 500;
    text-decoration: underline;
    text-transform: uppercase;
    letter-spacing: 3px;
}

@media (max-width: 1199px) {
    .feautured-product-s3-section .shop-btn, .feautured-product-s4-section .shop-btn {
        font-size: 11px;
        font-size: 0.7333333333rem;
    }
}

.feautured-product-s3-section .shop-btn:hover, .feautured-product-s4-section .shop-btn:hover {
    color: #c2572b;
}

.home-feature-section.home-feature-section-s2 {
    background-color: transparent;
    border-top: 1px solid #ececec;
}

.hm-pg-blog-section {
    padding-top: 0;
}

/*--------------------------------------------------------------
	#home-s3
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#3.1	top-products-section
--------------------------------------------------------------*/
.top-products-section {
    padding-top: 0;
    /*** owl controls ***/
}

.top-products-section .grid .img-holder {
    width: 690px;
    float: left;
}

@media (max-width: 1199px) {
    .top-products-section .grid .img-holder {
        width: 50%;
    }
}

@media (max-width: 991px) {
    .top-products-section .grid .img-holder {
        width: 100%;
        float: none;
    }
}

.top-products-section .grid .details {
    width: calc(100% - 690px);
    float: left;
    padding-left: 85px;
    padding-top: 90px;
}

@media (max-width: 1450px) {
    .top-products-section .grid .details {
        padding-top: 45px;
        padding-left: 45px;
    }
}

@media (max-width: 1199px) {
    .top-products-section .grid .details {
        width: 50%;
        padding: 0 0 0 35px;
    }
}

@media (max-width: 991px) {
    .top-products-section .grid .details {
        width: 100%;
        float: none;
        padding: 45px 0 0;
    }
}

.top-products-section .grid .details h2 {
    font-size: 40px;
    font-size: 2.6666666667rem;
    margin: 0 0 0.4em;
}

@media (max-width: 1199px) {
    .top-products-section .grid .details h2 {
        font-size: 35px;
        font-size: 2.3333333333rem;
    }
}

@media (max-width: 991px) {
    .top-products-section .grid .details h2 {
        font-size: 30px;
        font-size: 2rem;
    }
}

.top-products-section .grid .details p {
    font-size: 16px;
    font-size: 1.0666666667rem;
    margin: 0;
}

.top-products-section .price {
    font-size: 36px;
    font-size: 2.4rem;
    margin: 0.6em 0 1em;
}

@media (max-width: 991px) {
    .top-products-section .price {
        font-size: 25px;
        font-size: 1.6666666667rem;
    }
}

.top-products-section .price .old {
    font-size: 20px;
    font-size: 1.3333333333rem;
    font-weight: normal;
    color: #ababab;
    text-decoration: line-through;
    display: inline-block;
    margin-left: 5px;
}

@media (max-width: 991px) {
    .top-products-section .price .old {
        font-size: 18px;
        font-size: 1.2rem;
    }
}

.top-products-section .action {
    overflow: hidden;
}

.top-products-section .action > div {
    float: left;
    margin-right: 5px;
}

.top-products-section .action > div a:not(.theme-btn):not(.theme-btn-s2):not(.theme-btn-s3):not(.theme-btn-s4):not(.theme-btn-s6):not(.theme-btn-s7):not(.theme-btn-s5) {
    width: 49px;
    height: 49px;
    line-height: 49px;
    text-align: center;
    font-size: 22px;
    color: #000;
    border: 1px solid #000;
    display: inline-block;
}

.top-products-section .owl-controls {
    width: calc(100% - 690px);
    float: right;
    padding-left: 85px;
    text-align: left;
    position: absolute;
    right: 0;
    bottom: 100px;
}

@media (max-width: 1450px) {
    .top-products-section .owl-controls {
        padding-left: 45px;
        bottom: 60px;
    }
}

@media (max-width: 1199px) {
    .top-products-section .owl-controls {
        padding-left: 35px;
        bottom: 0;
        width: 100%;
        text-align: center;
        position: static;
        margin-top: 45px;
    }
}

@media (max-width: 991px) {
    .top-products-section .owl-controls {
        text-align: left;
        padding-left: 0;
    }
}

.top-products-section .owl-theme .owl-dots span,
.top-products-section .owl-theme .owl-dots .owl-dot.active span {
    background: transparent;
    height: 24px;
    font-size: 16px;
    font-size: 1.0666666667rem;
    font-weight: 500;
    color: #000;
    padding: 0;
    margin: 0 15px 0 0;
    display: inline-block;
}

.top-products-section .owl-theme .owl-dots .owl-dot.active span {
    font-weight: 600;
}

.top-products-section .owl-theme .owl-dots .owl-dot span {
    position: relative;
}

.top-products-section .owl-theme .owl-dots .owl-dot span:before {
    content: "";
    width: 10px;
    height: 1px;
    background: #000;
    position: absolute;
    left: 27px;
    top: 10px;
    opacity: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
}

.top-products-section .owl-theme .owl-dots .owl-dot.active span:before {
    width: 40px;
    opacity: 1;
}

.top-products-section .owl-theme .owl-dots .owl-dot:hover span {
    background: transparent;
}

.top-products-section .owl-theme .owl-dots .owl-dot {
    margin-right: 15px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
}

.top-products-section .owl-theme .owl-dots .owl-dot.active {
    margin-right: 55px;
}

.feautured-product-s4-section {
    padding-bottom: 110px;
}

@media (max-width: 991px) {
    .feautured-product-s4-section {
        padding-bottom: 100px;
    }
}

@media (max-width: 767px) {
    .feautured-product-s4-section {
        padding-bottom: 90px;
    }
}

.home-feature-section-s2 {
    background: transparent;
}

.featured-proeducts-section-s3 {
    padding-bottom: 0;
}

@media screen and (min-width: 1200px) {
    .featured-proeducts-section-s3 .product-grids > .grid:first-child,
    .featured-proeducts-section-s3 .product-grids > .grid:last-child {
        margin-top: 0;
    }
}

.featured-proeducts-section-s3 .product-grids > .grid:nth-child(2) .details {
    bottom: 20px;
    left: 20px;
}

/*--------------------------------------------------------------
	#my account
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#4.1	my-account-section
--------------------------------------------------------------*/
.my-account-section #customer_login {
    overflow: hidden;
    padding: 110px 0;
    margin: 0 -15px;
}

.my-account-section .u-column1,
.my-account-section .u-column2 {
    width: calc(50% - 30px);
    float: left;
    margin: 0 15px;
    position: relative;
    /* box-shadow: 0 0 15px #efefef; */
    padding: 45px;
}

@media (max-width: 991px) {
    .my-account-section .u-column1,
    .my-account-section .u-column2 {
        width: calc(100% - 30px);
        float: none;
    }
}

.my-account-section .u-column1 p,
.my-account-section .u-column2 p {
    font-size: 14px;
    font-size: 0.9333333333rem;
}

@media (max-width: 991px) {
    .my-account-section .u-column2 {
        margin-top: 45px;
    }
}

.my-account-section .u-column1 h2,
.my-account-section .u-column2 h2 {
    font-size: 20px;
    font-size: 1.3333333333rem;
    font-weight: 500;
    margin: 0 0 0.7em;
}

.my-account-section label {
    
    
    display: block;
    font-weight: 500;
}


/**/
.my-account-section .u-column3 {
    width: calc(50% - 30px);
    float: left;
    margin: 0 15px;
    position: relative;
    /* box-shadow: 0 0 15px #efefef; */
    padding: 45px;
}

@media (max-width: 991px) {
    .my-account-section .u-column3 {
        width: calc(100% - 30px);
        float: none;
    }
}

.my-account-section .u-column3 p {
    font-size: 14px;
    font-size: 0.9333333333rem;
}

@media (max-width: 991px) {
    .my-account-section .u-column3 {
        margin-top: 45px;
    }
}

.my-account-section .u-column3 h2 {
    font-size: 20px;
    font-size: 1.3333333333rem;
    font-weight: 500;
    margin: 0 0 0.7em;
}
/**/

.my-account-section input[type="text"],
.my-account-section input[type="password"],
.my-account-section input[type="email"] {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 20;
    padding: 12px 15px;
}

@media (max-width: 767px) {
    .my-account-section input[type="text"],
    .my-account-section input[type="password"],
    .my-account-section input[type="email"] {
        padding: 10px 15px;
    }
}

.my-account-section .woocommerce-form__input-checkbox {
    position: absolute;
    cursor: pointer;
    height: 0;
    width: 0;
    overflow: visible;
}

.my-account-section .woocommerce-form__input-checkbox:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    border: 1px solid #000;
}

.my-account-section .woocommerce-form__input-checkbox:checked:before {
    background-color: #000;
    border-color: #000;
}

.my-account-section .woocommerce-form__input-checkbox + span {
    padding-left: 25px;
}

@media screen and (min-width: 992px) {
    .my-account-section .woocommerce-LostPassword.lost_password {
        position: absolute;
        right: 50px;
        bottom: 125px;
    }
}

.my-account-section .woocommerce-LostPassword.lost_password a {
    color: #000;
}

.my-account-section .woocommerce-form.woocommerce-form-login,
.my-account-section .woocommerce-form.woocommerce-form-register.register {
    border: 0 !important;
    padding: 0;
}

.woocommerce form .form-row {
    margin-bottom: 15px;
}

/*--------------------------------------------------------------
	#blog-page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#5.1	blog-pg-section
--------------------------------------------------------------*/
.blog-pg-section {
    /*** format-standard ***/
    /*** format-gallery ***/
    /*** format-quote ***/
    /*** format-video ***/
}

@media screen and (min-width: 1200px) {
    .blog-pg-section .blog-content {
        padding-right: 45px;
    }
}

.blog-pg-section .blog-content .post {
    margin-bottom: 100px;
}

@media (max-width: 991px) {
    .blog-pg-section .blog-content .post {
        margin-bottom: 70px;
    }
}

@media (max-width: 767px) {
    .blog-pg-section .blog-content .post {
        margin-bottom: 60px;
    }
}

.blog-pg-section .entry-details {
    padding-top: 40px;
    position: relative;
    z-index: 1;
}

.blog-pg-section .entry-details .entry-meta {
    overflow: hidden;
    margin-bottom: 25px;
}

.blog-pg-section .entry-details .entry-meta > div {
    float: left;
    position: relative;
}

.blog-pg-section .entry-details .entry-meta > div:last-child {
    margin-left: 25px;
}

.blog-pg-section .entry-details .entry-meta > div:last-child:before {
    content: "";
    background-color: #606060;
    width: 9px;
    height: 9px;
    border-radius: 9px;
    position: absolute;
    left: -11px;
    top: 2px;
}

.blog-pg-section .entry-details .entry-meta .date,
.blog-pg-section .entry-details .entry-meta .tags {
    /* font-family: "futura-pt-demi", sans-serif; */
    font-size: 10px;
    font-size: 0.6666666667rem;
    font-weight: 500;
    
    text-transform: uppercase;
    letter-spacing: 2px;
}

.blog-pg-section .entry-details .entry-meta .tags a {
    
    text-decoration: underline;
    position: relative;
    display: inline-block;
    margin-left: 10px;
}

.blog-pg-section .entry-details .entry-meta .tags a:hover {
    color: #000;
}

.blog-pg-section .entry-details .entry-meta .tags > a + a:before {
    content: "'";
    position: absolute;
    left: -9px;
    bottom: -5px;
}

.blog-pg-section .entry-details .read-more {
    /* font-family: "futura-pt-demi", sans-serif; */
    
    
    font-weight: 500;
    color: #000;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.blog-pg-section .entry-details .read-more i {
    position: relative;
    top: 1px;
}

.blog-pg-section .entry-details .read-more i:before {
    font-size: 14px;
}

.blog-pg-section .entry-details .read-more:hover {
    text-decoration: underline;
}

.blog-pg-section .post h3 {
    font-size: 36px;
    font-size: 2.4rem;
    font-weight: normal;
    margin: 0 0 0.5em;
}

@media (max-width: 991px) {
    .blog-pg-section .post h3 {
        font-size: 25px;
        font-size: 1.6666666667rem;
    }
}

@media (max-width: 767px) {
    .blog-pg-section .post h3 {
        font-size: 22px;
        font-size: 1.4666666667rem;
    }
}

.blog-pg-section .post h3 a {
    color: #000;
}

.blog-pg-section .post h3 a:hover {
    text-decoration: underline;
}

.blog-pg-section .post p {
    margin-bottom: 1.8em;
}

@media (max-width: 991px) {
    .blog-pg-section .post p {
        font-size: 16px;
        font-size: 1.0666666667rem;
    }
}

.blog-pg-section .format-standard .entry-details,
.blog-pg-section .format-quote .entry-details {
    border: 1px solid #e9ebef;
    padding: 45px 35px;
}

@media (max-width: 500px) {
    .blog-pg-section .format-standard .entry-details,
    .blog-pg-section .format-quote .entry-details {
        padding: 35px 20px;
    }
}

.blog-pg-section .format-gallery {
    position: relative;
}

.blog-pg-section .format-gallery .owl-controls {
    width: 100%;
    margin: 0;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.blog-pg-section .format-gallery .owl-controls .owl-nav [class*=owl-]:hover {
    background: #c2572b;
}

.blog-pg-section .format-gallery .owl-controls .owl-nav [class*=owl-] {
    background: rgba(133, 133, 133, 0.5);
    width: 50px;
    height: 50px;
    line-height: 50px;
    padding: 0;
    margin: 0;
    border-radius: 50%;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
}

.blog-pg-section .format-gallery .owl-controls .owl-nav .owl-prev,
.blog-pg-section .format-gallery .owl-controls .owl-nav .owl-next {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.blog-pg-section .format-gallery .owl-controls .owl-nav .owl-prev {
    left: 30px;
}

.blog-pg-section .format-gallery .owl-controls .owl-nav .owl-next {
    right: 30px;
}

.blog-pg-section .format-quote {
    position: relative;
}

.blog-pg-section .format-quote:before {
    /* font-family: "futura-pt-demi", sans-serif; */
    content: "Q";
    font-size: 160px;
    font-size: 10.6666666667rem;
    color: rgba(0, 0, 0, 0.1);
    position: absolute;
    right: 162px;
    top: -20px;
}

.blog-pg-section .format-quote h3 {
    font-size: 24px;
    font-size: 1.6rem;
    margin: 0;
}

.blog-pg-section .format-quote h3,
.blog-pg-section .format-quote p {
    position: relative;
}

.blog-pg-section .format-video .video-holder {
    position: relative;
    text-align: center;
}

.blog-pg-section .format-video .video-holder:before {
    content: "";
    background-color: #3e3e3e;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
    opacity: 0.5;
}

.blog-pg-section .format-video .video-holder:hover:before {
    opacity: 0.7;
}

.blog-pg-section .format-video .video-holder a {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.blog-pg-section .format-video .video-holder .fi:before {
    font-size: 70px;
    font-size: 4.6666666667rem;
    color: #fff;
}

@media (max-width: 767px) {
    .blog-pg-section .format-video .video-holder .fi:before {
        font-size: 60px;
        font-size: 4rem;
    }
}

@media screen and (min-width: 1200px) {
    .blog-masonry-4-columns .container-fluid {
        padding: 0 80px;
    }
}

.blog-masonry-4-columns .blog-content {
    padding-right: 0;
    margin: 0 -15px;
}

@media (max-width: 1199px) {
    .blog-masonry-4-columns .blog-content {
        margin: 0 -7.5px;
    }
}

@media (max-width: 650px) {
    .blog-masonry-4-columns .blog-content {
        margin: 0;
    }
}

.blog-masonry-4-columns .post {
    width: 25%;
    float: left;
    clear: none;
    padding: 0 15px;
    overflow: hidden;
}

@media (max-width: 1199px) {
    .blog-masonry-4-columns .post {
        width: 33.33%;
        padding: 0 7.5px;
    }
}

@media (max-width: 991px) {
    .blog-masonry-4-columns .post {
        width: 50%;
    }
}

@media (max-width: 650px) {
    .blog-masonry-4-columns .post {
        width: 100%;
        float: none;
        padding: 0;
    }
}

.blog-masonry-4-columns .post h3 {
    font-size: 24px;
    font-size: 1.6rem;
    font-weight: normal;
    margin: 0 0 0.5em;
}

@media (max-width: 991px) {
    .blog-masonry-4-columns .post h3 {
        font-size: 22px;
        font-size: 1.4666666667rem;
    }
}

@media (max-width: 767px) {
    .blog-masonry-4-columns .post h3 {
        font-size: 20px;
        font-size: 1.3333333333rem;
    }
}

@media screen and (min-width: 992px) {
    .blog-pg-left-sidebar .blog-content {
        padding: 0 0 0 45px;
    }
}

/*--------------------------------------------------------------
	#blog-single-page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#6.1	blog-single-section
--------------------------------------------------------------*/
.blog-single-section {
    /*** tag-share ***/
    /*** author-box ***/
    /*** more-posts ***/
    /*** comments area ***/
    /*** comment-respond ***/
}

@media screen and (min-width: 1200px) {
    .blog-single-section .blog-content {
        padding-right: 45px;
    }
}

.blog-single-section .entry-meta {
    overflow: hidden;
    margin: 45px 0 25px;
}

.blog-single-section .entry-meta > div {
    float: left;
    position: relative;
}

.blog-single-section .entry-meta > div:last-child {
    margin-left: 25px;
}

.blog-single-section .entry-meta > div:last-child:before {
    content: "";
    background-color: #606060;
    width: 9px;
    height: 9px;
    border-radius: 9px;
    position: absolute;
    left: -11px;
    top: 2px;
}

.blog-single-section .entry-meta .date,
.blog-single-section .entry-meta .tags {
    /* font-family: "futura-pt-demi", sans-serif; */
    font-size: 10px;
    font-size: 0.6666666667rem;
    font-weight: 500;
    
    text-transform: uppercase;
    letter-spacing: 2px;
}

.blog-single-section .entry-meta .tags a {
    
    text-decoration: underline;
    position: relative;
    display: inline-block;
    margin-left: 10px;
}

.blog-single-section .entry-meta .tags a:hover {
    color: #000;
}

.blog-single-section .entry-meta .tags > a + a:before {
    content: "'";
    position: absolute;
    left: -9px;
    bottom: -5px;
}

.blog-single-section .post h2 {
    font-size: 36px;
    font-size: 2.4rem;
    font-weight: normal;
    margin: 0 0 0.7em;
}

@media (max-width: 991px) {
    .blog-single-section .post h2 {
        font-size: 25px;
        font-size: 1.6666666667rem;
    }
}

@media (max-width: 767px) {
    .blog-single-section .post h2 {
        font-size: 22px;
        font-size: 1.4666666667rem;
    }
}

.blog-single-section .post p {
    line-height: 1.7em;
    margin-bottom: 1.5em;
}

.blog-single-section .post h3 {
    font-size: 24px;
    font-size: 1.6rem;
    line-height: 1.3em;
    margin: 1.8em 0 1em;
}

@media (max-width: 991px) {
    .blog-single-section .post h3 {
        font-size: 22px;
        font-size: 1.4666666667rem;
    }
}

@media (max-width: 767px) {
    .blog-single-section .post h3 {
        font-size: 20px;
        font-size: 1.3333333333rem;
    }
}

.blog-single-section .post blockquote {
    /* font-family: "futura-pt-demi", sans-serif; */
    font-size: 20px;
    font-size: 1.3333333333rem;
    font-weight: 400;
    color: #000;
    line-height: 1.6em;
    padding: 40px 50px 40px 100px;
    margin: 50px 0 50px;
    border: 1px solid #e2e2e2;
    position: relative;
}

@media (max-width: 767px) {
    .blog-single-section .post blockquote {
        font-size: 17px;
        font-size: 1.1333333333rem;
        padding: 40px 25px 40px 80px;
    }
}

.blog-single-section .post blockquote:before {
    /* font-family: "futura-pt-demi", sans-serif; */
    content: "Q";
    font-size: 60px;
    font-size: 4rem;
    color: #dfdfdf;
    position: absolute;
    left: 30px;
    top: 40%;
}

@media (max-width: 767px) {
    .blog-single-section .post blockquote:before {
        font-size: 45px;
        font-size: 3rem;
        left: 25px;
    }
}

.blog-single-section .share {
    border-top: 1px solid #e2e2e2;
    padding-top: 25px;
    margin: 75px 0 0;
    /* font-family: "futura-pt-demi", sans-serif; */
}

.blog-single-section .share i {
    font-size: 25px;
    font-size: 1.6666666667rem;
}

.blog-single-section .share ul {
    display: inline-block;
    padding-left: 25px;
}

.blog-single-section .share ul li {
    float: left;
}

.blog-single-section .share ul > li + li {
    margin-left: 20px;
}

.blog-single-section .share ul a {
    
    
    font-weight: 500;
    color: #000;
    text-transform: uppercase;
}

.blog-single-section .author-box {
    margin: 70px 0;
}

.blog-single-section .author-box .author-avatar {
    float: left;
}

@media (max-width: 767px) {
    .blog-single-section .author-box .author-avatar {
        float: none;
    }
}

.blog-single-section .author-box .author-content {
    display: block;
    overflow: hidden;
    padding-left: 25px;
}

@media (max-width: 767px) {
    .blog-single-section .author-box .author-content {
        padding: 0;
        margin: 15px 0 0 0;
    }
}

.blog-single-section .author-box .author-content p {
    margin-bottom: 20px;
}

.blog-single-section .author-box .author-name {
    /* font-family: "futura-pt-demi", sans-serif; */
    font-size: 18px;
    font-size: 1.2rem;
    font-weight: 400;
    display: inline-block;
    margin-bottom: 10px;
    color: #000;
}

.blog-single-section .author-box .social-link {
    display: inline-block;
    list-style: none;
}

.blog-single-section .author-box .social-link li {
    float: left;
    margin-right: 12px;
}

.blog-single-section .author-box .social-link a {
    display: block;
    
    
    color: #000;
}

.blog-single-section .author-box .social-link a:hover {
    color: #c2572b;
}

.blog-single-section .more-posts {
    overflow: hidden;
    border: 1px solid #dddddd;
    padding: 0 25px;
}

.blog-single-section .more-posts > div {
    width: 50%;
    float: left;
}

@media (max-width: 767px) {
    .blog-single-section .more-posts > div {
        width: 100%;
        float: none;
    }
}

.blog-single-section .more-posts > div > a {
    display: inline-block;
}

.blog-single-section .more-posts .previous-post,
.blog-single-section .more-posts .next-post {
    padding: 40px 0;
}

@media (max-width: 767px) {
    .blog-single-section .more-posts .previous-post,
    .blog-single-section .more-posts .next-post {
        padding: 25px 15px !important;
    }
}

.blog-single-section .more-posts .previous-post > a,
.blog-single-section .more-posts .next-post > a {
    /* font-family: "futura-pt-demi", sans-serif; */
    
    
    font-weight: 500;
    color: #000;
}

.blog-single-section .more-posts .next-post {
    text-align: right;
    border-left: 1px solid #dddddd;
    padding-left: 15px;
    padding-right: 5px;
}

@media (max-width: 767px) {
    .blog-single-section .more-posts .next-post {
        border-left: 0;
        text-align: left;
        border-top: 1px solid #dddddd;
    }
}

.blog-single-section .more-posts .next-post .post-control-link {
    padding-right: 25px;
    position: relative;
    text-transform: uppercase;
}

@media (max-width: 767px) {
    .blog-single-section .more-posts .next-post .post-control-link {
        padding-right: 0;
    }
}

.blog-single-section .more-posts .next-post .post-control-link:before {
    /* font-family: "Flaticon"; */
    content: "\f101";
    position: absolute;
    right: 0;
    top: -1px;
}

@media (max-width: 767px) {
    .blog-single-section .more-posts .next-post .post-control-link:before {
        display: none;
    }
}

.blog-single-section .more-posts .previous-post {
    padding-right: 15px;
    padding-left: 5px;
}

.blog-single-section .more-posts .previous-post .post-control-link {
    padding-left: 25px;
    position: relative;
    text-transform: uppercase;
}

@media (max-width: 767px) {
    .blog-single-section .more-posts .previous-post .post-control-link {
        padding-left: 0;
    }
}

.blog-single-section .more-posts .previous-post .post-control-link:before {
    /* font-family: "Flaticon"; */
    content: "\f100";
    position: absolute;
    left: 0;
    top: -1px;
}

@media (max-width: 767px) {
    .blog-single-section .more-posts .previous-post .post-control-link:before {
        display: none;
    }
}

.blog-single-section .more-posts .previous-post > a > span,
.blog-single-section .more-posts .next-post > a > span {
    display: block;
}

.blog-single-section .more-posts a:hover .post-control-link {
    color: #c2572b;
}

.blog-single-section .comments-area {
    margin-top: 70px;
}

.blog-single-section .comments-area li > div {
    border-bottom: 1px solid #f4f4f4;
    padding: 0 0 35px;
}

.blog-single-section .comments-area ol {
    list-style-type: none;
    padding-left: 0;
}

.blog-single-section .comments-area ol ul {
    padding-left: 30px;
    padding-bottom: 30px;
    padding-top: 30px;
    list-style-type: none;
}

.blog-single-section .comments-area ol > li:last-child div {
    border-bottom: 0;
}

.blog-single-section .comments-area .comments-title {
    font-size: 18px;
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0 0 1.5em;
    text-transform: uppercase;
}

@media (max-width: 991px) {
    .blog-single-section .comments-area .comments-title {
        font-size: 18px;
        font-size: 1.2rem;
    }
}

.blog-single-section .comments-area li > div {
    position: relative;
}

.blog-single-section .comments-area .comment-theme {
    position: absolute;
    left: 0;
}

@media (max-width: 767px) {
    .blog-single-section .comments-area .comment-theme {
        position: static;
    }
}

.blog-single-section .comments-area .comment-main-area {
    padding-left: 100px;
}

@media (max-width: 767px) {
    .blog-single-section .comments-area .comment-main-area {
        padding-left: 0;
        margin-top: 25px;
    }
}

.blog-single-section .comments-area .comment-main-area p {
    margin-bottom: 20px;
}

.blog-single-section .comments-area .comments-meta h4 {
    font-size: 16px;
    font-size: 1.0666666667rem;
    color: #000;
    font-weight: 500;
    margin: 0 0 1em;
}

.blog-single-section .comments-area .comments-meta h4 span {
    /* font-family: "Gothic A1", sans-serif; */
    font-size: 11px;
    font-size: 0.7333333333rem;
    
    text-transform: uppercase;
    text-decoration: underline;
    display: inline-block;
    padding-left: 5px;
}

@media (max-width: 767px) {
    .blog-single-section .comments-area .comments-meta h4 span {
        padding-left: 0;
    }
}

.blog-single-section .comments-area .comment-reply-link {
    /* font-family: "futura-pt-demi", sans-serif; */
    font-size: 14px;
    font-size: 0.9333333333rem;
    font-weight: 500;
    color: #000;
    text-transform: uppercase;
    position: relative;
    padding-left: 30px;
}

.blog-single-section .comments-area .comment-reply-link:hover {
    color: #c2572b;
}

.blog-single-section .comments-area .comment-reply-link i {
    font-size: 22px;
    font-size: 1.4666666667rem;
    display: inline-block;
    padding-right: 5px;
    position: absolute;
    left: 0;
}

.blog-single-section .comment-respond {
    margin-top: 70px;
}

.blog-single-section .comment-respond .comment-reply-title {
    font-size: 18px;
    font-size: 1.2rem;
    margin: 0 0 1.5em;
    text-transform: uppercase;
}

.blog-single-section .comment-respond form input,
.blog-single-section .comment-respond form textarea {
    background-color: #fff;
    width: 100%;
    height: 50px;
    font-size: 14px;
    font-size: 0.9333333333rem;
    border: 1px solid #c1c1c1;
    padding: 6px 15px;
    margin-bottom: 15px;
    outline: 0;
    border-radius: 20;
    box-shadow: none;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
}

.blog-single-section .comment-respond form input:focus,
.blog-single-section .comment-respond form textarea:focus {
    border-color: #000;
    box-shadow: none;
}

@media (max-width: 991px) {
    .blog-single-section .comment-respond form input,
    .blog-single-section .comment-respond form textarea {
        height: 40px;
    }
}

.blog-single-section .comment-respond form textarea {
    height: 220px;
    padding: 15px;
}

@media (max-width: 991px) {
    .blog-single-section .comment-respond form textarea {
        height: 150px;
    }
}

.blog-single-section .comment-respond .form-inputs {
    overflow: hidden;
}

.blog-single-section .comment-respond .form-inputs > input:nth-child(2) {
    width: 49%;
    float: left;
}

@media (max-width: 767px) {
    .blog-single-section .comment-respond .form-inputs > input:nth-child(2) {
        width: 100%;
        float: none;
    }
}

.blog-single-section .comment-respond .form-inputs > input:nth-child(3) {
    width: 49%;
    float: right;
}

@media (max-width: 767px) {
    .blog-single-section .comment-respond .form-inputs > input:nth-child(3) {
        width: 100%;
        float: none;
    }
}

.blog-single-section .comment-respond .form-submit input {
    /* font-family: "futura-pt-demi", sans-serif; */
    max-width: 180px;
    background-color: rgba(0, 0, 0, 0.8);
    
    
    color: #fff;
    margin-bottom: 0;
    border: 0;
    outline: 0;
    text-transform: capitalize;
    font-weight: 500;
    border-radius: 20;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.blog-single-section .comment-respond .form-submit input:hover {
    background-color: #000;
}

@media screen and (min-width: 1200px) {
    .blog-single-left-sidebar-section .blog-sidebar {
        padding-right: 45px;
        padding-left: 0;
    }
}

/*--------------------------------------------------------------
	#error-404
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#7.1	error-404-section
--------------------------------------------------------------*/
.error-404-section {
    border-top: 1px solid #eaeaea;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
}

@media (max-width: 991px) {
    .error-404-section {
        margin-top: 0;
        height: calc(100vh - 89px);
    }
}

.error-404-section .error-404-area {
    max-width: 580px;
    margin: 0 auto;
}

.error-404-section h2 {
    font-size: 240px;
    font-size: 16rem;
    font-weight: 400;
    line-height: 0.7em;
    margin: 0 0 0.2em;
}

@media (max-width: 991px) {
    .error-404-section h2 {
        font-size: 140px;
        font-size: 9.3333333333rem;
    }
}

.error-404-section h3 {
    font-size: 26px;
    font-size: 1.7333333333rem;
    font-weight: 400;
    margin: 0 0 1em;
}

.error-404-section p {
    font-size: 17px;
    font-size: 1.1333333333rem;
    margin: 0 0 3em;
}

.error-404-pg .header-default-style {
    z-index: 12;
}

.error-404-pg {
    position: relative;
}

.error-404-pg:before {
    content: "";
    width: 481px;
    height: 670px;
    /* background: url("../images/404-light.png") center center/cover no-repeat local; */
    position: absolute;
    right: 25px;
    top: 0;
    z-index: 100;
}

@media (max-width: 1500px) {
    .error-404-pg:before {
        width: 380px;
        height: 529px;
    }
}

@media (max-width: 1300px) {
    .error-404-pg:before {
        display: none;
    }
}

/*--------------------------------------------------------------
	#checkout-page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#8.1	checkout-section
--------------------------------------------------------------*/
.checkout-section .checkout.woocommerce-checkout {
    overflow: hidden;
    clear: both;
}

@media screen and (min-width: 992px) {
    .checkout-section .checkout.woocommerce-checkout .col2-set {
        width: 70%;
        float: left;
        padding-right: 85px;
    }

    .checkout-section .checkout.woocommerce-checkout .woocommerce-checkout-review-order {
        width: 30%;
        float: right;
    }

    .checkout-section .checkout.woocommerce-checkout form .form-row.form-row-first,
    .checkout-section .checkout.woocommerce-checkout form .form-row.form-row-last,
    .checkout-section .checkout.woocommerce-checkout .form-row.form-row-first,
    .checkout-section .checkout.woocommerce-checkout .form-row.form-row-last {
        width: 50%;
        float: left;
    }

    .checkout-section .checkout.woocommerce-checkout form .form-row.form-row-first,
    .checkout-section .checkout.woocommerce-checkout .form-row.form-row-first {
        padding-right: 15px;
    }

    .checkout-section .checkout.woocommerce-checkout form .form-row.form-row-last,
    .checkout-section .checkout.woocommerce-checkout .form-row.form-row-last {
        padding-left: 15px;
    }
}

.checkout-section .checkout.woocommerce-checkout .form-row label.checkbox,
.checkout-section .checkout.woocommerce-checkout .woocommerce-shipping-fields label.checkbox {
    display: inline-block;
}

.checkout-section .checkout.woocommerce-checkout .form-row textarea {
    height: 180px;
}

/*--------------------------------------------------------------
    #cart
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#9.1    cart-section
--------------------------------------------------------------*/
.woocommerce-cart .grop-page-content_warp .woocommerce {
    margin-top: 20px;
    margin-bottom: 41px;
}

.woocommerce .quantity {
    width: 76px;
}

.woocommerce-cart .woocommerce table.shop_table th {
    /* font-family: "futura-pt-demi", sans-serif; */
    padding: 15px 19px 13px;
    text-transform: uppercase;
}

.woocommerce-cart .woocommerce table.shop_table .cart_item td.product-name,
.woocommerce-cart .woocommerce table.shop_table .cart_item td.product-name a {
    color: #555;
}

.woocommerce-cart .woocommerce table.shop_table .cart_item td {
    padding: 21px 19px;
}

.woocommerce-cart .woocommerce table.shop_table .cart_item td,
.woocommerce-cart .woocommerce table.shop_table .cart_item td a {
    color: #232323;
}

.woocommerce-cart .woocommerce table.shop_table .cart_item td.product-thumbnail {
    padding-left: 30px;
}

.woocommerce-cart .woocommerce table.shop_table .cart_item td.product-remove {
    border-right: 1px solid #e5e5e5;
    padding: 0;
    text-align: center;
    width: 70px;
}

.woocommerce-cart .woocommerce table.shop_table td.actions {
    padding: 30px 0;
    background-color: transparent;
    padding-top: 19px;
    padding-bottom: 19px;
    text-align: right;
}

.woocommerce-cart .woocommerce table.shop_table td.actions .coupon label:first-child {
    display: none;
}

.woocommerce-cart .woocommerce table.shop_table td.actions #coupon_code {
    border: 1px solid #e5e5e5;
    font-size: 14px;
    height: 45px;
    margin-right: 15px;
    padding-left: 13px;
    width: 227px;
}

.woocommerce-cart .woocommerce table.shop_table tr.cart-subtotal td[data-title*="Subtotal"],
.woocommerce-cart .woocommerce table.shop_table tr.shipping td[data-title*="Subtotal"],
.woocommerce-cart .woocommerce table.shop_table tr.order-total td[data-title*="Subtotal"] {
    color: #232323;
}

.woocommerce-cart .woocommerce table.shop_table tr.cart-subtotal td[data-title*="Subtotal"]::-webkit-input-placeholder,
.woocommerce-cart .woocommerce table.shop_table tr.shipping td[data-title*="Subtotal"]::-webkit-input-placeholder,
.woocommerce-cart .woocommerce table.shop_table tr.order-total td[data-title*="Subtotal"]::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    opacity: 1;
    filter: alpha(opacity=100);
    -ms-filter: "alpha(opacity=100)";
    color: #888;
}

.woocommerce-cart .woocommerce table.shop_table tr.cart-subtotal td[data-title*="Subtotal"]::-moz-placeholder,
.woocommerce-cart .woocommerce table.shop_table tr.shipping td[data-title*="Subtotal"]::-moz-placeholder,
.woocommerce-cart .woocommerce table.shop_table tr.order-total td[data-title*="Subtotal"]::-moz-placeholder {
    /* Firefox 19+ */
    opacity: 1;
    filter: alpha(opacity=100);
    -ms-filter: "alpha(opacity=100)";
    color: #888;
}

.woocommerce-cart .woocommerce table.shop_table tr.cart-subtotal td[data-title*="Subtotal"]:-ms-input-placeholder,
.woocommerce-cart .woocommerce table.shop_table tr.shipping td[data-title*="Subtotal"]:-ms-input-placeholder,
.woocommerce-cart .woocommerce table.shop_table tr.order-total td[data-title*="Subtotal"]:-ms-input-placeholder {
    /* IE 10+ */
    opacity: 1;
    filter: alpha(opacity=100);
    -ms-filter: "alpha(opacity=100)";
    color: #888;
}

.woocommerce-cart .woocommerce table.shop_table tr.cart-subtotal td[data-title*="Subtotal"]:-moz-placeholder,
.woocommerce-cart .woocommerce table.shop_table tr.shipping td[data-title*="Subtotal"]:-moz-placeholder,
.woocommerce-cart .woocommerce table.shop_table tr.order-total td[data-title*="Subtotal"]:-moz-placeholder {
    /* Firefox 18- */
    opacity: 1;
    filter: alpha(opacity=100);
    -ms-filter: "alpha(opacity=100)";
    color: #888;
}

.woocommerce-cart .woocommerce table.shop_table tr.cart-subtotal th,
.woocommerce-cart .woocommerce table.shop_table tr.cart-subtotal td,
.woocommerce-cart .woocommerce table.shop_table tr.shipping th,
.woocommerce-cart .woocommerce table.shop_table tr.shipping td,
.woocommerce-cart .woocommerce table.shop_table tr.order-total th,
.woocommerce-cart .woocommerce table.shop_table tr.order-total td {
    padding: 16px 15px 14px;
}

.woocommerce-cart .woocommerce-shipping-calculator .shipping-calculator-button {
    color: #151515;
    margin-top: 6px;
    display: block;
    margin-bottom: 12px;
}

.woocommerce-cart .shipping-calculator-form .form-row {
    margin: 0 0 13px;
}

.woocommerce-cart .shipping-calculator-form button.button {
    background-color: #222222 !important;
    color: #ffffff !important;
    height: 45px;
    line-height: 45px;
    padding: 1px 27px 0;
    margin-top: 4px;
}

#add_payment_method table.cart img,
.woocommerce-cart table.cart img,
.woocommerce-checkout table.cart img {
    width: 57px;
}

.woocommerce-cart .woocommerce table.shop_table .cart_item td a.remove {
    width: 18px;
    height: 18px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
    line-height: 20px;
    color: #fff;
    border-radius: 50%;
    background: #dbdbdb;
}

.woocommerce-cart .woocommerce table.shop_table .cart_item td a.remove:hover {
    background-color: #000;
}

.woocommerce #respond input#submit.disabled,
.woocommerce #respond input#submit:disabled,
.woocommerce #respond input#submit[disabled]:disabled,
.woocommerce a.button.disabled,
.woocommerce a.button:disabled,
.woocommerce a.button[disabled]:disabled,
.woocommerce button.button.disabled,
.woocommerce button.button:disabled,
.woocommerce button.button[disabled]:disabled,
.woocommerce input.button.disabled,
.woocommerce input.button:disabled,
.woocommerce input.button[disabled]:disabled {
    padding-top: 0;
    padding-bottom: 0;
}

.woocommerce .cart-collaterals .cart_totals,
.woocommerce-page .cart-collaterals .cart_totals {
    margin-top: 50px;
}

.woocommerce .cart-collaterals .cart_totals > h2,
.woocommerce-page .cart-collaterals .cart_totals > h2 {
    font-size: 24px;
    font-size: 1.6rem;
    margin: 0 0 1.3em;
}

#add_payment_method .cart-collaterals .cart_totals table select,
.woocommerce-cart .cart-collaterals .cart_totals table select,
.woocommerce-checkout .cart-collaterals .cart_totals table select {
    border: 1px solid #e5e5e5;
    color: #888888;
    font-size: 14px;
    height: 50px;
    width: 100%;
}

#add_payment_method .wc-proceed-to-checkout,
.woocommerce-cart .wc-proceed-to-checkout,
.woocommerce-checkout .wc-proceed-to-checkout {
    padding: 0;
    text-align: right;
}

#add_payment_method .wc-proceed-to-checkout,
.woocommerce-cart .wc-proceed-to-checkout,
.woocommerce-checkout .wc-proceed-to-checkout {
    padding: 0;
}

#add_payment_method .wc-proceed-to-checkout a.checkout-button,
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button,
.woocommerce-checkout .wc-proceed-to-checkout a.checkout-button {
    margin-bottom: 0;
    margin-top: 16px;
}

.woocommerce-cart .actions input[name="update_cart"] {
    background-color: #dcdcdc;
    color: #000;
}

.woocommerce-cart .actions input[name="update_cart"]:hover {
    background-color: #000;
    color: #fff;
}

.woocommerce-cart .input-text.qty.text {
    box-shadow: none;
}

.woocommerce-cart input::-webkit-outer-spin-button,
.woocommerce-cart input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@media (max-width: 600px) {
    .woocommerce-cart thead {
        display: none;
    }

    .woocommerce-cart table,
    .woocommerce-cart th,
    .woocommerce-cart td,
    .woocommerce-cart tr,
    .woocommerce-cart tbody {
        width: 100%;
        display: block;
        border: 0;
        text-align: center;
    }

    .woocommerce table.shop_table {
        border: 1px solid #e5e5e5;
    }

    .quantity {
        margin: 0 auto;
    }

    .woocommerce table.shop_table td {
        border: 0;
    }

    .woocommerce-cart tbody > tr {
        position: relative;
    }

    .woocommerce-cart tbody > tr + tr {
        border-top: 1px solid #e5e5e5;
    }

    .woocommerce-cart tbody > tr {
        padding: 30px 20px;
    }

    .woocommerce-cart .woocommerce table.shop_table .cart_item td {
        padding: 8px 10px;
    }

    .woocommerce-cart .woocommerce table.shop_table .cart_item td.product-remove {
        border-right: 0;
        width: auto;
    }

    .woocommerce-cart .woocommerce table.shop_table .cart_item td.product-remove {
        position: absolute;
        right: 15px;
        top: 15px;
    }

    .woocommerce-cart .woocommerce table.shop_table td.actions {
        text-align: center;
    }

    .woocommerce-cart .woocommerce table.shop_table td.actions input {
        display: block;
        float: none;
    }

    .woocommerce-cart .coupon {
        float: none !important;
    }

    .woocommerce-cart .woocommerce table.shop_table td.actions input {
        display: inline-block;
        float: none;
        margin-top: 10px;
    }

    .woocommerce-cart .woocommerce table.shop_table .cart_item td.product-name,
    .woocommerce-cart .woocommerce table.shop_table .cart_item td.product-name a,
    .woocommerce-cart .woocommerce table.shop_table .cart_item td,
    .woocommerce-cart .woocommerce table.shop_table .cart_item td a {
        font-weight: 500;
    }
}

/* Firefox */
.woocommerce-cart input[type=number] {
    -moz-appearance: textfield;
}

.woocommerce strong {
    font-weight: 500;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up,
.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
    border-radius: 20;
}

/*--------------------------------------------------------------
	#shop-page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#10.1	shop-section
--------------------------------------------------------------*/
@media screen and (min-width: 1200px) {
    .shop-section .container-fluid {
        padding: 0 85px;
    }
}

.shop-section .woocommerce-toolbar-top {
    text-align: right;
    margin-bottom: 45px;
    overflow: hidden;
    border-bottom: 1px solid #ddd;
    padding-bottom: 25px;
}

@media (max-width: 550px) {
    .shop-section .woocommerce-toolbar-top {
        text-align: center;
        border-bottom: 0;
    }
}

.shop-section .woocommerce-toolbar-top > p,
.shop-section .woocommerce-toolbar-top > div,
.shop-section .woocommerce-toolbar-top form {
    display: inline-block;
}

@media (max-width: 550px) {
    .shop-section .woocommerce-toolbar-top > p,
    .shop-section .woocommerce-toolbar-top > div,
    .shop-section .woocommerce-toolbar-top form {
        width: 100%;
        display: block;
        float: none;
    }
}

.shop-section .woocommerce-toolbar-top p {
    font-size: 15px;
    font-size: 1rem;
    color: #000;
    float: left;
    margin: 0;
}

.shop-section .woocommerce-toolbar-top form {
    float: right;
    font-size: 15px;
    font-size: 1rem;
}

@media (max-width: 550px) {
    .shop-section .woocommerce-toolbar-top form {
        margin-top: 15px;
    }
}

.shop-section .woocommerce-toolbar-top form select {
    
}

.shop-section .woocommerce-toolbar-top > div {
    margin: 0 auto;
}

.shop-section .woocommerce-toolbar-top > div a {
    width: 23px;
    display: inline-block;
}

.shop-section .woocommerce-toolbar-top > div > a + a {
    margin-left: 7px;
}

.shop-section .woocommerce-toolbar-top .active {
    border-color: #000;
    color: #fff;
}

.shop-section .woocommerce-toolbar-top select {
    background-position: calc(100% - 15px) 15px;
    padding: 6px 15px;
}

@media (max-width: 1500px) {
    .shop-section .woocommerce-toolbar-top .grid-4 {
        display: none;
    }
}

.shop-section .woocommerce-toolbar-top .grid-draw {
    overflow: hidden;
    text-align: center;
}

.shop-section .woocommerce-toolbar-top .grid-draw span {
    width: 5px;
    height: 5px;
    background-color: #c3c3c3;
    float: left;
    margin: 0 0 1px 1px;
}

.shop-section .woocommerce-toolbar-top .products-sizes {
    border: 1px solid #ddd;
    padding: 7px 10px 2px 10px;
    margin-right: 15px;
    overflow: hidden;
}

@media (max-width: 550px) {
    .shop-section .woocommerce-toolbar-top .products-sizes {
        padding: 15px 0;
    }
}

.shop-section .woocommerce-toolbar-top .products-sizes .grid-draw > span:first-child {
    margin-left: 0;
}

.shop-section .woocommerce-toolbar-top .grid-draw-line {
    overflow: hidden;
}

.shop-section .woocommerce-toolbar-top .grid-draw-line span {
    width: 5px;
    height: 5px;
    background-color: #c3c3c3;
    float: left;
    margin: 0 0 1px 1px;
}

.shop-section .woocommerce-toolbar-top .products-sizes .grid-draw > span:first-child {
    margin-left: 0;
}

.shop-section .woocommerce-toolbar-top .grid-draw-line span:last-child {
    width: 16px;
    height: 1px;
    position: relative;
    top: 2px;
}

.shop-section .woocommerce-toolbar-top .products-sizes .active span {
    background-color: #000;
}

.shop-section .woocommerce-content-wrap {
    width: calc(100% - 295px);
    padding-left: 90px;
    float: right;
}

@media (max-width: 991px) {
    .shop-section .woocommerce-content-wrap {
        width: 100%;
        float: none;
        padding-left: 0;
        margin-bottom: 60px;
    }
}

.shop-section .shop-sidebar {
    width: 295px;
    float: left;
}

@media (max-width: 991px) {
    .shop-section .shop-sidebar {
        float: none;
    }
}

.shop-section .products {
    overflow: hidden;
    margin: 0 -15px;
}

.shop-section .products > li {
    width: calc(25% - 30px);
    float: left;
    margin: 0 15px 50px;
}

@media (max-width: 1500px) {
    .shop-section .products > li {
        width: calc(33.33% - 50px);
    }
}

@media (max-width: 1300px) {
    .shop-section .products > li {
        width: calc(50% - 30px);
    }
}

@media (max-width: 500px) {
    .shop-section .products > li {
        width: calc(100% - 30px);
        float: none;
    }
}

.shop-section .products.three-column > li {
    width: calc(33.33% - 30px);
}

@media (max-width: 1300px) {
    .shop-section .products.three-column > li {
        width: calc(50% - 30px);
    }
}

@media (max-width: 500px) {
    .shop-section .products.three-column > li {
        width: calc(100% - 30px);
        float: none;
    }
}

.shop-section .products.list-view {
    overflow: hidden;
}

.shop-section .products.list-view > li {
    width: calc(100% - 30px);
    float: none;
    overflow: hidden;
}

@media screen and (min-width: 600px) {
    .shop-section .products.list-view > li {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.shop-section .products.list-view .product-holder {
    max-width: 300px;
    /* width: '100%';
    max-width: '100%'; */

    float: left;
}

@media (max-width: 600px) {
    .shop-section .products.list-view .product-holder {
        max-width: auto;
        width: 100%;
        float: none;
    }
}

.shop-section .products.list-view .product-info {
    width: calc(100% - 300px);
    float: right;
    padding-left: 40px;
    text-align: left;
}

@media (max-width: 600px) {
    .shop-section .products.list-view .product-info {
        width: 100%;
        float: none;
        padding-left: 0;
    }
}

.shop-section .products.list-view .product-info h4 {
    font-size: 20px;
    margin-bottom: 0.7em;
}

.shop-section .products.list-view .product-description {
    margin: 1em 0;
    display: block;
}

.shop-section .product-holder {
    position: relative;
}

.shop-section .product-holder .product-badge {
    background-color: #494949;
    
    
    color: #fff;
    padding: 5px 15px;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 25px;
}

.shop-section .product:hover .shop-action-wrap {
    opacity: 1;
    visibility: visible;
    bottom: 35px;
}

.shop-section .shop-action-wrap {
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 15px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
}

.shop-section .shop-action {
    display: inline-block;
}

.shop-section .shop-action > li {
    float: left;
    text-align: center;
}

.shop-section .shop-action > li + li {
    margin-left: 5px;
}

.shop-section .shop-action li a {
    background-color: #fff;
    width: 45px;
    height: 45px;
    line-height: 45px;
    color: #000;
    display: inline-block;
    border-radius: 45px;
    box-shadow: 0px 3px 6.58px 0.42px rgba(0, 0, 0, 0.08);
}

.shop-section .shop-action li a:hover {
    background-color: #000;
    color: #fff;
}

.shop-section .shop-action a i:before {
    font-size: 15px;
    font-size: 1rem;
}

.shop-section .shop-action > li:first-child i:before {
    font-size: 16px;
    font-size: 1.0666666667rem;
}

.shop-section .shop-action > li:nth-child(2) a {
    line-height: 50px;
}

.shop-section .product-info {
    text-align: center;
    padding-top: 25px;
}

.shop-section .product-info h4 {
    /* font-family: "Gothic A1", sans-serif; */
    font-weight: 500;
    font-size: 15px;
    font-size: 1rem;
    font-weight: 500;
    
    margin: 0 0 0.5em;
}

.shop-section .product-info h4 a {
    color: #000;
}

.shop-section .product-info h4 a:hover {
    text-decoration: underline;
}

.shop-section .product-info .woocommerce-Price-amount {
    /* font-family: "futura-pt-demi", sans-serif; */
    font-size: 16px;
    font-size: 1.0666666667rem;
}

.shop-section .product-info ins {
    font-weight: 500;
    color: #000;
    text-decoration: none;
}

.shop-section .product-info del {
    display: inline-block;
    padding-left: 8px;
    color: #a9a9a9;
}

.shop-section .product-info .product-description {
    display: none;
}

.shop-style-2 .woocommerce-content-wrap {
    width: 100%;
    padding-left: 0;
}

@media screen and (min-width: 1200px) {
    .shop-right-sidebar .woocommerce-content-wrap {
        float: left;
        padding-left: 0;
        padding-right: 90px;
    }
}

.ui-tooltip,
.arrow:after {
    background: black;
    border: 0;
}

.ui-tooltip {
    /* font-family: "futura-pt-demi", sans-serif; */
    padding: 6px 12px;
    color: white;
    border-radius: 5px;
    
    font-weight: 400;
    text-transform: none;
    box-shadow: none;
}

.arrow {
    width: 70px;
    height: 16px;
    overflow: hidden;
    position: absolute;
    left: 50%;
    margin-left: -35px;
    bottom: -16px;
}

.arrow.top {
    top: -16px;
    bottom: auto;
}

.arrow.left {
    left: 20%;
}

.arrow:after {
    content: "";
    position: absolute;
    left: 20px;
    top: -20px;
    width: 25px;
    height: 25px;
    box-shadow: none;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.arrow.top:after {
    bottom: -20px;
    top: auto;
}

/*--------------------------------------------------------------
	#shop-single-page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#11.1	shop-single-section
--------------------------------------------------------------*/
.shop-single-section {
    padding-bottom: 80px;
    /*** product slider ***/
    /*** single-product info ***/
}

@media (max-width: 991px) {
    .shop-single-section {
        padding-bottom: 70px;
    }
}

@media (max-width: 767px) {
    .shop-single-section {
        padding-bottom: 60px;
    }
}

.shop-single-section .shop-single-slider .slider-for {
    text-align: center;
}

.shop-single-section .shop-single-slider .slider-for img {
    display: inline-block;
}

.shop-single-section .shop-single-slider .slider-nav {
    padding: 0;
    margin-top: 35px;
}

.shop-single-section .shop-single-slider .slider-nav > i {
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 100;
}

.shop-single-section .shop-single-slider .slider-nav > i:hover {
    cursor: pointer;
}

.shop-single-section .shop-single-slider .slider-nav .nav-btn-rt {
    left: auto;
    right: 0;
}

.shop-single-section .shop-single-slider .slider-nav .slick-slide {
    text-align: center;
}

.shop-single-section .shop-single-slider .slider-nav .slick-slide img {
    display: inline-block;
}

.shop-single-section .shop-single-slider .slider-nav .slick-slide {
    width: auto !important;
    margin-right: 15px;
}

.shop-single-section .shop-single-slider .slider-nav .slick-slide {
    border: 1px solid transparent;
}

.shop-single-section .shop-single-slider .slider-nav .slick-slide.slick-current.slick-active {
    border: 1px solid #000;
}

.shop-single-section .product-details {
    padding: 50px 30px 93px;
    /*** product option ***/
}

@media (max-width: 1199px) {
    .shop-single-section .product-details {
        padding: 40px 30px 85px;
    }
}

@media (max-width: 991px) {
    .shop-single-section .product-details {
        margin-top: 45px;
        padding: 40px 30px;
    }
}

@media (max-width: 767px) {
    .shop-single-section .product-details {
        padding: 0;
    }
}

.shop-single-section .product-details h2 {
    font-size: 42px;
    font-size: 2.8rem;
    margin: 0 0 0.5em;
}

@media (max-width: 767px) {
    .shop-single-section .product-details h2 {
        font-size: 22px;
        font-size: 1.4666666667rem;
    }
}

.shop-single-section .product-details .price {
    /* font-family: "futura-pt-demi", sans-serif; */
    font-size: 24px;
    font-size: 1.6rem;
    margin: 0.4em 0 0.5em;
}

@media (max-width: 991px) {
    .shop-single-section .product-details .price {
        font-size: 20px;
        font-size: 1.3333333333rem;
    }
}

.shop-single-section .product-details .price .old {
    font-size: 20px;
    font-size: 1.3333333333rem;
    font-weight: normal;
    color: #ababab;
    text-decoration: line-through;
    display: inline-block;
    margin-left: 5px;
}

@media (max-width: 991px) {
    .shop-single-section .product-details .price .old {
        font-size: 18px;
        font-size: 1.2rem;
    }
}

.shop-single-section .product-details .rating {
    margin-bottom: 25px;
}

.shop-single-section .product-details .rating .fi:before {
    
    
    color: #c2572b;
}

.shop-single-section .product-details p {
    margin-bottom: 1.3em;
}

.shop-single-section .product-details p:last-child {
    margin: 0;
}

.shop-single-section .product-details .product-option {
    margin: 35px 0;
}

.shop-single-section .product-details .product-option .btn {
    padding: 7px 4px 7px 6px;
    font-size: 8px;
    font-weight: normal;
    position: relative;
}

.shop-single-section .product-details .product-option .btn:hover {
    background-color: #000 !important;
}

.shop-single-section .product-details .product-option .product-count {
    width: 65px;
    position: relative;
    height: 50px;
    border-right: 0;
}

.shop-single-section .product-details .product-option .input-group-btn-vertical {
    position: relative;
    top: -36px;
}

.shop-single-section .product-details .product-option .bootstrap-touchspin-up {
    top: 3px;
}

.shop-single-section .product-details .product-option .bootstrap-touchspin-down {
    bottom: 1px;
}

.shop-single-section .product-details .product-option form button[type="submit"] {
    
    border: 0;
}

.shop-single-section .product-details .product-option .product-row {
    overflow: hidden;
}

.shop-single-section .product-details .product-option .product-row button:after {
    display: none;
}

.shop-single-section .product-details .product-option .product-row > div {
    display: inline-block;
    float: left;
}

.shop-single-section .product-details .product-option .product-row > div + div {
    margin-left: 15px;
}

.shop-single-section .product-details .product-option .product-row > div:first-child {
    width: 85px;
    height: 50px;
}

.shop-single-section .product-details .product-option .product-row > div:last-child .theme-btn:hover, .shop-single-section .product-details .product-option .product-row > div:last-child .theme-btn-s2:hover, .shop-single-section .product-details .product-option .product-row > div:last-child .theme-btn-s3:hover, .shop-single-section .product-details .product-option .product-row > div:last-child .theme-btn-s4:hover, .shop-single-section .product-details .product-option .product-row > div:last-child .theme-btn-s6:hover, .shop-single-section .product-details .product-option .product-row > div:last-child .theme-btn-s7:hover, .shop-single-section .product-details .product-option .product-row > div:last-child .theme-btn-s5:hover {
    background: #c2572b;
    border-color: #c2572b;
    color: #fff;
}

.shop-single-section .product-details .product-option .heart-btn i {
    font-size: 15px;
    font-size: 1rem;
}

.shop-single-section .product-details .product-option .product-row > div:last-child .theme-btn, .shop-single-section .product-details .product-option .product-row > div:last-child .theme-btn-s2, .shop-single-section .product-details .product-option .product-row > div:last-child .theme-btn-s3, .shop-single-section .product-details .product-option .product-row > div:last-child .theme-btn-s4, .shop-single-section .product-details .product-option .product-row > div:last-child .theme-btn-s6, .shop-single-section .product-details .product-option .product-row > div:last-child .theme-btn-s7, .shop-single-section .product-details .product-option .product-row > div:last-child .theme-btn-s5 {
    font-size: 18px;
    font-size: 1.2rem;
}

.shop-single-section .product-details .product-count {
    border-radius: 20;
    border: 1px solid #e6e5e5;
}

.shop-single-section .product-details .product-count:focus {
    box-shadow: none;
    outline: none;
}

.shop-single-section .product-details .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up,
.shop-single-section .product-details .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
    border-radius: 20;
    border-color: #e6e5e5;
}

.shop-single-section .product-details .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up:hover,
.shop-single-section .product-details .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down:hover {
    background-color: #c2572b;
    color: #fff;
}

.shop-single-section .thb-product-meta-before .add-to-wishlist {
    margin-bottom: 20px;
}

.shop-single-section .thb-product-meta-before .add-to-wishlist i {
    font-size: 20px;
    font-size: 1.3333333333rem;
    position: relative;
    top: 4px;
    display: inline-block;
    padding-right: 3px;
}

.shop-single-section .thb-product-meta-before .add-to-wishlist a {
    font-size: 14px;
    font-size: 0.9333333333rem;
    color: #000;
    font-weight: 500;
}

.shop-single-section .thb-product-meta-before .add-to-wishlist a:hover {
    color: #c2572b;
}

.shop-single-section .thb-product-meta-before .product_meta > span {
    display: block;
    font-size: 14px;
    font-size: 0.9333333333rem;
    font-weight: 500;
    margin-bottom: 5px;
}

.shop-single-section .thb-product-meta-before .product_meta > span a {
    font-weight: 400;
    
}

.shop-single-section .thb-product-meta-before .product_meta > span a:hover {
    text-decoration: underline;
}

.shop-single-section .single-product-info {
    margin-top: 75px;
    text-align: center;
    border-top: 1px solid #e8e8e8;
    /*** tabs ***/
    /*** client rv ***/
    /*** review form ***/
}

.shop-single-section .single-product-info h4 {
    font-size: 18px;
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0;
    line-height: 1.7em;
}

.shop-single-section .single-product-info p {
    margin-bottom: 1.3em;
}

.shop-single-section .single-product-info .tab-pane p:last-child {
    margin-bottom: 0;
}

.shop-single-section .single-product-info .nav-tabs {
    border: 0;
    display: inline-block;
    margin-top: 5px;
}

@media (max-width: 767px) {
    .shop-single-section .single-product-info .nav-tabs {
        margin-bottom: 20px;
    }
}

.shop-single-section .single-product-info .nav-tabs li {
    border: 0;
    margin-right: 1px;
}

.shop-single-section .single-product-info .nav-tabs li.active a {
    border: 0;
    outline: 0;
}

.shop-single-section .single-product-info .nav-tabs a {
    /* font-family: "futura-pt-demi", sans-serif; */
    font-weight: 500;
    font-size: 16px;
    font-size: 1.0666666667rem;
    
    border: 0;
    border-radius: 20;
    margin: 0;
    display: block;
    padding: 12px 20px 11px;
}

@media (max-width: 767px) {
    .shop-single-section .single-product-info .nav-tabs a {
        font-size: 14px;
        font-size: 0.9333333333rem;
        font-weight: normal;
        padding: 10px 10px 8px;
        text-transform: none;
    }
}

.shop-single-section .single-product-info .nav-tabs a:hover,
.shop-single-section .single-product-info .nav-tabs .active a {
    background-color: transparent;
    color: #000;
}

.shop-single-section .single-product-info .tab-content {
    border-top: 1px solid #e8e8e8;
    padding: 35px 0;
    margin-top: -1px;
    text-align: left;
}

@media (max-width: 767px) {
    .shop-single-section .single-product-info .tab-content {
        border: 0;
        padding: 0;
        margin: 0;
    }
}

.shop-single-section .single-product-info .client-rv {
    overflow: hidden;
    margin-bottom: 30px;
}

.shop-single-section .single-product-info .client-rv:last-child {
    margin-bottom: 0;
}

.shop-single-section .single-product-info .client-rv .client-pic {
    width: 60px;
    float: left;
}

@media (max-width: 767px) {
    .shop-single-section .single-product-info .client-rv .client-pic {
        width: 100%;
        float: none;
        margin-bottom: 10px;
    }
}

.shop-single-section .single-product-info .client-rv .details {
    width: calc(100% - 80px);
    float: right;
}

@media (max-width: 767px) {
    .shop-single-section .single-product-info .client-rv .details {
        width: 100%;
        float: none;
    }
}

@media (max-width: 767px) {
    .shop-single-section .single-product-info .client-rv .name-rating-time {
        padding-bottom: 3px;
    }
}

.shop-single-section .single-product-info .client-rv .name-rating-time > div,
.shop-single-section .single-product-info .client-rv .name-rating > div {
    display: inline-block;
    font-size: 14px;
    font-size: 0.9333333333rem;
}

@media (max-width: 767px) {
    .shop-single-section .single-product-info .client-rv .name-rating-time > div,
    .shop-single-section .single-product-info .client-rv .name-rating > div {
        
        
        display: block;
    }
}

.shop-single-section .single-product-info .client-rv .rating .fi:before {
    
}

.shop-single-section .single-product-info .client-rv .rating {
    
    color: #c2572b;
    padding-left: 12px;
}

@media (max-width: 767px) {
    .shop-single-section .single-product-info .client-rv .rating {
        padding-left: 0;
        margin: 4px 0 7px;
    }
}

.shop-single-section .single-product-info .client-rv .name-rating-time .time {
    font-size: 14px;
    font-size: 0.9333333333rem;
    font-weight: 500;
    margin-top: 3px;
    display: block;
    color: #b3b2b2;
    text-transform: uppercase;
}

@media (max-width: 767px) {
    .shop-single-section .single-product-info .client-rv .name-rating-time .time {
        float: none;
    }
}

.shop-single-section .single-product-info .client-rv .review-body {
    padding-top: 12px;
}

.shop-single-section .single-product-info .review-form-wrapper {
    margin-top: 50px;
}

@media screen and (min-width: 1200px) {
    .shop-single-section .single-product-info .review-form-wrapper {
        padding-left: 15px;
    }
}

@media (max-width: 991px) {
    .shop-single-section .single-product-info .review-form {
        margin-top: 45px;
    }
}

.shop-single-section .single-product-info .review-form h4 {
    margin-bottom: 1.73em;
    font-weight: 400;
}

.shop-single-section .single-product-info .review-form form input,
.shop-single-section .single-product-info .review-form form textarea {
    width: 100%;
    height: auto;
    border: 1px solid #ddd;
    border-radius: 20;
    padding: 12px 15px;
    box-shadow: none;
}

@media (max-width: 991px) {
    .shop-single-section .single-product-info .review-form form input,
    .shop-single-section .single-product-info .review-form form textarea {
        padding: 10px 15px;
    }
}

.shop-single-section .single-product-info .review-form form input:focus,
.shop-single-section .single-product-info .review-form form textarea:focus {
    border: 1px solid #000;
    outline: none;
    box-shadow: none;
}

.shop-single-section .single-product-info .review-form form textarea {
    height: 130px;
}

.shop-single-section .single-product-info .review-form form > div {
    margin-bottom: 27px;
}

.shop-single-section .single-product-info .review-form form > div:last-child {
    margin-bottom: 0;
}

.shop-single-section .single-product-info .review-form form .rating-wrapper > div {
    display: inline-block;
}

@media (max-width: 767px) {
    .shop-single-section .single-product-info .review-form form .rating-wrapper > div {
        display: block;
        float: none !important;
    }
}

.shop-single-section .single-product-info .review-form form .rating-wrapper > div:last-child {
    float: right;
}

@media (max-width: 767px) {
    .shop-single-section .single-product-info .review-form form .rating-wrapper > div:last-child {
        margin-top: 20px;
    }
}

.shop-single-section .single-product-info .review-form form .rating a {
    font-size: 14px;
    color: #cccccc;
    display: inline-block;
    margin-right: 10px;
}

@media (max-width: 767px) {
    .shop-single-section .single-product-info .review-form form .rating a {
        
        margin-right: 5px;
    }
}

.shop-single-section .single-product-info .review-form form .rating a:last-child {
    margin: 0;
}

.shop-single-section .single-product-info .review-form form .rating a:hover {
    color: #c2572b;
}

.shop-single-section .single-product-info .review-form .submit button {
    border: 0;
    outline: none;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
}

.shop-single-section .slider-nav .slick-slide:focus {
    outline: none;
}

.shop-single-section .products {
    overflow: hidden;
    margin: 0 -15px;
}

.shop-single-section .products > li {
    width: calc(25% - 30px);
    float: left;
    margin: 0 15px 30px;
}

@media (max-width: 1500px) {
    .shop-single-section .products > li {
        width: calc(33.33% - 30px);
    }
}

@media (max-width: 1300px) {
    .shop-single-section .products > li {
        width: calc(50% - 30px);
    }
}

@media (max-width: 500px) {
    .shop-single-section .products > li {
        width: calc(100% - 30px);
        float: none;
    }
}

.shop-single-section .product-holder {
    position: relative;
}

.shop-single-section .product-holder .product-badge {
    background-color: #494949;
    
    
    color: #fff;
    padding: 5px 15px;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 25px;
}

.shop-single-section .product:hover .shop-action-wrap {
    opacity: 1;
    visibility: visible;
    bottom: 35px;
}

.shop-single-section .shop-action-wrap {
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 15px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
}

.shop-single-section .shop-action {
    display: inline-block;
}

.shop-single-section .shop-action > li {
    float: left;
    text-align: center;
}

.shop-single-section .shop-action > li + li {
    margin-left: 5px;
}

.shop-single-section .shop-action li a {
    background-color: #fff;
    width: 45px;
    height: 45px;
    line-height: 45px;
    color: #000;
    display: inline-block;
    border-radius: 45px;
    box-shadow: 0px 3px 6.58px 0.42px rgba(0, 0, 0, 0.08);
}

.shop-single-section .shop-action li a:hover {
    background-color: #000;
    color: #fff;
}

.shop-single-section .shop-action a i:before {
    font-size: 15px;
    font-size: 1rem;
}

.shop-single-section .shop-action > li:first-child i:before {
    font-size: 16px;
    font-size: 1.0666666667rem;
}

.shop-single-section .shop-action > li:nth-child(2) a {
    line-height: 50px;
}

.shop-single-section .product-info {
    text-align: center;
    padding-top: 25px;
}

.shop-single-section .product-info h4 {
    /* font-family: "Gothic A1", sans-serif; */
    font-size: 14px;
    font-size: 0.9333333333rem;
    font-weight: 500;
    margin: 0 0 0.5em;
}

.shop-single-section .product-info h4 a {
    color: #000;
}

.shop-single-section .product-info h4 a:hover {
    text-decoration: underline;
}

.shop-single-section .product-info .woocommerce-Price-amount {
    /* font-family: "futura-pt-demi", sans-serif; */
    font-size: 16px;
    font-size: 1.0666666667rem;
}

.shop-single-section .product-info ins {
    font-weight: 500;
    color: #000;
    text-decoration: none;
}

.shop-single-section .product-info del {
    display: inline-block;
    padding-left: 8px;
    color: #a9a9a9;
}

.shop-single-section .product-info .product-description {
    display: none;
}

.shop-single-section .realted-porduct h3 {
    font-size: 40px;
    font-size: 2.6666666667rem;
    margin: 1em 0 1em;
    text-align: center;
}

@media screen and (min-width: 1200px) {
    .shop-single-vertical-thumb .slider-for {
        max-width: 535px;
        float: right;
    }

    .shop-single-vertical-thumb .slider-nav .slick-slide {
        margin-bottom: 15px;
    }

    .shop-single-vertical-thumb .shop-single-slider .slider-nav .slick-slide,
    .shop-single-vertical-thumb .shop-single-slider .slider-nav .slick-track {
        width: 100px !important;
    }

    .shop-single-vertical-thumb .slider-nav .slick-slide {
        float: none;
    }

    .shop-single-vertical-thumb .single-product-info {
        margin-top: 110px;
    }
}

@media screen and (min-width: 1450px) {
    .shop-single-vertical-thumb .slider-for {
        max-width: 615px;
        float: right;
    }
}

/*--------------------------------------------------------------
	#contact page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#12.1	contact-pg-section
--------------------------------------------------------------*/
.contact-pg-section .contact-info {
    width: 370px;
    float: left;
}

@media (max-width: 991px) {
    .contact-pg-section .contact-info {
        width: 100%;
        float: none;
    }
}

.contact-pg-section .contact-info li {
    position: relative;
    padding-left: 85px;
}

@media (max-width: 991px) {
    .contact-pg-section .contact-info li {
        padding-left: 65px;
    }
}

.contact-pg-section .contact-info ul > li + li {
    margin-top: 50px;
}

.contact-pg-section .contact-info i {
    position: absolute;
    left: 0;
    top: -5px;
}

.contact-pg-section .contact-info i:before {
    font-size: 57px;
    font-size: 3.8rem;
    
}

@media (max-width: 991px) {
    .contact-pg-section .contact-info i:before {
        font-size: 50px;
        font-size: 3.3333333333rem;
    }
}

.contact-pg-section .contact-info h4 {
    font-size: 18px;
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0 0 0.6em;
}

.contact-pg-section .contact-info p {
    font-size: 15px;
    font: 1rem;
    margin: 0;
}

.contact-pg-section .contact-form-col {
    width: calc(100% - 370px);
    float: right;
    padding-left: 130px;
    position: relative;
}

@media (max-width: 991px) {
    .contact-pg-section .contact-form-col {
        width: 100%;
        float: none;
        padding: 0 15px;
        margin-top: 60px;
    }
}

.contact-pg-section .contact-form-col h2 {
    font-size: 24px;
    font-size: 1.6rem;
    margin: 0 0 1em;
}

.contact-pg-section .contact-form-col form {
    margin: 0 -15px;
}

.contact-pg-section .contact-form-col form > div {
    width: calc(50% - 30px);
    float: left;
    margin: 0 15px 30px;
}

@media (max-width: 600px) {
    .contact-pg-section .contact-form-col form > div {
        width: calc(100% - 30px);
        float: none;
    }
}

.contact-pg-section .contact-form-col form .fullwidth {
    width: calc(100% - 30px);
    float: none;
    clear: both;
}

.contact-pg-section .contact-form-col input,
.contact-pg-section .contact-form-col textarea,
.contact-pg-section .contact-form-col select {
    border: 1px solid #ddd;
    
}

.contact-pg-section .contact-form-col form button {
    border: 0;
    outline: 0;
}

.contact-pg-section .contact-form-col .submit-area {
    width: 100%;
    margin-bottom: 0;
}

.contact-map-section {
    margin-bottom: 110px;
}

@media (max-width: 991px) {
    .contact-map-section {
        margin-bottom: 100px;
    }
}

@media (max-width: 767px) {
    .contact-map-section {
        margin-bottom: 90px;
    }
}

.contact-map-section .contact-map {
    height: 450px;
}

.contact-map-section iframe {
    width: 100%;
    height: 100%;
    border: 0;
    outline: 0;
}

/*--------------------------------------------------------------
	#about-page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#13.1	feature-section
--------------------------------------------------------------*/
.feature-section {
    background-color: #f5f5f5;
    padding: 60px 0 10px;
}

@media (max-width: 767px) {
    .feature-section {
        padding: 90px 0 40px;
    }
}

.feature-section .features {
    margin: 0 -15px;
}

.feature-section .features > div {
    width: calc(25% - 30px);
    margin: 0 15px 50px;
    float: left;
    padding-left: 70px;
    position: relative;
}

@media (max-width: 1199px) {
    .feature-section .features > div {
        width: calc(50% - 30px);
        margin: 0 15px 50px;
    }
}

@media (max-width: 767px) {
    .feature-section .features > div {
        padding-left: 50px;
    }
}

@media (max-width: 550px) {
    .feature-section .features > div {
        width: calc(100% - 30px);
        float: none;
        padding-left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.feature-section .features .icon {
    position: absolute;
    left: 0;
}

@media (max-width: 550px) {
    .feature-section .features .icon {
        position: relative;
    }
}

.feature-section .features .icon i {
    font-size: 50px;
    font-size: 3.3333333333rem;
    color: #5b5b5b;
}

@media (max-width: 767px) {
    .feature-section .features .icon i {
        font-size: 40px;
        font-size: 2.6666666667rem;
    }
}

.feature-section .features .info {
    padding-left: 20px;
    border-left: 1px solid #dbdbdb;
}

@media (max-width: 767px) {
    .feature-section .features .info {
        padding-left: 15px;
    }
}

@media (max-width: 550px) {
    .feature-section .features .info {
        margin-left: 15px;
    }
}

.feature-section .features h3 {
    font-size: 17px;
    font-size: 1.1333333333rem;
    margin: 0 0 0.2em;
    text-transform: uppercase;
}

@media (max-width: 1199px) {
    .feature-section .features h3 {
        font-size: 20px;
        font-size: 1.3333333333rem;
    }
}

@media (max-width: 767px) {
    .feature-section .features h3 {
        font-size: 18px;
        font-size: 1.2rem;
    }
}

.feature-section .features p {
    font-size: 14px;
    font-size: 0.9333333333rem;
    margin: 0;
}

/*--------------------------------------------------------------
#13.2.	about-section
--------------------------------------------------------------*/
@media screen and (min-width: 1200px) {
    .about-section .container-fluid {
        padding: 0 85px;
    }

    .about-section .container-fluid > .row {
        display: flex;
        align-items: center;
    }
}

.about-section .about-area {
    text-align: right;
}

@media (max-width: 1199px) {
    .about-section .about-area {
        text-align: center;
    }
}

.about-section .about-area .info > span {
    /* font-family: "futura-pt-demi", sans-serif; */
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #c2572b;
}

.about-section .about-area img {
    display: inline-block;
}

.about-section .about-area .info h2 {
    font-size: 48px;
    font-size: 3.2rem;
    margin: 0.3em 0 0.5em;
}

@media (max-width: 991px) {
    .about-section .about-area .info h2 {
        font-size: 35px;
        font-size: 2.3333333333rem;
    }
}

@media (max-width: 767px) {
    .about-section .about-area .info h2 {
        font-size: 30px;
        font-size: 2rem;
    }
}

.about-section .about-area .info {
    max-width: 620px;
    background-color: #fff;
    padding: 100px 65px;
    position: absolute;
    left: 0;
    top: 155px;
    box-shadow: 0px 6px 68px 0px rgba(10, 39, 50, 0.06);
}

@media (max-width: 1450px) {
    .about-section .about-area .info {
        background-color: rgba(255, 255, 255, 0.95);
        top: 100px;
        max-width: 55%;
    }
}

@media (max-width: 1199px) {
    .about-section .about-area .info {
        max-width: 100%;
        position: static;
        text-align: center;
        margin-bottom: 80px;
    }
}

.about-section .about-area .info p {
    margin-bottom: 2em;
}

@media screen and (min-width: 1200px) {
    .about-section .mission-vision-area {
        padding-left: 30px;
    }
}

@media screen and (min-width: 1400px) {
    .about-section .mission-vision-area {
        padding-left: 60px;
    }
}

@media (max-width: 1199px) {
    .about-section .mission-vision-area {
        text-align: center;
        padding: 0 45px;
        margin-top: 60px;
    }
}

@media (max-width: 767px) {
    .about-section .mission-vision-area {
        padding: 0 15px;
    }
}

.about-section .mission-vision-area > div {
    margin-bottom: 60px;
}

@media (max-width: 767px) {
    .about-section .mission-vision-area > div {
        margin-bottom: 45px;
    }
}

.about-section .mission-vision-area h3 {
    font-size: 30px;
    font-size: 2rem;
    margin: 0 0 0.5em;
    position: relative;
    text-transform: capitalize;
}

@media (max-width: 991px) {
    .about-section .mission-vision-area h3 {
        font-size: 25px;
        font-size: 1.6666666667rem;
    }
}

@media (max-width: 767px) {
    .about-section .mission-vision-area h3 {
        font-size: 22px;
        font-size: 1.4666666667rem;
    }
}

.about-section .mission-vision-area p {
    font-size: 16px;
    font-size: 1.0666666667rem;
    margin: 0;
}

/*--------------------------------------------------------------
#13.3	testimonials-section
--------------------------------------------------------------*/
.testimonials-section {
    /* background: url("../images/testimonials.jpg") center top/cover no-repeat local; */
    padding: 110px 0;
    position: relative;
    overflow: hidden;
}

@media (max-width: 991px) {
    .testimonials-section {
        padding: 100px 0;
    }
}

@media (max-width: 767px) {
    .testimonials-section {
        padding: 80px 0;
    }
}

.testimonials-section .img-holder {
    position: absolute;
    top: -180px;
}

@media (max-width: 991px) {
    .testimonials-section .img-holder {
        display: none;
    }
}

.testimonials-section .testimonial-grids {
    position: relative;
}

.testimonials-section .testimonial-grids .grid {
    background-color: #fff;
    padding: 45px;
}

.testimonials-section .testimonial-grids:before {
    content: "Q";
    font-size: 300px;
    font-size: 20rem;
    color: #fff;
    opacity: 0.2;
    position: absolute;
    left: -250px;
    top: -120px;
}

@media (max-width: 991px) {
    .testimonials-section .testimonial-grids:before {
        left: 0;
    }
}

.testimonials-section p {
    font-size: 18px;
    font-size: 1.2rem;
    margin: 0 0 1em;
    font-weight: 200;
}

@media (max-width: 767px) {
    .testimonials-section p {
        font-size: 18px;
        font-size: 1.2rem;
        margin: 0 0 1.9em;
    }
}

.testimonials-section h5 {
    font-size: 14px;
    font-size: 0.9333333333rem;
    margin: 0;
    text-transform: uppercase;
    padding-left: 35px;
    position: relative;
}

.testimonials-section h5:before {
    content: "";
    background-color: #000;
    width: 20px;
    height: 1px;
    position: absolute;
    left: 0;
    top: 8px;
}

.testimonials-section .owl-controls {
    text-align: left;
    margin-top: 35px;
    margin-left: -5px;
}

.testimonials-section .owl-theme .owl-dots .owl-dot span {
    width: 8px;
    height: 8px;
    margin: 5px 5px;
    background: #fff;
    opacity: 0.2;
}

.testimonials-section .owl-theme .owl-dots .owl-dot.active span {
    opacity: 1;
}

/*--------------------------------------------------------------
#13.4	blog-section
--------------------------------------------------------------*/
.blog-section {
    padding-bottom: 40px;
}

@media (max-width: 991px) {
    .blog-section {
        padding-bottom: 30px;
    }
}

@media (max-width: 767px) {
    .blog-section {
        padding-bottom: 20px;
    }
}

.blog-section .blog-grids {
    margin: 0 -15px;
}

.blog-section .blog-grids .post {
    width: calc(33.33% - 30px);
    float: left;
    margin: 0 15px 70px;
}

@media (max-width: 991px) {
    .blog-section .blog-grids .post {
        width: calc(50% - 30px);
    }
}

@media (max-width: 650px) {
    .blog-section .blog-grids .post {
        width: calc(100% - 30px);
        float: none;
    }
}

.blog-section .entry-details {
    padding-top: 40px;
    position: relative;
    z-index: 1;
}

.blog-section .entry-details .entry-meta {
    overflow: hidden;
    margin-bottom: 15px;
}

.blog-section .entry-details .entry-meta > div {
    float: left;
    position: relative;
}

.blog-section .entry-details .entry-meta > div:last-child {
    margin-left: 25px;
}

.blog-section .entry-details .entry-meta > div:last-child:before {
    content: "";
    background-color: #606060;
    width: 9px;
    height: 9px;
    border-radius: 9px;
    position: absolute;
    left: -11px;
    top: 2px;
}

.blog-section .entry-details .entry-meta .date,
.blog-section .entry-details .entry-meta .tags {
    /* font-family: "futura-pt-demi", sans-serif; */
    font-size: 10px;
    font-size: 0.6666666667rem;
    font-weight: 500;
    
    text-transform: uppercase;
    letter-spacing: 2px;
}

.blog-section .entry-details .entry-meta .tags a {
    
    text-decoration: underline;
    position: relative;
    display: inline-block;
    margin-left: 10px;
}

.blog-section .entry-details .entry-meta .tags a:hover {
    color: #000;
}

.blog-section .entry-details .entry-meta .tags > a + a:before {
    content: "'";
    position: absolute;
    left: -9px;
    bottom: -5px;
}

.blog-section .entry-details .read-more {
    /* font-family: "futura-pt-demi", sans-serif; */
    
    
    font-weight: 500;
    color: #000;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.blog-section .entry-details .read-more i {
    position: relative;
    top: 1px;
}

.blog-section .entry-details .read-more i:before {
    font-size: 14px;
}

.blog-section .entry-details .read-more:hover {
    text-decoration: underline;
}

.blog-section .post h3 {
    font-size: 22px;
    font-size: 1.4666666667rem;
    font-weight: normal;
    margin: 0 0 0.6em;
}

@media screen and (min-width: 1200px) {
    .blog-section .post h3 {
        padding-right: 45px;
    }
}

@media (max-width: 767px) {
    .blog-section .post h3 {
        font-size: 20px;
        font-size: 1.3333333333rem;
    }
}

.blog-section .post h3 a {
    color: #000;
}

.blog-section .post h3 a:hover {
    text-decoration: underline;
}

.blog-section .post p {
    margin-bottom: 1.8em;
}

@media (max-width: 991px) {
    .blog-section .post p {
        font-size: 16px;
        font-size: 1.0666666667rem;
    }
}

  